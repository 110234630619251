import React, { useEffect, useRef, useState } from 'react';
import SingleSelectCloud from '../Select/SingleSelectCloud';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import SwitchButton, { SwitchLabelLocation } from '../SwitchButton';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { API, KeyImagesAPI, LocalAPI } from '../../../../../constants/baseurl';
import { servicesManager } from '../../../../../platform/app/src/App';
import { secretKeyy } from '../../../../../utils/bucket';
import { useSelector } from 'react-redux';

function CloudUpload() {
  const navigate = useNavigate();

  const { uiNotificationService } = servicesManager.services;
  const token = localStorage.getItem('token');
  const [files, setFiles] = useState([]);
  const [filesCount, setFilesCount] = useState(0);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('user_data')));
  const [bucketData, setBucketData] = useState([]);
  const [actualBucketData, setActualBucketData] = useState([]);
  const [currentBucket, setCurrentBucket] = useState([]);
  const [casesCount, setCaseCount] = useState(0);
  const [isDeidentify, setDeidentify] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const folderName = useSelector(state => state.user.selectedFolder);
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState('');
  const [sharedCase, setSharedCase] = useState(false);
  const handleFileCancel = useRef(null);

  useEffect(() => {
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const is_demo = user_data && user_data.email == 'userdemo@gmail.com';
    const is_share = JSON.parse(localStorage.getItem('share'));
    const share_case =
      user_data &&
      user_data.isShared &&
      is_share &&
      user_data.isShared == 'true' &&
      is_share == true
        ? true
        : false;

    if ((share_case == true && folderName == '') || is_demo == true) {
      navigate('/sign-in');
    }

    if (share_case == true) {
      setSharedCase(true);
    }
  }, []);

  useEffect(() => {
    try {
      axios
        .post(
          `${API}/api/v1/bucket/getSpecificUserBucket`,
          {
            user: userData._id,
          },
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then(data => {
          const mappedBuckets = data.data.data.map(item => {
            return {
              _id: item._id,
              user: item.user,
              bucketName: item.bucketName,
              dcmcAccessKey: item.dcmcAccessKey,
              bucketType: item.bucketType,
              __v: item.__v,
              accessKeyId: CryptoJS.AES.decrypt(item.accessKeyId, secretKeyy).toString(
                CryptoJS.enc.Utf8
              ),
              secretAccessKey: CryptoJS.AES.decrypt(item.secretAccessKey, secretKeyy).toString(
                CryptoJS.enc.Utf8
              ),
            };
          });
          const options = mappedBuckets.map(item => {
            return {
              value: item._id,
              label: item.bucketName,
            };
          });
          if (folderName != '') {
            const bucketId = localStorage.getItem('bucketId');
            const newBucket = mappedBuckets.find(item => item._id == bucketId);
            const newOptions = options.find(item => item._id == bucketId);
            setBucketData(newOptions);
            setActualBucketData([newBucket]);
            setCurrentBucket(newBucket);
          } else {
            setBucketData(options);
            setActualBucketData(mappedBuckets);
            setCurrentBucket(mappedBuckets[0]);
          }
        })
        .catch(error => {
          console.error('Error getting bucket data:', error);
        });
    } catch (error) {
      console.error('Error getting bucket data:', error);
    }
  }, []);

  const getFolderList = async () => {
    try {
      const body = {
        AwsAccessKey: currentBucket.accessKeyId,
        AwsSecretAccessKey: currentBucket.secretAccessKey,
        AwsBucket: currentBucket.bucketName,
      };
      if (body) {
        const response_list = await axios.post(
          `${KeyImagesAPI}/keyImages/v1/updateFolder/v4/getFolderList`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response_list && response_list.data) {
          const foldersOptions = response_list.data.data.map(item => {
            return {
              value: item,
              label: item,
            };
          });

          const defaultFolder = {
            value: 'default_123456789_studylist',
            label: 'Default Studylist',
          };

          setFolders([defaultFolder, ...foldersOptions]);
        }
      }
    } catch (error) {
      setFolders([]);
      console.error('Error getting folder list:', error);
    }
  };

  useEffect(() => {
    if (!sharedCase && folderName == '' && bucketData.length > 0) {
      getFolderList();
    }
  }, [currentBucket]);

  const onDrop = acceptedFiles => {
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
    setFilesCount(prevCount => prevCount + acceptedFiles.length);
  };

  const bucketSelection = (newselection, action) => {
    const forCurrent = actualBucketData.find(item => item._id == newselection);
    setCurrentBucket(forCurrent);
  };

  const folderSelection = (newselection, action) => {
    const forCurrent = folders.find(item => item.value == newselection);
    setCurrentFolder(forCurrent.value);
  };

  // function BrowseUpload() {
  //   const formData = new FormData();
  //   files.forEach(file => formData.append('files', file));
  //   formData.append('bucketName', currentBucket.bucketName);
  //   formData.append('AwsAccessKey', currentBucket.accessKeyId);
  //   formData.append('AwsSecretAccessKey', currentBucket.secretAccessKey);
  //   formData.append('Region', 'us-east-1');
  //   if (
  //     folderName != '' ||
  //     (currentFolder != '' && currentFolder != 'default_123456789_studylist')
  //   ) {
  //     if (currentFolder != '' && currentFolder != 'default_123456789_studylist') {
  //       formData.append('folderName', currentFolder);
  //     } else if (folderName != '') {
  //       formData.append('folderName', folderName);
  //     }
  //   }

  //   axios
  //     .post(`${API}/apilocal/v1/local/cloudUpload`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         Authorization: `Bearer ${token}`,
  //       },
  //       onUploadProgress: progressEvent => {
  //         const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //         setUploadPercent(percentCompleted);
  //         // You can update your UI with the upload progress here if needed
  //       },
  //     })
  //     .then(response => {
  //       setFiles([]);
  //       setFilesCount(0);
  //       setUploadPercent(0);
  //       uiNotificationService.show({
  //         title: 'Upload Study',
  //         message: 'Study has been uploaded successfully.',
  //         type: 'success',
  //         duration: 3000,
  //       });
  //     })
  //     .catch(error => {
  //       console.error('File upload error:', error);

  //       setFiles([]);
  //       setFilesCount(0);
  //       setUploadPercent(0);
  //       uiNotificationService.show({
  //         title: 'Upload Study',
  //         message: 'Study failed to upload.',
  //         type: 'warning',
  //         duration: 3000,
  //       });
  //     });
  // }

  function chunkArray(array, chunkSize) {
    const results = [];
    if (array.length <= chunkSize) {
      let newSize = Math.floor(chunkSize / 2);
      for (let i = 0; i < array.length; i += newSize) {
        results.push(array.slice(i, i + newSize));
      }
    } else {
      for (let i = 0; i < array.length; i += chunkSize) {
        results.push(array.slice(i, i + chunkSize));
      }
    }
    return results;
  }

  async function uploadBatches(batches) {
    let uploadedCount = 0;
    let isRetrying = false;
    const totalFiles = batches.flat().length;

    for (let i = 0; i < batches.length; i++) {
      if (handleFileCancel.current == 0) {
        break;
      }
      const batch = batches[i];
      const formData = new FormData();
      batch.forEach(file => formData.append('files', file));
      formData.append('bucketName', currentBucket.bucketName);
      formData.append('AwsAccessKey', currentBucket.accessKeyId);
      formData.append('AwsSecretAccessKey', currentBucket.secretAccessKey);
      formData.append('Region', 'us-east-1');
      if (folderName || (currentFolder && currentFolder !== 'default_123456789_studylist')) {
        formData.append('folderName', currentFolder || folderName);
      }

      try {
        await axios.post(`${API}/apilocal/v1/local/cloudUpload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            const overallPercent = Number(
              (
                ((uploadedCount + (percentCompleted / 100) * batch.length) * 100) /
                totalFiles
              ).toFixed(2)
            );
            setUploadPercent(overallPercent);
          },
        });

        if (handleFileCancel.current == 1) {
          uiNotificationService.show({
            title: 'Upload Study',
            message: `${batch.length} files uploaded successfully.`,
            type: 'success',
            duration: 3000,
          });
        }

        isRetrying = false;
        uploadedCount += batch.length;
      } catch (error) {
        console.error('File upload error:', error);
        // Retry the failed batch
        if (isRetrying == false) {
          uiNotificationService.show({
            title: 'Upload Study',
            message: `${batch.length} files failed to upload, retrying...`,
            type: 'error',
            duration: 3000,
          });
        }
        isRetrying = true;
        i--;
      }
    }

    setFiles([]);
    setFilesCount(0);
    setUploadPercent(0);
    if (handleFileCancel.current == 1) {
      uiNotificationService.show({
        title: 'Upload Study',
        message: 'Study has been uploaded successfully.',
        type: 'success',
        duration: 3000,
      });
      handleFileCancel.current = 0;
    }
  }

  function BrowseUpload() {
    if (filesCount > 0) {
      handleFileCancel.current = 1;
      const batches = chunkArray(files, 150);
      uploadBatches(batches);
    } else {
      uiNotificationService.show({
        title: 'Upload Study',
        message: 'Please select files to upload.',
        type: 'warning',
        duration: 3000,
      });
    }
  }

  const handleCancel = () => {
    if (filesCount > 0) {
      const windowConfirm = window.confirm('Are you sure you want to cancel the upload?');

      if (windowConfirm) {
        setFiles([]);
        handleFileCancel.current = 0;
        setFilesCount(0);
        setUploadPercent(0);
      }
    } else {
      setFiles([]);
      setFilesCount(0);
      setUploadPercent(0);
    }
  };

  const handleBack = () => {
    if (filesCount > 0) {
      const windowConfirm = window.confirm(
        'Are you sure you want to leave this page? Doing so will cancel the upload..'
      );

      if (windowConfirm) {
        setFiles([]);
        setFilesCount(0);
        setUploadPercent(0);
        navigate('/');
      }
    } else {
      navigate('/');
    }
  };

  return (
    <div className="flex h-screen w-full items-center justify-center bg-black">
      <div className="bg-primary-modalgray border-primary-newgray mx-auto flex flex-col items-center justify-center space-y-2 rounded-xl border p-8 pt-4 drop-shadow-md">
        <div className="flex w-full justify-center">
          <div className="p-2 text-left font-bold text-white">
            {folderName == '' ? 'Upload to Bucket' : `Upload to Bucket Folder: ${folderName}`}
          </div>
        </div>
        {folderName == '' && (
          <div>
            <span className="mt-1 text-xs text-red-300">
              * By default first bucket will be selected.
            </span>
            <SingleSelectCloud
              id="select-bucket"
              placeholder="Select Bucket"
              className="bg-primary-modalgradient mt-2 w-[700px]"
              options={bucketData}
              isMulti={false}
              isClearable={false}
              isSearchable={true}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={bucketSelection}
            />
          </div>
        )}
        {!sharedCase && folderName == '' && (
          <div>
            <span className="mt-1 text-xs text-red-300">
              * Not selecting a folder or selecting 'Default Studylist' will upload to root study
              list.
            </span>
            <SingleSelectCloud
              id="select-bucket"
              placeholder="Select Folder"
              className="bg-primary-modalgradient mt-2 w-[700px]"
              options={folders}
              isMulti={false}
              isClearable={false}
              isSearchable={true}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={folderSelection}
            />
          </div>
        )}
        <div className="w-full pt-5">
          <Dropzone
            onDrop={acceptedFiles => {
              onDrop(acceptedFiles);
            }}
            noClick
            noKeyboard
            disabled={uploadPercent > 0 ? true : false}
          >
            {({ getRootProps, getInputProps, open }) => (
              <div
                {...getRootProps()}
                className="border-primary-gray hover:border-primary-darkgray h-60 w-full cursor-pointer rounded-xl border-[1px] pt-10 transition duration-300"
                onClick={open}
              >
                <input
                  {...getInputProps()}
                  webkitdirectory="true"
                  mozdirectory="true"
                />
                <div className="flex flex-col items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={0.5}
                    stroke="white"
                    className="h-24 w-24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 00-1.883 2.542l.857 6a2.25 2.25 0 002.227 1.932H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-1.883-2.542m-16.5 0V6A2.25 2.25 0 016 3.75h3.879a1.5 1.5 0 011.06.44l2.122 2.12a1.5 1.5 0 001.06.44H18A2.25 2.25 0 0120.25 9v.776"
                    />
                  </svg>
                  <span className="text-primary-gray pt-5">
                    {filesCount > 0
                      ? filesCount + ' Files selected'
                      : 'Click Or Drop to load folders'}
                  </span>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        <div
          className={`bg-primary-light ${uploadPercent > 0 ? 'flex' : 'hidden'} h-1 justify-center rounded-lg px-20 pb-1 pt-1 text-white`}
          style={{ width: `${uploadPercent}%` }}
        >
          {uploadPercent}%
        </div>
        {/* {<div className='flex w-full justify-center px-20 pt-5'>

        </div>} */}
        <div className="flex w-full justify-center px-20 pt-5">
          {/* <div className="mr-5 flex w-28">
            <SwitchButton
              label="De-Identify"
              checked={isDeidentify}
              onChange={() => setDeidentify(!isDeidentify)}
              labelLocation={SwitchLabelLocation.right}
            />
          </div> */}
          <button
            onClick={handleBack}
            className="bg-primary-darkTable hover:bg-common-dark mr-4 w-40 rounded-2xl p-2 text-white transition duration-300"
          >
            Back
          </button>
          <button
            onClick={handleCancel}
            className="bg-primary-darkTable hover:bg-common-dark mr-4 w-40 rounded-2xl p-2 text-white transition duration-300"
          >
            Cancel
          </button>
          <button
            disabled={files.length > 0 || uploadPercent == 0 ? false : true}
            onClick={() => BrowseUpload()}
            className={`${files.length > 0 ? 'hover:bg-primary-darknav' : 'cursor-not-allowed'} bg-primary-light w-40 rounded-2xl p-2 text-white transition duration-300`}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
}

export default CloudUpload;
