import { createSlice } from '@reduxjs/toolkit';

export const listSlice = createSlice({
  name: 'list',
  initialState: {
    value: [],
    isLocal: false,
  },
  reducers: {
    list: (state, action) => {
      state.value = action.payload;
    },
    setIsLocal: (state, action) => {
      console.log(action.payload);
      state.isLocal = action.payload;
    },
  },
});

export const { list, setIsLocal } = listSlice.actions;

export default listSlice.reducer;
