import React from 'react';
import type { IconProps } from '../types';

export const Layers = (props: IconProps) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 15.00 15.00"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#ffffff"
    {...props}
    stroke-width="0.00015000000000000001"
  >
    <g
      id="SVGRepo_bgCarrier"
      stroke-width="0"
    />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 1.5V4H1.5C0.671573 4 0 4.67157 0 5.5V13.5C0 14.3284 0.671573 15 1.5 15H9.5C10.3284 15 11 14.3284 11 13.5V11H13.5C14.3284 11 15 10.3284 15 9.5V1.5C15 0.671573 14.3284 0 13.5 0H5.5C4.67157 0 4 0.671573 4 1.5ZM5.5 1C5.22386 1 5 1.22386 5 1.5V4H9.5C10.3284 4 11 4.67157 11 5.5V10H13.5C13.7761 10 14 9.77614 14 9.5V1.5C14 1.22386 13.7761 1 13.5 1H5.5ZM5.5 11C4.67157 11 4 10.3284 4 9.5V5H1.5C1.22386 5 1 5.22386 1 5.5V13.5C1 13.7761 1.22386 14 1.5 14H9.5C9.77614 14 10 13.7761 10 13.5V11H5.5Z"
        fill="#168ea9"
      />{' '}
    </g>
  </svg>
);

export default Layers;
