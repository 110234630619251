import CryptoJS from 'crypto-js';
import axios from 'axios';
import { API, BucketXRAD, XRADAPIKEY } from '../constants/baseurl';

export const secretKeyy = 'ufFm4swTgCbzTHXHWGeZPHrERMj2resQaHXjY5ioT0TDSRzBD3OLYejlLpuGYU5l';

export const getAllBuckets = async () => {
  const isXRAD = window.location.href.includes('/xrad/') || window.location.href.includes('/radx/');
  const urlParams = new URLSearchParams(window.location.search);
  const xradToken = urlParams.get('authToken');
  const token = isXRAD ? xradToken : localStorage.getItem('token');
  const user_data = JSON.parse(localStorage.getItem('user_data'));
  const client_id = user_data ? user_data._id : isXRAD ? '65c9c9d7d442077c5490b9c1' : 'N/A';

  try {
    const bucket_response = await axios.post(
      `${API}/api/v1/bucket/getSpecificUserBucket`,
      {
        user: client_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    const mappedBuckets = bucket_response.data.data.map(item => {
      return {
        _id: item._id,
        user: item.user,
        bucketName: item.bucketName,
        dcmcAccessKey: item.dcmcAccessKey,
        bucketType: item.bucketType,
        __v: item.__v,
        accessKeyId: CryptoJS.AES.decrypt(item.accessKeyId, secretKeyy).toString(CryptoJS.enc.Utf8),
        secretAccessKey: CryptoJS.AES.decrypt(item.secretAccessKey, secretKeyy).toString(
          CryptoJS.enc.Utf8
        ),
      };
    });

    return mappedBuckets;
  } catch (error) {
    console.error(error);
    return [];
  }
};

// export const getSpecificBucket = async bucketId => {
//   const buckets = await getAllBuckets();
//   const userBucket = buckets.find(item => item._id === bucketId);
//   return userBucket;
// };

export const getSpecificBucket = async bucketId => {
  const isXRAD = window.location.href.includes('/xrad/') || window.location.href.includes('/radx/');
  const isFolderPath = window.location.href.includes('folderPath');
  const urlParams = new URLSearchParams(window.location.search);
  const xradToken = urlParams.get('authToken');
  const token = isXRAD ? xradToken : localStorage.getItem('token');

  const getSpecificBucketSession = sessionStorage.getItem('bucketData');

  if (isXRAD && !isFolderPath) {
    try {
      const bucket_response = await axios.get(
        `${BucketXRAD}/External/GetBucketCredentials?Identifier=${bucketId}`,
        {
          headers: {
            'X-API-KEY': XRADAPIKEY,
          },
        }
      );

      if (bucket_response.data.data) {
        const response = bucket_response.data.data;
        if (response.cloudOption == 1) {
          const mappedBuckets = {
            bucketName: response.s3BucketCreds.bucketName,
            accessKeyId: response.s3BucketCreds.accessKey,
            secretAccessKey: response.s3BucketCreds.secretKey,
            dcmcAccessKey: '98u3498u398u498',
            region: response.s3BucketCreds.region,
          };

          return mappedBuckets;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  } else {
    try {
      const bucket_response = await axios.post(
        `${API}/api/v1/bucket/getSpecificBucketById`,
        {
          user: bucketId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const mappedBuckets = {
        _id: bucket_response.data.data._id,
        user: bucket_response.data.data.user,
        bucketName: bucket_response.data.data.bucketName,
        dcmcAccessKey: bucket_response.data.data.dcmcAccessKey,
        bucketType: bucket_response.data.data.bucketType,
        __v: bucket_response.data.data.__v,
        accessKeyId: CryptoJS.AES.decrypt(
          bucket_response.data.data.accessKeyId,
          secretKeyy
        ).toString(CryptoJS.enc.Utf8),
        secretAccessKey: CryptoJS.AES.decrypt(
          bucket_response.data.data.secretAccessKey,
          secretKeyy
        ).toString(CryptoJS.enc.Utf8),
      };

      return mappedBuckets;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

export const getBase64 = async bucketId => {
  const bucket = await getSpecificBucket(bucketId);

  const base64 =
    bucket.bucketName +
    '^' +
    bucket.accessKeyId +
    '^' +
    bucket.secretAccessKey +
    '^' +
    bucket.dcmcAccessKey;
  const base64String = btoa(base64);

  return base64String;
};
