import React from 'react';
import type { IconProps } from '../types';

export const UserPreferences = (props: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      fill="#2196f3"
      fill-rule="evenodd"
    >
      <path d="M20.812 14.916a.75.75 0 1 0-1.415-.497l-.162.46a3.8 3.8 0 0 0-1.504.082l-.21-.438a.75.75 0 1 0-1.352.65l.21.438c-.413.31-.751.693-1.004 1.122l-.46-.161a.75.75 0 0 0-.496 1.415l.459.161a3.8 3.8 0 0 0 .083 1.505l-.438.21a.75.75 0 1 0 .65 1.352l.437-.21c.31.413.694.751 1.123 1.004l-.161.46a.75.75 0 0 0 1.415.496l.161-.459a3.8 3.8 0 0 0 1.505-.083l.21.438a.75.75 0 1 0 1.352-.65l-.21-.437c.413-.31.751-.694 1.004-1.123l.46.16a.75.75 0 1 0 .496-1.414l-.46-.161a3.8 3.8 0 0 0-.082-1.505l.438-.21a.75.75 0 1 0-.65-1.352l-.438.21a3.8 3.8 0 0 0-1.122-1.004zm.104 4.541c.194-.563.175-1.2-.102-1.779l-.002-.005-.003-.005a2.352 2.352 0 1 0-1.108 3.149l.01-.005.009-.004c.576-.28.987-.764 1.187-1.324l.004-.013zm-3.295-.25a1.188 1.188 0 1 0 2.141-1.03 1.188 1.188 0 0 0-2.14 1.03" />
      <path
        d="M12.997.241c-1.538 0-2.885.908-3.458 2.224 1.313.813 2.208 2.24 2.208 3.917q0 .464-.088.898c.417.155.868.239 1.338.239 2.044 0 3.75-1.603 3.75-3.639S15.04.241 12.997.241m1.75 14.5h.601a1.5 1.5 0 0 1 2.809-.62q.282-.034.563-.032a1.5 1.5 0 0 1 1.027-.879v-2.682c0-.467-.255-.885-.648-1.111a12.27 12.27 0 0 0-7.605-1.532 4.6 4.6 0 0 1-1.19 1.827c1.141.293 2.25.74 3.294 1.34a2.28 2.28 0 0 1 1.149 1.978zm-1.045 1.39a1.5 1.5 0 0 0-.484.762c-.266.22-.608.35-.971.35h-10.5c-.802 0-1.5-.632-1.5-1.472v-2.74c0-.468.254-.886.647-1.112a12.28 12.28 0 0 1 12.205 0c.393.226.648.644.648 1.111v2.741q0 .188-.045.36M3.247 6.382c0-2.036 1.705-3.639 3.75-3.639s3.75 1.603 3.75 3.64c0 2.035-1.706 3.638-3.75 3.638s-3.75-1.603-3.75-3.639"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default UserPreferences;
