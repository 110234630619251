import React, { useEffect, useState } from 'react';
import updateservices from './apis/services/updateservices';

export default function Finished({ selectedCards }) {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const map = {
      'de-identification': 'deID',
      'image-render': '3D',
      'image-archive': 'Archive',
      sharing: 'Sharing',
      client: 'Templates',
      converter: 'Converter',
      machine: 'Machine',
      'block-chain': 'Blockchain',
    };

    const mappedArray = [];

    selectedCards.forEach(item => {
      if (map[item]) {
        mappedArray.push(map[item]);
      }
    });

    const user_data = JSON.parse(localStorage.getItem('user_data') || '{}');

    updateservices(user_data._id, mappedArray)
      .then(response => {
        if (!response.error) {
          localStorage.setItem('user_data', JSON.stringify(response.user));
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  return (
    <div className="flex h-[70vh] flex-col items-center justify-center">
      <div className="flex-col">
        <div
          className={`border-primary-signactivedark border-gradient-purple relative mt-10 flex h-24 w-24 cursor-pointer items-center justify-center rounded-full border-[2px] p-[4px]`}
        >
          <svg
            fill="none"
            stroke="#FFF"
            viewBox="0 0 24 24"
            strokeWidth="2"
          >
            <path
              d="m5 13 3 4L19 7"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div className={`absolute right-[-2px] h-[calc(30%+1px)] w-1 bg-[#1c2c44]`}></div>
        </div>
      </div>
      <div className="justify-centerfont-semibold mt-5 flex flex-col items-center text-white">
        <span className="text-center text-2xl sm:text-5xl">Thank You for Joining Us.</span>
        <span className="w-96 text-center text-xl sm:text-3xl">
          Your Account Has Been Created Successfully.
        </span>
      </div>
      <div className="from-primary-signactivedark to-primary-signactivelight mt-10 flex cursor-pointer items-center justify-center rounded-3xl bg-gradient-to-r p-2 px-10 text-xl text-white transition duration-300 hover:scale-105">
        Go To Home
      </div>
    </div>
  );
}
