import React from 'react';
import type { IconProps } from '../types';

export const Compare = (props: IconProps) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    {...props}
  >
    <g stroke-width="0" />
    <g
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g clip-path="url(#a)">
      <path
        d="M13 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7m4-16h1a2 2 0 0 1 2 2v1m0 10v1a2 2 0 0 1-2 2h-1m3-9v2M12 2v20"
        stroke-width="1.656"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Compare;
