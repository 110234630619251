import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LegacyButton from '../LegacyButton';
import LegacyButtonGroup from '../LegacyButtonGroup';
import Typography from '../Typography';
import Select from '../Select';

const StudyListPagination = ({
  onChangePage,
  currentPage,
  perPage,
  onChangePerPage,
  tableStudiesLength,
  totalStudies,
}) => {
  const { t } = useTranslation('StudyList');

  const navigateToPage = page => {
    const toPage = page < 1 ? 1 : page;
    onChangePage(toPage);
  };

  const ranges = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ];
  const [selectedRange, setSelectedRange] = useState(ranges.find(r => r.value === perPage));
  const onSelectedRange = selectedRange => {
    setSelectedRange(selectedRange);
    onChangePerPage(selectedRange.value);
  };

  const totalPages = Math.ceil(totalStudies / perPage);

  return (
    <div className="dark:bg-primary-modalgray bg-white py-4">
      <div className="relative mx-1 sm:mx-4 md:mx-6">
        <div className="flex justify-between">
          <div className="flex items-center">
            <Select
              id="rows-per-page"
              className="border-primary-gray relative mr-3 w-24"
              options={ranges}
              value={selectedRange}
              isMulti={false}
              isClearable={false}
              isSearchable={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={true}
              onChange={onSelectedRange}
            />
            <Typography className="text-black dark:text-white">{t('Results per page')}</Typography>
          </div>
          <div className="">
            <div className="flex items-center">
              {/* <Typography className="mr-4 text-black dark:text-white">
                {t('Page')} {currentPage}
              </Typography> */}
              <div
                onClick={() => navigateToPage(currentPage - 1)}
                className="dark:bg-primary-newgray dark:hover:bg-primary-darktable hover:bg-primary-gray dark:border-primary-newgray border-primary-gray cursor-pointer rounded-l-md border-[1px] bg-white p-[6px] text-base transition duration-200 dark:text-white"
              >
                Previous
              </div>
              {currentPage - 2 > 0 && (
                <div
                  onClick={() => navigateToPage(currentPage - 2)}
                  className="dark:bg-primary-newgray dark:hover:bg-primary-darktable hover:bg-primary-gray dark:border-primary-newgray border-primary-gray mx-1 cursor-pointer border-[1px] bg-white p-[6px] px-3 text-base transition duration-200 dark:text-white"
                >
                  {currentPage - 2}
                </div>
              )}
              {currentPage - 1 > 0 && (
                <div
                  onClick={() => navigateToPage(currentPage - 1)}
                  className={`${currentPage - 2 > 0 ? 'ml-0' : 'ml-1'} dark:bg-primary-newgray dark:hover:bg-primary-darktable hover:bg-primary-gray dark:border-primary-newgray border-primary-gray mx-1 cursor-pointer border-[1px] bg-white p-[6px] px-3 text-base transition duration-200 dark:text-white`}
                >
                  {currentPage - 1}
                </div>
              )}

              <div
                className={`${currentPage - 1 === 0 ? 'ml-1' : ''} ${currentPage + 1 <= totalPages ? '' : 'mr-1'} bg-primary-light dark:border-primary-light border-primary-gray mx-0 cursor-pointer border-[1px] p-[6px] px-3 text-base font-bold transition duration-200 dark:text-white`}
              >
                {currentPage}
              </div>
              {currentPage + 1 <= totalPages && (
                <div
                  onClick={() => navigateToPage(currentPage + 1)}
                  className={`dark:bg-primary-newgray ${currentPage + 2 <= totalPages ? '' : 'ml-1'} dark:hover:bg-primary-darktable hover:bg-primary-gray dark:border-primary-newgray border-primary-gray mx-1 cursor-pointer border-[1px] bg-white p-[6px] px-3 text-base transition duration-200 dark:text-white`}
                >
                  {currentPage + 1}
                </div>
              )}
              {currentPage + 2 <= totalPages && (
                <div
                  onClick={() => navigateToPage(currentPage + 2)}
                  className="dark:bg-primary-newgray dark:border-primary-newgray dark:hover:bg-primary-darktable hover:bg-primary-gray border-primary-gray mx-1 ml-0 cursor-pointer border-[1px] bg-white p-[6px] px-3 text-base transition duration-200 dark:text-white"
                >
                  {currentPage + 2}
                </div>
              )}
              <div
                onClick={() => navigateToPage(currentPage + 1)}
                className="dark:bg-primary-newgray dark:hover:bg-primary-darktable hover:bg-primary-gray dark:border-primary-newgray border-primary-gray cursor-pointer rounded-r-md border-[1px] bg-white p-[6px] text-base transition duration-200 dark:text-white"
              >
                Next
              </div>
              {/* TODO Revisit design of LegacyButtonGroup later - for now use LegacyButton for its children.*/}
              {/* <LegacyButtonGroup>
                <LegacyButton
                  size="initial"
                  className="border-primary-gray hover:border-primary-light px-2 py-2 text-lg text-black dark:text-white"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={() => navigateToPage(1)}
                >
                  {`<<`}
                </LegacyButton>
                <LegacyButton
                  size="initial"
                  className="border-primary-gray hover:border-primary-light py-2 px-2 text-lg text-black dark:text-white"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={() => navigateToPage(currentPage - 1)}
                >
                  {t('Previous')}
                </LegacyButton>
                <LegacyButton
                  size="initial"
                  className="border-primary-gray hover:border-primary-light py-2 px-2 text-lg text-black dark:text-white"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={() => navigateToPage(currentPage + 1)}
                >
                  {t('Next')}
                </LegacyButton>
              </LegacyButtonGroup> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StudyListPagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  onChangePerPage: PropTypes.func.isRequired,
};

export default StudyListPagination;
