import React from 'react';
import type { IconProps } from '../types';

export const ListView = (props: IconProps) => (
  // <svg
  //   width="24px"
  //   height="24px"
  //   viewBox="0 0 24 24"
  //   version="1.1"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     id="icon-list-view"
  //     stroke="none"
  //     strokeWidth="1"
  //     fill="none"
  //     fillRule="evenodd"
  //   >
  //     <rect
  //       id="Rectangle"
  //       x="0"
  //       y="0"
  //       width="24"
  //       height="24"
  //     ></rect>
  //     <line
  //       x1="10.5"
  //       y1="8"
  //       x2="18.5"
  //       y2="8"
  //       id="Line-2"
  //       stroke="currentColor"
  //       strokeLinecap="round"
  //     ></line>
  //     <line
  //       x1="10.5"
  //       y1="12"
  //       x2="18.5"
  //       y2="12"
  //       id="Line-2"
  //       stroke="currentColor"
  //       strokeLinecap="round"
  //     ></line>
  //     <line
  //       x1="10.5"
  //       y1="16"
  //       x2="18.5"
  //       y2="16"
  //       id="Line-2"
  //       stroke="currentColor"
  //       strokeLinecap="round"
  //     ></line>
  //     <circle
  //       id="Oval"
  //       fill="currentColor"
  //       cx="7"
  //       cy="8"
  //       r="1"
  //     ></circle>
  //     <circle
  //       id="Oval"
  //       fill="currentColor"
  //       cx="7"
  //       cy="12"
  //       r="1"
  //     ></circle>
  //     <circle
  //       id="Oval"
  //       fill="currentColor"
  //       cx="7"
  //       cy="16"
  //       r="1"
  //     ></circle>
  //   </g>
  // </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="14px"
    height="14px"
    {...props}
  >
    <path
      fill="#4dbbeb"
      d="M467 30H225c-24.901 0-45 20.099-45 45s20.099 45 45 45h242c24.901 0 45-20.099 45-45s-20.099-45-45-45m0 182H225c-24.901 0-45 20.099-45 45 0 24.899 20.099 45 45 45h242c24.901 0 45-20.101 45-45 0-24.901-20.099-45-45-45"
    />
    <path
      fill="#4dbbeb"
      d="M512 257c0 24.899-20.099 45-45 45H225c-24.901 0-45-20.101-45-45 0-24.901 20.099-45 45-45h242c24.901 0 45 20.099 45 45m0-182c0 24.901-20.099 45-45 45H225c-24.901 0-45-20.099-45-45s20.099-45 45-45h242c24.901 0 45 20.099 45 45m-45 317H225c-24.901 0-45 20.099-45 45 0 24.899 20.099 45 45 45h242c24.901 0 45-20.101 45-45 0-24.901-20.099-45-45-45"
    />
    <path
      fill="#4dbbeb"
      d="M512 437c0 24.899-20.099 45-45 45H225c-24.901 0-45-20.101-45-45 0-24.901 20.099-45 45-45h242c24.901 0 45 20.099 45 45"
    />
    <path
      fill="#159ce4"
      d="M75 0C33.6 0 0 33.6 0 75s33.6 75 75 75 75-33.6 75-75S116.4 0 75 0m0 182c-41.4 0-75 33.6-75 75s33.6 75 75 75 75-33.6 75-75-33.6-75-75-75m0 180c-41.4 0-75 33.6-75 75s33.6 75 75 75 75-33.6 75-75-33.6-75-75-75"
    />
    <path
      fill="#159ce4"
      d="M150 437c0 41.4-33.6 75-75 75V362c41.4 0 75 33.6 75 75m0-180c0 41.4-33.6 75-75 75V182c41.4 0 75 33.6 75 75m0-182c0 41.4-33.6 75-75 75V0c41.4 0 75 33.6 75 75m362 362c0 24.899-20.099 45-45 45H347v-90h120c24.901 0 45 20.099 45 45m0-180c0 24.899-20.099 45-45 45H347v-90h120c24.901 0 45 20.099 45 45m-45-137H347V30h120c24.901 0 45 20.099 45 45s-20.099 45-45 45"
    />
    <g />
  </svg>
);

export default ListView;
