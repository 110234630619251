import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { packages } from '../../../../../../platform/app/src/redux/features/register/packagelist';

export default function Free() {
  const monthly = {
    id: 'free',
    title: 'Without Your Own Storage',
    subtitle: '',
    features: [
      '4 DICOM Files/Month',
      'Monthly Single Share Link',
      'Can Upload DICOM Images',
      'Can Connect your Bucket',
    ],
    price: 'Free',
    subprice: 'includes',
  };

  const dispatch = useDispatch();
  const isPackages = useSelector(state => state.packages.value);

  const handleOnClick = () => {
    if (isPackages.id == 'Free') {
      dispatch(
        packages({
          id: '',
          title: '',
          subtitle: '',
          price: '',
          type: '',
        })
      );
    } else {
      dispatch(
        packages({
          id: 'Free',
          title: 'Free',
          subtitle: '',
          price: 'Free',
          type: '',
        })
      );
    }
  };

  return (
    <div className="mt-2 flex flex-col items-center justify-center">
      <div className="mt-3 flex w-[1045px] items-center justify-center text-white">
        <div
          onClick={() => handleOnClick()}
          className={`${isPackages.id == 'Free' ? 'from-primary-signactivedark to-primary-signactivelight' : 'from-primary-signcard to-primary-signcarddark'} relative ml-5 flex h-72 w-64 cursor-pointer flex-col items-center rounded-xl bg-gradient-to-r shadow-xl transition duration-300 first:ml-0 hover:scale-105`}
        >
          <div
            className={`${isPackages.id == 'Free' ? 'from-primary-signcard to-primary-signcarddark' : 'from-primary-signactivedark to-primary-signactivelight'} mb-5 flex h-14 w-full items-center justify-center rounded-xl bg-gradient-to-r`}
          >
            {isPackages.id == 'Free' ? 'FREE - SELECTED' : 'FREE'}
          </div>
          {monthly.features.map(feature => (
            <div className="my-1 flex">
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="#1DADE4"
                strokeWidth="3"
                viewBox="0 0 24 24"
              >
                <path d="M5 13l4 4L19 7" />
              </svg>
              <span className="ml-3 w-40 text-sm">{feature}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
