import React from 'react';
import type { IconProps } from '../types';

export const LayoutAdvanced3DFourUp = (props: IconProps) => (
  // <svg
  //   width="29px"
  //   height="18px"
  //   viewBox="0 0 29 18"
  //   version="1.1"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     id="Layout-select----FINAL"
  //     stroke="none"
  //     strokeWidth="1"
  //     fill="none"
  //     fillRule="evenodd"
  //   >
  //     <g
  //       id="layout-advanced-3d-four-up"
  //       transform="translate(1, 1)"
  //       stroke="currentColor"
  //     >
  //       <rect
  //         id="Rectangle"
  //         x="0"
  //         y="0"
  //         width="27"
  //         height="16.2"
  //         rx="2"
  //       ></rect>
  //       <path
  //         d="M0,0 L11.8857143,-2.22044605e-16 C12.9902838,5.56104062e-16 13.8857143,0.8954305 13.8857143,2 L13.8857143,14.2 C13.8857143,15.3045695 12.9902838,16.2 11.8857143,16.2 L0,16.2 L0,16.2 L0,0 Z"
  //         id="Rectangle"
  //         transform="translate(6.9429, 8.1) scale(-1, 1) translate(-6.9429, -8.1)"
  //       ></path>
  //       <path
  //         d="M13.8857143,0 L25,0 C26.1045695,-1.18396092e-15 27,0.8954305 27,2 L27,8.5 L27,8.5 L13.8857143,8.5 L13.8857143,0 Z"
  //         id="Rectangle"
  //         fill="#5acce6"
  //       ></path>
  //       <path
  //         d="M0,8.48571429 L13.8857143,8.48571429 L13.8857143,14.2 C13.8857143,15.3045695 12.9902838,16.2 11.8857143,16.2 L0,16.2 L0,16.2 L0,8.48571429 Z"
  //         id="Rectangle"
  //         transform="translate(6.9429, 12.3429) scale(-1, 1) translate(-6.9429, -12.3429)"
  //       ></path>
  //       <path
  //         d="M13.8857143,8.48571429 L27,8.48571429 L27,14.2 C27,15.3045695 26.1045695,16.2 25,16.2 L13.8857143,16.2 L13.8857143,16.2 L13.8857143,8.48571429 Z"
  //         id="Rectangle"
  //       ></path>
  //     </g>
  //   </g>
  // </svg>
  <svg
    height="25"
    viewBox="0 0 100 100"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path
      fill="#5acce6"
      d="M53.333 46.664H90V16.667C90 13.001 86.999 10 83.333 10h-30z"
    />
    <path d="M46.667 46.664V10h-30C13 10 10 13.001 10 16.667v29.997zm6.666 6.666V90h30C86.999 90 90 86.999 90 83.333V53.33zm-6.666 0H10v30.003C10 86.999 13 90 16.667 90h30z" />
  </svg>
);

export const LayoutAdvanced3DMain = (props: IconProps) => (
  // <svg
  //   width="29px"
  //   height="18px"
  //   viewBox="0 0 29 18"
  //   version="1.1"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     id="Layout-select----FINAL"
  //     stroke="none"
  //     strokeWidth="1"
  //     fill="none"
  //     fillRule="evenodd"
  //   >
  //     <g
  //       id="layout-advanced-3d-main"
  //       transform="translate(1, 1)"
  //       stroke="currentColor"
  //     >
  //       <rect
  //         id="Rectangle"
  //         x="0"
  //         y="0"
  //         width="27"
  //         height="16.2"
  //         rx="2"
  //       ></rect>
  //       <path
  //         d="M18,7.71428571 L27,7.71428571 L27,14.2 C27,15.3045695 26.1045695,16.2 25,16.2 L18,16.2 L18,16.2 L18,7.71428571 Z"
  //         id="Rectangle"
  //       ></path>
  //       <path
  //         d="M0,7.71428571 L9.25714286,7.71428571 L9.25714286,16.2 L2,16.2 C0.8954305,16.2 9.55813795e-16,15.3045695 8.8817842e-16,14.2 L0,7.71428571 L0,7.71428571 Z"
  //         id="Rectangle"
  //       ></path>
  //       <path
  //         d="M2,0 L25,0 C26.1045695,-2.02906125e-16 27,0.8954305 27,2 L27,8.48571429 L27,8.48571429 L0,8.48571429 L0,2 C-1.3527075e-16,0.8954305 0.8954305,1.3527075e-16 2,0 Z"
  //         id="Rectangle"
  //         fill="#5acce6"
  //         transform="translate(13.5, 4.2429) scale(-1, 1) translate(-13.5, -4.2429)"
  //       ></path>
  //     </g>
  //   </g>
  // </svg>
  <svg
    height="25"
    viewBox="0 0 100 100"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path d="M31.667 86.667c0 1.832-1.5 3.333-3.333 3.333h-15A3.344 3.344 0 0 1 10 86.667v-15c0-1.833 1.5-3.334 3.333-3.334h15a3.344 3.344 0 0 1 3.333 3.334zm29.166 0c0 1.832-1.5 3.333-3.333 3.333h-15a3.344 3.344 0 0 1-3.333-3.333v-15c0-1.833 1.5-3.334 3.333-3.334h15a3.344 3.344 0 0 1 3.333 3.334z" />
    <path
      fill="#5acce6"
      d="M90 57.5a3.344 3.344 0 0 1-3.333 3.333H13.333A3.343 3.343 0 0 1 10 57.5V13.333C10 11.5 11.5 10 13.333 10h73.333C88.499 10 90 11.5 90 13.333z"
    />
    <path d="M90 86.667A3.344 3.344 0 0 1 86.667 90h-15a3.344 3.344 0 0 1-3.334-3.333v-15a3.345 3.345 0 0 1 3.334-3.334h15A3.344 3.344 0 0 1 90 71.667z" />
  </svg>
);

export const LayoutAdvanced3DOnly = (props: IconProps) => (
  <svg
    width="29px"
    height="18px"
    viewBox="0 0 29 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="Layout-select----FINAL"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="layout-advanced-3d-only"
        transform="translate(1, 1)"
        fill="#5acce6"
        stroke="currentColor"
      >
        <rect
          id="Rectangle"
          x="0"
          y="0"
          width="27"
          height="16.2"
          rx="2"
        ></rect>
      </g>
    </g>
  </svg>
);

export const LayoutAdvanced3DPrimary = (props: IconProps) => (
  // <svg
  //   width="29px"
  //   height="18px"
  //   viewBox="0 0 29 18"
  //   version="1.1"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     id="Layout-select----FINAL"
  //     stroke="none"
  //     strokeWidth="1"
  //     fill="none"
  //     fillRule="evenodd"
  //   >
  //     <g
  //       id="layout-advanced-3d-primary"
  //       transform="translate(1, 1)"
  //       stroke="currentColor"
  //     >
  //       <rect
  //         id="Rectangle"
  //         x="0"
  //         y="0"
  //         width="27"
  //         height="16.2"
  //         rx="2"
  //       ></rect>
  //       <path
  //         d="M15.4285714,10.5 L27,10.5 L27,14 C27,15.1045695 26.1045695,16 25,16 L15.4285714,16 L15.4285714,16 L15.4285714,10.5 Z"
  //         id="Rectangle"
  //       ></path>
  //       <path
  //         d="M15.4285714,0 L25,0 C26.1045695,-2.02906125e-16 27,0.8954305 27,2 L27,5.4 L27,5.4 L15.4285714,5.4 L15.4285714,0 Z"
  //         id="Rectangle"
  //       ></path>
  //       <path
  //         d="M0,0 L14.2,0 C15.3045695,-2.02906125e-16 16.2,0.8954305 16.2,2 L16.2,14.2 C16.2,15.3045695 15.3045695,16.2 14.2,16.2 L0,16.2 L0,16.2 L0,0 Z"
  //         id="Rectangle"
  //         fill="#5acce6"
  //         transform="translate(8.1, 8.1) scale(-1, 1) translate(-8.1, -8.1)"
  //       ></path>
  //     </g>
  //   </g>
  // </svg>
  <svg
    height="25"
    viewBox="0 0 100 100"
    width="25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M86.668 68.335a3.345 3.345 0 0 1 3.334 3.334v15a3.344 3.344 0 0 1-3.334 3.333h-15a3.344 3.344 0 0 1-3.333-3.333v-15a3.344 3.344 0 0 1 3.333-3.334zm0-29.167a3.345 3.345 0 0 1 3.334 3.334v15a3.344 3.344 0 0 1-3.334 3.333h-15a3.344 3.344 0 0 1-3.333-3.333v-15a3.344 3.344 0 0 1 3.333-3.334z" />
    <path
      fill="#5acce6"
      d="M57.502 10.002c1.832 0 3.333 1.5 3.333 3.333v73.334a3.344 3.344 0 0 1-3.333 3.333H13.334a3.344 3.344 0 0 1-3.333-3.333V13.335a3.344 3.344 0 0 1 3.333-3.333z"
    />
    <path d="M86.668 10.002c1.833 0 3.334 1.5 3.334 3.333v15a3.344 3.344 0 0 1-3.334 3.333h-15a3.344 3.344 0 0 1-3.333-3.333v-15a3.344 3.344 0 0 1 3.333-3.333z" />
  </svg>
);

export const LayoutAdvancedAxialPrimary = (props: IconProps) => (
  // <svg
  //   width="29px"
  //   height="18px"
  //   viewBox="0 0 29 18"
  //   version="1.1"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     id="Layout-select----FINAL"
  //     stroke="none"
  //     strokeWidth="1"
  //     fill="none"
  //     fillRule="evenodd"
  //   >
  //     <g
  //       id="layout-advanced-axial-primary"
  //       transform="translate(1, 1)"
  //       stroke="currentColor"
  //     >
  //       <path
  //         d="M15.4285714,0 L25,0 C26.1045695,-2.02906125e-16 27,0.8954305 27,2 L27,14.2 C27,15.3045695 26.1045695,16.2 25,16.2 L15.4285714,16.2 L15.4285714,16.2 L15.4285714,0 Z"
  //         id="Rectangle"
  //       ></path>
  //       <path
  //         d="M15.4285714,0 L25,0 C26.1045695,-2.02906125e-16 27,0.8954305 27,2 L27,8.48571429 L27,8.48571429 L15.4285714,8.48571429 L15.4285714,0 Z"
  //         id="Rectangle"
  //       ></path>
  //       <path
  //         d="M4.54747351e-13,0 L13.7,0 C14.8045695,-2.02906125e-16 15.7,0.8954305 15.7,2 L15.7,14.2 C15.7,15.3045695 14.8045695,16.2 13.7,16.2 L4.54747351e-13,16.2 L4.54747351e-13,16.2 L4.54747351e-13,0 Z"
  //         id="Rectangle"
  //         transform="translate(7.85, 8.1) scale(-1, 1) translate(-7.85, -8.1)"
  //       ></path>
  //     </g>
  //   </g>
  // </svg>
  <svg
    height="25"
    viewBox="0 0 100 100"
    width="25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M46.667 86.667A3.344 3.344 0 0 1 43.333 90h-30A3.344 3.344 0 0 1 10 86.667V13.333A3.344 3.344 0 0 1 13.333 10h30a3.344 3.344 0 0 1 3.334 3.333zM90 43.333a3.344 3.344 0 0 1-3.333 3.334h-30a3.345 3.345 0 0 1-3.334-3.334v-30A3.344 3.344 0 0 1 56.667 10h30A3.344 3.344 0 0 1 90 13.333zm0 43.334A3.344 3.344 0 0 1 86.667 90h-30a3.344 3.344 0 0 1-3.334-3.333v-30a3.345 3.345 0 0 1 3.334-3.334h30A3.344 3.344 0 0 1 90 56.667z" />
  </svg>
);

export const LayoutAdvancedMPR = (props: IconProps) => (
  // <svg
  //   width="29px"
  //   height="18px"
  //   viewBox="0 0 29 18"
  //   version="1.1"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     id="Layout-select----FINAL"
  //     stroke="none"
  //     strokeWidth="1"
  //     fill="none"
  //     fillRule="evenodd"
  //   >
  //     <g
  //       id="layout-advanced-mpr"
  //       transform="translate(1, 1)"
  //       stroke="currentColor"
  //     >
  //       <path
  //         d="M17.7428571,0 L25,0 C26.1045695,-2.02906125e-16 27,0.8954305 27,2 L27,14.2 C27,15.3045695 26.1045695,16.2 25,16.2 L17.7428571,16.2 L17.7428571,16.2 L17.7428571,0 Z"
  //         id="Rectangle"
  //       ></path>
  //       <rect
  //         id="Rectangle"
  //         x="9"
  //         y="0"
  //         width="9"
  //         height="16.2"
  //       ></rect>
  //       <path
  //         d="M0,0 L7.25714286,0 C8.36171236,-1.18396092e-15 9.25714286,0.8954305 9.25714286,2 L9.25714286,14.2 C9.25714286,15.3045695 8.36171236,16.2 7.25714286,16.2 L0,16.2 L0,16.2 L0,0 Z"
  //         id="Rectangle"
  //         transform="translate(4.6286, 8.1) scale(-1, 1) translate(-4.6286, -8.1)"
  //       ></path>
  //     </g>
  //   </g>
  // </svg>
  <svg
    height="25"
    viewBox="0 0 100 100"
    width="25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M31.667 86.667c0 1.832-1.5 3.333-3.333 3.333h-15A3.344 3.344 0 0 1 10 86.667V13.333C10 11.5 11.5 10 13.333 10h15c1.833 0 3.333 1.5 3.333 3.333zm29.166 0c0 1.832-1.5 3.333-3.333 3.333h-15a3.344 3.344 0 0 1-3.333-3.333V13.333c0-1.833 1.5-3.333 3.333-3.333h15c1.833 0 3.333 1.5 3.333 3.333zm29.167 0A3.344 3.344 0 0 1 86.667 90h-15a3.344 3.344 0 0 1-3.334-3.333V13.333A3.344 3.344 0 0 1 71.667 10h15C88.499 10 90 11.5 90 13.333z" />
  </svg>
);

export const LayoutCommon1x1 = (props: IconProps) => (
  // <svg
  //   width="28px"
  //   height="18px"
  //   viewBox="0 0 28 18"
  //   version="1.1"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     id="Layout-select----FINAL"
  //     stroke="none"
  //     strokeWidth="1"
  //     fill="none"
  //     fillRule="evenodd"
  //   >
  //     <g
  //       id="layout-common-1x1"
  //       transform="translate(1, 1)"
  //       stroke="currentColor"
  //     >
  //       <rect
  //         id="Rectangle"
  //         x="0"
  //         y="0"
  //         width="26"
  //         height="15.6"
  //         rx="2"
  //       ></rect>
  //     </g>
  //   </g>
  // </svg>
  <svg
    height="25"
    viewBox="-2 -1 20 20"
    width="25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.187 2.275H5a3.003 3.003 0 0 0-3 3v6.186a3.003 3.003 0 0 0 3 3h6.187a3.003 3.003 0 0 0 3-3V5.275a3.003 3.003 0 0 0-3-3m1 9.186a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5.275a1 1 0 0 1 1-1h6.187a1 1 0 0 1 1 1z" />
  </svg>
);

export const LayoutCommon1x2 = (props: IconProps) => (
  // <svg
  //   width="28px"
  //   height="18px"
  //   viewBox="0 0 28 18"
  //   version="1.1"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     id="Layout-select----FINAL"
  //     stroke="none"
  //     strokeWidth="1"
  //     fill="none"
  //     fillRule="evenodd"
  //   >
  //     <g
  //       id="layout-common-1x2"
  //       transform="translate(1, 1)"
  //       stroke="currentColor"
  //     >
  //       <rect
  //         id="Rectangle"
  //         x="0"
  //         y="0"
  //         width="26"
  //         height="15.6"
  //         rx="2"
  //       ></rect>
  //       <path
  //         d="M0,0 L11,-4.4408921e-16 C12.1045695,-6.46995335e-16 13,0.8954305 13,2 L13,13.6 C13,14.7045695 12.1045695,15.6 11,15.6 L0,15.6 L0,15.6 L0,0 Z"
  //         id="Rectangle"
  //         transform="translate(6.5, 7.8) scale(-1, 1) translate(-6.5, -7.8)"
  //       ></path>
  //     </g>
  //   </g>
  // </svg>
  <svg
    height="25"
    viewBox="0 0 32 16"
    width="25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.187 2.275H5a3.003 3.003 0 0 0-3 3v6.186a3.003 3.003 0 0 0 3 3h6.187a3.003 3.003 0 0 0 3-3V5.275a3.003 3.003 0 0 0-3-3m1 9.186a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5.275a1 1 0 0 1 1-1h6.187a1 1 0 0 1 1 1zM27 2.275h-6.187a3.003 3.003 0 0 0-3 3v6.186a3.003 3.003 0 0 0 3 3H27a3.003 3.003 0 0 0 3-3V5.275a3.003 3.003 0 0 0-3-3m1 9.186a1 1 0 0 1-1 1h-6.187a1 1 0 0 1-1-1V5.275a1 1 0 0 1 1-1H27a1 1 0 0 1 1 1z" />
  </svg>
);

export const LayoutCommon2x2 = (props: IconProps) => (
  // <svg
  //   width="28px"
  //   height="18px"
  //   viewBox="0 0 28 18"
  //   version="1.1"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     id="Layout-select----FINAL"
  //     stroke="none"
  //     strokeWidth="1"
  //     fill="none"
  //     fillRule="evenodd"
  //   >
  //     <g
  //       id="layout-common-2x2"
  //       transform="translate(1, 1)"
  //       stroke="currentColor"
  //     >
  //       <rect
  //         id="Rectangle"
  //         x="0"
  //         y="0"
  //         width="26"
  //         height="15.6"
  //         rx="2"
  //       ></rect>
  //       <path
  //         d="M0,0 L11,-4.4408921e-16 C12.1045695,-6.46995335e-16 13,0.8954305 13,2 L13,13.6 C13,14.7045695 12.1045695,15.6 11,15.6 L0,15.6 L0,15.6 L0,0 Z"
  //         id="Rectangle"
  //         transform="translate(6.5, 7.8) scale(-1, 1) translate(-6.5, -7.8)"
  //       ></path>
  //       <path
  //         d="M2,0 L24,0 C25.1045695,-2.02906125e-16 26,0.8954305 26,2 L26,8 L26,8 L0,8 L0,2 C-1.3527075e-16,0.8954305 0.8954305,1.3527075e-16 2,0 Z"
  //         id="Rectangle"
  //         transform="translate(13, 4) scale(-1, 1) translate(-13, -4)"
  //       ></path>
  //     </g>
  //   </g>
  // </svg>
  <svg
    height="25"
    viewBox="-3 -4 42 42"
    width="25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.187 2.275H5a3.003 3.003 0 0 0-3 3v6.186a3.003 3.003 0 0 0 3 3h6.187a3.003 3.003 0 0 0 3-3V5.275a3.003 3.003 0 0 0-3-3m1 9.186a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5.275a1 1 0 0 1 1-1h6.187a1 1 0 0 1 1 1zM27 2.275h-6.187a3.003 3.003 0 0 0-3 3v6.186a3.003 3.003 0 0 0 3 3H27a3.003 3.003 0 0 0 3-3V5.275a3.003 3.003 0 0 0-3-3m1 9.186a1 1 0 0 1-1 1h-6.187a1 1 0 0 1-1-1V5.275a1 1 0 0 1 1-1H27a1 1 0 0 1 1 1zM11.187 17.54H5a3.003 3.003 0 0 0-3 3v6.185a3.003 3.003 0 0 0 3 3h6.187a3.003 3.003 0 0 0 3-3V20.54a3.003 3.003 0 0 0-3-3m1 9.185a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V20.54a1 1 0 0 1 1-1h6.187a1 1 0 0 1 1 1zM27 17.54h-6.187a3.003 3.003 0 0 0-3 3v6.185a3.003 3.003 0 0 0 3 3H27a3.003 3.003 0 0 0 3-3V20.54a3.003 3.003 0 0 0-3-3m1 9.185a1 1 0 0 1-1 1h-6.187a1 1 0 0 1-1-1V20.54a1 1 0 0 1 1-1H27a1 1 0 0 1 1 1z" />
  </svg>
);

export const LayoutCommon2x3 = (props: IconProps) => (
  // <svg
  //   width="28px"
  //   height="18px"
  //   viewBox="0 0 28 18"
  //   version="1.1"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     id="Layout-select----FINAL"
  //     stroke="none"
  //     strokeWidth="1"
  //     fill="none"
  //     fillRule="evenodd"
  //   >
  //     <g
  //       id="layout-common-2x3"
  //       transform="translate(1, 1)"
  //       stroke="currentColor"
  //     >
  //       <rect
  //         id="Rectangle"
  //         x="0"
  //         y="0"
  //         width="26"
  //         height="15.6"
  //         rx="2"
  //       ></rect>
  //       <path
  //         d="M0,0 L7,0 C8.1045695,-2.02906125e-16 9,0.8954305 9,2 L9,13.6 C9,14.7045695 8.1045695,15.6 7,15.6 L0,15.6 L0,15.6 L0,0 Z"
  //         id="Rectangle"
  //         transform="translate(4.5, 7.8) scale(-1, 1) translate(-4.5, -7.8)"
  //       ></path>
  //       <path
  //         d="M0,0 L15,-4.4408921e-16 C16.1045695,-6.46995335e-16 17,0.8954305 17,2 L17,13.6 C17,14.7045695 16.1045695,15.6 15,15.6 L0,15.6 L0,15.6 L0,0 Z"
  //         id="Rectangle"
  //         transform="translate(8.5, 7.8) scale(-1, 1) translate(-8.5, -7.8)"
  //       ></path>
  //       <path
  //         d="M2,0 L24,0 C25.1045695,-2.02906125e-16 26,0.8954305 26,2 L26,8 L26,8 L0,8 L0,2 C-1.3527075e-16,0.8954305 0.8954305,1.3527075e-16 2,0 Z"
  //         id="Rectangle"
  //         transform="translate(13, 4) scale(-1, 1) translate(-13, -4)"
  //       ></path>
  //     </g>
  //   </g>
  // </svg>
  <svg
    height="25"
    viewBox="0 0 24 16"
    width="25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.5 6h-3C.673 6 0 5.327 0 4.5v-3C0 .673.673 0 1.5 0h3C5.327 0 6 .673 6 1.5v3C6 5.327 5.327 6 4.5 6m-3-5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm3 14h-3C.673 15 0 14.327 0 13.5v-3C0 9.673.673 9 1.5 9h3c.827 0 1.5.673 1.5 1.5v3c0 .827-.673 1.5-1.5 1.5m-3-5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm12-4h-3C9.673 6 9 5.327 9 4.5v-3C9 .673 9.673 0 10.5 0h3c.827 0 1.5.673 1.5 1.5v3c0 .827-.673 1.5-1.5 1.5m-3-5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm3 14h-3c-.827 0-1.5-.673-1.5-1.5v-3c0-.827.673-1.5 1.5-1.5h3c.827 0 1.5.673 1.5 1.5v3c0 .827-.673 1.5-1.5 1.5m-3-5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm12-4h-3c-.827 0-1.5-.673-1.5-1.5v-3c0-.827.673-1.5 1.5-1.5h3c.827 0 1.5.673 1.5 1.5v3c0 .827-.673 1.5-1.5 1.5m-3-5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm3 14h-3c-.827 0-1.5-.673-1.5-1.5v-3c0-.827.673-1.5 1.5-1.5h3c.827 0 1.5.673 1.5 1.5v3c0 .827-.673 1.5-1.5 1.5m-3-5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
  </svg>
);
