import React, { useState } from 'react';
import checkconnectivity from '../Register/apis/bucket/checkconnectivity';
import addbucket from '../Register/apis/bucket/addbucket';
import { useNavigate } from 'react-router-dom';
import { servicesManager } from '../../../../app/src/App';

export default function ConnectBucket() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('aws');
  const [accessKey, setAccessKey] = useState('');
  const [secretAccessKey, setSecretAccessKey] = useState('');
  const [bucketName, setBucketName] = useState('');
  const [dcmcAccessKey, setDcmcAccessKey] = useState('');
  const [googleAccessKey, setGoogleAccessKey] = useState('');
  const [googleSecretAccessKey, setGoogleSecretAccessKey] = useState('');
  const [googleBucketName, setGoogleBucketName] = useState('');
  const [googleDcmcAccessKey, setGoogleDcmcAccessKey] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const { uiNotificationService } = servicesManager.services;

  const tabs = [
    {
      id: 'aws',
      label: 'AWS',
    },
    {
      id: 'google',
      label: 'Google',
    },
  ];

  const validateForm = () => {
    const newErrors = {};

    if (!accessKey.trim()) newErrors.accessKey = 'Access Key is missing';
    if (!secretAccessKey.trim()) newErrors.secretAccessKey = 'Secret Access Key is missing';
    if (!bucketName.trim()) newErrors.bucketName = 'Bucket Name is missing';
    if (!dcmcAccessKey.trim()) newErrors.dcmcAccessKey = 'DCMC Access Key is missing';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const validateFormGoogle = () => {
    const newErrors = {};

    if (!googleAccessKey.trim()) newErrors.googleAccessKey = 'Access Key is missing';
    if (!googleSecretAccessKey.trim())
      newErrors.googleSecretAccessKey = 'Secret Access Key is missing';
    if (!googleBucketName.trim()) newErrors.googleBucketName = 'Bucket Name is missing';
    if (!googleDcmcAccessKey.trim()) newErrors.googleDcmcAccessKey = 'DCMC Access Key is missing';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleBucket = () => {
    const values =
      activeTab == 'aws'
        ? {
            bucketType: activeTab,
            accessKeyId: accessKey,
            secretAccessKey: secretAccessKey,
            bucketName: bucketName,
            dcmcAccessKey: dcmcAccessKey,
            Region: 'us-east-1',
          }
        : {
            bucketType: activeTab,
            accessKeyId: googleAccessKey,
            secretAccessKey: googleSecretAccessKey,
            bucketName: googleBucketName,
            dcmcAccessKey: googleDcmcAccessKey,
            Region: 'us-east-1',
          };

    if (activeTab == 'aws' ? validateForm() : validateFormGoogle()) {
      setIsLoading(true);
      // checkconnectivity(values)
      //   .then(response => {
      //     if (!response.error) {
      //       if (response.data == false) {
      const user_data = JSON.parse(localStorage.getItem('user_data') || '{}');
      const bucket_data = { ...values, user: user_data._id };
      addbucket(bucket_data)
        .then(response => {
          if (!response.error) {
            uiNotificationService.show({
              title: 'Bucket Connection',
              message: 'Successfully connected to the bucket.',
              type: 'success',
              duration: 2000,
            });
            setTimeout(() => navigate('/'), 2000);
          } else {
            setErrorResponse(true);
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      //   }
      //   } else {
      //     setErrorResponse(true);
      //   }
      // })
      // .catch(error => {
      //   console.error(error);
      // })
      // .finally(() => {
      //   setIsLoading(false);
      // });
    }
  };

  const awsForm = () => {
    return (
      <div className="from-primary-signcard to-primary-signcarddark relative mt-10 flex w-72 flex-col items-center rounded-xl bg-gradient-to-r shadow-xl sm:w-96 lg:w-[27rem]">
        <span className="mt-6 text-xl text-white">Bucket Configuration</span>
        <div className="border-primary-gray hover:border-primary-signactivedark mx-5 mt-5 flex w-64 border-b-2 p-2 transition duration-300 lg:w-80">
          <input
            placeholder="AWS Access Key ID"
            value={accessKey}
            onChange={e => setAccessKey(e.target.value)}
            className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
          />
        </div>
        {errors.accessKey && <p className="mt-2 text-sm text-red-500">{errors.accessKey}</p>}
        <div className="border-primary-gray hover:border-primary-signactivedark mx-5 mt-5 flex w-64 border-b-2 p-2 transition duration-300 lg:w-80">
          <input
            placeholder="Secret Access Key"
            value={secretAccessKey}
            onChange={e => setSecretAccessKey(e.target.value)}
            className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
          />
        </div>
        {errors.secretAccessKey && (
          <p className="mt-2 text-sm text-red-500">{errors.secretAccessKey}</p>
        )}
        <div className="border-primary-gray hover:border-primary-signactivedark mx-5 mt-5 flex w-64 border-b-2 p-2 transition duration-300 lg:w-80">
          <input
            placeholder="AWS Bucket Name"
            value={bucketName}
            onChange={e => setBucketName(e.target.value)}
            className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
          />
        </div>
        {errors.bucketName && <p className="mt-2 text-sm text-red-500">{errors.bucketName}</p>}
        <div className="border-primary-gray hover:border-primary-signactivedark mx-5 mt-5 flex w-64 border-b-2 p-2 transition duration-300 lg:w-80">
          <input
            placeholder="DCMC Access Key"
            value={dcmcAccessKey}
            onChange={e => setDcmcAccessKey(e.target.value)}
            className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
          />
        </div>
        {errors.dcmcAccessKey && (
          <p className="mt-2 text-sm text-red-500">{errors.dcmcAccessKey}</p>
        )}

        <div
          onClick={() => handleBucket()}
          className="from-primary-signactivedark to-primary-signactivelight mt-7 flex w-28 cursor-pointer items-center justify-center rounded-xl bg-gradient-to-r p-1 text-sm text-white transition duration-300 hover:scale-105"
        >
          Connect Bucket
        </div>
        <div
          onClick={() => navigate('/')}
          className="border-primary-darktable mb-5 mt-3 flex w-28 cursor-pointer items-center justify-center rounded-xl border-[2px] p-[2px] text-sm text-white transition duration-300 hover:scale-105 hover:bg-gradient-to-r"
        >
          Back
        </div>
      </div>
    );
  };

  const googleForm = () => {
    return (
      <div className="from-primary-signcard to-primary-signcarddark relative mt-10 flex w-72 flex-col items-center rounded-xl bg-gradient-to-r shadow-xl sm:w-96 lg:w-[27rem]">
        <span className="mt-6 text-xl text-white">Bucket Configuration</span>
        <div className="border-primary-gray hover:border-primary-signactivedark mx-5 mt-5 flex w-64 border-b-2 p-2 transition duration-300 lg:w-80">
          <input
            placeholder="Google Access Key ID"
            value={googleAccessKey}
            onChange={e => setGoogleAccessKey(e.target.value)}
            className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
          />
        </div>
        {errors.googleAccessKey && (
          <p className="mt-2 text-sm text-red-500">{errors.googleAccessKey}</p>
        )}
        <div className="border-primary-gray hover:border-primary-signactivedark mx-5 mt-5 flex w-64 border-b-2 p-2 transition duration-300 lg:w-80">
          <input
            placeholder="Google Secret Access Key"
            value={googleSecretAccessKey}
            onChange={e => setGoogleSecretAccessKey(e.target.value)}
            className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
          />
        </div>
        {errors.googleSecretAccessKey && (
          <p className="mt-2 text-sm text-red-500">{errors.googleSecretAccessKey}</p>
        )}
        <div className="border-primary-gray hover:border-primary-signactivedark mx-5 mt-5 flex w-64 border-b-2 p-2 transition duration-300 lg:w-80">
          <input
            placeholder="AWS Bucket Name"
            value={googleBucketName}
            onChange={e => setGoogleBucketName(e.target.value)}
            className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
          />
        </div>
        {errors.googleBucketName && (
          <p className="mt-2 text-sm text-red-500">{errors.googleBucketName}</p>
        )}
        <div className="border-primary-gray hover:border-primary-signactivedark mx-5 mt-5 flex w-64 border-b-2 p-2 transition duration-300 lg:w-80">
          <input
            placeholder="DCMC Access Key"
            value={googleDcmcAccessKey}
            onChange={e => setGoogleDcmcAccessKey(e.target.value)}
            className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
          />
        </div>
        {errors.googleDcmcAccessKey && (
          <p className="mt-2 text-sm text-red-500">{errors.googleDcmcAccessKey}</p>
        )}

        <div className="from-primary-signactivedark to-primary-signactivelight mt-7 flex w-28 cursor-pointer items-center justify-center rounded-xl bg-gradient-to-r p-1 text-sm text-white transition duration-300 hover:scale-105">
          Connect Bucket
        </div>
        <div
          onClick={() => navigate('/')}
          className="border-primary-darktable mt-3 mb-5 flex w-28 cursor-pointer items-center justify-center rounded-xl border-[2px] p-[2px] text-sm text-white transition duration-300 hover:scale-105 hover:bg-gradient-to-r"
        >
          Back
        </div>
      </div>
    );
  };

  const renderSwitch = () => {
    switch (activeTab) {
      case 'aws':
        return awsForm();
      case 'google':
        return googleForm();
      default:
        return <div></div>;
    }
  };

  return (
    <div
      className="flex h-screen flex-col items-center justify-center overflow-y-auto"
      style={{
        background:
          'radial-gradient(ellipse, rgba(37,58,86,1) 0%, rgba(18,23,51,1) 50%, rgba(20,20, 33,1) 75%)',
      }}
    >
      <div className="mt-5 flex flex-col items-center justify-center">
        <div className="flex items-center justify-center text-white md:w-[700px] lg:w-[1000px]">
          <span className="text-[18px] font-semibold">Bucket Connect</span>
          {tabs.map(tab => (
            <div
              key={tab.id}
              className={`ml-2 cursor-pointer rounded-3xl px-5 text-sm transition duration-300 hover:scale-105 ${
                activeTab === tab.id
                  ? 'from-primary-signactivedark to-primary-signactivelight bg-gradient-to-r p-1'
                  : 'border-primary-darktable border-2 p-[2px]'
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </div>
          ))}
        </div>
        {renderSwitch()}
      </div>
    </div>
  );
}
