import React from 'react';
import type { IconProps } from '../types';

const Magnifier = (props: IconProps) => (
  <svg
    height="100"
    width="100"
    viewBox="0 0 512 512"
  >
    <circle
      fill="#d6e5f6"
      cx="193.939"
      cy="193.94"
      r="170.667"
    />
    <path
      fill="#a4c6ec"
      d="M279.282 217.22h-.002c-12.853 0-23.273-10.422-23.271-23.274.002-16.576-6.454-32.161-18.178-43.885s-27.307-18.179-43.885-18.178c-12.853.002-23.274-10.418-23.274-23.271s10.418-23.273 23.271-23.274c29.012-.002 56.286 11.295 76.802 31.811 20.514 20.516 31.811 47.79 31.809 76.802-.001 12.851-10.421 23.269-23.272 23.269"
    />
    <path
      fill="#1d3366"
      d="M505.183 472.274 346.497 313.585c25.921-32.976 41.398-74.533 41.398-119.637C387.894 87.005 300.89 0 193.946 0h-.003C142.141 0 93.435 20.176 56.804 56.804 20.174 93.439 0 142.142 0 193.947c0 106.944 87.003 193.947 193.946 193.947 45.103 0 86.658-15.476 119.639-41.394L472.27 505.184A23.2 23.2 0 0 0 488.727 512a23.2 23.2 0 0 0 16.455-6.816c9.091-9.087 9.091-23.823.001-32.91M46.545 193.947c0-39.373 15.332-76.389 43.172-104.229s64.855-43.172 104.228-43.172c81.279 0 147.403 66.124 147.403 147.402 0 81.279-66.124 147.402-147.403 147.402-81.277-.001-147.4-66.123-147.4-147.403"
    />
  </svg>
);

export default Magnifier;
