import React from 'react';
import type { IconProps } from '../types';

export const ThumbnailView = (props: IconProps) => (
  // <svg
  //   width="24px"
  //   height="24px"
  //   viewBox="0 0 24 24"
  //   version="1.1"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     id="icon-thumbnail-view"
  //     stroke="none"
  //     strokeWidth="1"
  //     fill="none"
  //     fillRule="evenodd"
  //   >
  //     <rect
  //       id="Rectangle"
  //       x="0"
  //       y="0"
  //       width="24"
  //       height="24"
  //     ></rect>
  //     <rect
  //       id="Rectangle"
  //       fill="currentColor"
  //       x="6"
  //       y="6"
  //       width="5"
  //       height="5"
  //       rx="1.5"
  //     ></rect>
  //     <rect
  //       id="Rectangle"
  //       fill="currentColor"
  //       x="13"
  //       y="6"
  //       width="5"
  //       height="5"
  //       rx="1.5"
  //     ></rect>
  //     <rect
  //       id="Rectangle"
  //       fill="currentColor"
  //       x="6"
  //       y="13"
  //       width="5"
  //       height="5"
  //       rx="1.5"
  //     ></rect>
  //     <rect
  //       id="Rectangle"
  //       fill="currentColor"
  //       x="13"
  //       y="13"
  //       width="5"
  //       height="5"
  //       rx="1.5"
  //     ></rect>
  //   </g>
  // </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    height="14"
    width="14"
    {...props}
  >
    <path
      fill="#83c9ff"
      d="M187.628 231.335H43.707C19.607 231.335 0 211.728 0 187.628V43.707C0 19.607 19.607 0 43.707 0h143.921c24.1 0 43.707 19.607 43.707 43.707v143.921c0 24.1-19.607 43.707-43.707 43.707"
    />
    <path
      fill="#83c9ff"
      d="M468.293 231.335H324.372c-24.1 0-43.707-19.607-43.707-43.707V43.707c0-24.1 19.607-43.707 43.707-43.707h143.921C492.393 0 512 19.607 512 43.707v143.921c0 24.1-19.607 43.707-43.707 43.707"
    />
    <path
      fill="#83c9ff"
      d="M187.628 512H43.707C19.607 512 0 492.393 0 468.293V324.372c0-24.1 19.607-43.707 43.707-43.707h143.921c24.1 0 43.707 19.607 43.707 43.707v143.921c0 24.1-19.607 43.707-43.707 43.707"
    />
    <path
      fill="#83c9ff"
      d="M468.293 512H324.372c-24.1 0-43.707-19.607-43.707-43.707V324.372c0-24.1 19.607-43.707 43.707-43.707h143.921c24.1 0 43.707 19.607 43.707 43.707v143.921c0 24.1-19.607 43.707-43.707 43.707"
    />
  </svg>
);

export default ThumbnailView;
