import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { DicomMetadataStore, MODULE_TYPES } from '@ohif/core';

import Dropzone from 'react-dropzone';
import filesToStudies from './filesToStudies';

import { extensionManager } from '../../App.tsx';
import { Icon, LoadingIndicatorProgress } from '@ohif/ui';
import axios from 'axios';
import { LocalAPI, LocalAPIUpload } from '../../../../../constants/baseurl';

type LocalProps = {
  modePath: string;
};

function Local({ modePath }: LocalProps) {
  const navigate = useNavigate();
  const dropzoneRef = useRef();
  const [dropInitiated, setDropInitiated] = React.useState(false);
  const [serverFS, setServerFS] = React.useState(false);

  // Initializing the dicom local dataSource
  const dataSourceModules = extensionManager.modules[MODULE_TYPES.DATA_SOURCE];
  const localDataSources = dataSourceModules.reduce((acc, curr) => {
    const mods = [];
    curr.module.forEach(mod => {
      if (mod.type === 'localApi') {
        mods.push(mod);
      }
    });
    return acc.concat(mods);
  }, []);

  const firstLocalDataSource = localDataSources[0];
  const dataSource = firstLocalDataSource.createDataSource({});

  const microscopyExtensionLoaded = extensionManager.registeredExtensionIds.includes(
    '@dcm-cloud/extension-dicom-microscopy'
  );

  const uploadToServerFS = async files => {
    const token = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const formData = new FormData();
    files.forEach(file => formData.append('files', file));

    try {
      await axios.post(`${LocalAPIUpload}/apiupload`, formData);
      setDropInitiated(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onDrop = async acceptedFiles => {
    if (serverFS) {
      uploadToServerFS(acceptedFiles);
    } else {
      const studies = await filesToStudies(acceptedFiles, dataSource);

      const query = new URLSearchParams();

      if (microscopyExtensionLoaded) {
        const smStudies = studies.filter(id => {
          const study = DicomMetadataStore.getStudy(id);
          return (
            study.series.findIndex(s => s.Modality === 'SM' || s.instances[0].Modality === 'SM') >=
            0
          );
        });

        if (smStudies.length > 0) {
          smStudies.forEach(id => query.append('StudyInstanceUIDs', id));

          modePath = 'microscopy';
        }
      }

      studies.forEach(id => query.append('StudyInstanceUIDs', id));
      query.append('datasources', 'dicomlocal');

      // sessionStorage.setItem('isDicomLocal', 'true');
      navigate(`/${modePath}?${decodeURIComponent(query.toString())}`);
    }
  };

  useEffect(() => {
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const is_demo = user_data && user_data.email == 'userdemo@gmail.com';
    const is_share = JSON.parse(localStorage.getItem('share'));
    const share_case =
      user_data &&
      user_data.isShared &&
      is_share &&
      user_data.isShared == 'true' &&
      is_share == true
        ? true
        : false;

    if (share_case == true || is_demo == true) {
      navigate('/sign-in');
    }
  }, []);

  // Set body style
  useEffect(() => {
    document.body.classList.add('bg-black');
    return () => {
      document.body.classList.remove('bg-black');
    };
  }, []);

  return (
    <Dropzone
      ref={dropzoneRef}
      onDrop={acceptedFiles => {
        setDropInitiated(true);
        onDrop(acceptedFiles);
      }}
      noClick
    >
      {({ getRootProps, getInputProps, open }) => (
        <div
          {...getRootProps()}
          style={{ width: '100%', height: '100%' }}
        >
          <div className="flex h-screen w-screen items-center justify-center">
            <div className="from-primary-modalgray to-primary-modalgradient border-primary-darkgray mx-auto flex flex-col items-center justify-center space-y-2 rounded-xl bg-gradient-to-tr py-8 px-8 drop-shadow-md">
              {dropInitiated ? (
                <div className="flex flex-col items-center justify-center pt-48">
                  <LoadingIndicatorProgress
                    className={'h-full w-full bg-black'}
                    isUpload={true}
                  />
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center">
                  <div className="border-primary-gray text-primary-gray flex items-center rounded-3xl border-[1px] px-3 text-sm">
                    Upload Files Locally
                  </div>
                  <div className="text-primary-gray pt-5 text-lg font-bold">
                    Drag and Drop DICOM files here to load them in the Viewer
                  </div>
                  <div className="flex flex-col pt-5 md:flex-row">
                    <div
                      className="border-primary-gray hover:border-primary-darkgray mr-6 h-60 w-60 cursor-pointer rounded-xl border-[1px] transition duration-300"
                      onClick={open}
                    >
                      <div className="flex flex-col items-center justify-center pt-10">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={0.5}
                          stroke="white"
                          className="h-24 w-24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>
                        <span className="text-primary-gray pt-5">Click Or Drop to load files</span>
                      </div>
                      <input {...getInputProps()} />
                    </div>
                    <div
                      className="border-primary-gray hover:border-primary-darkgray h-60 w-60 cursor-pointer rounded-xl border-[1px] transition duration-300"
                      onClick={open}
                    >
                      <div className="flex flex-col items-center justify-center pt-10">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={0.5}
                          stroke="white"
                          className="h-24 w-24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 00-1.883 2.542l.857 6a2.25 2.25 0 002.227 1.932H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-1.883-2.542m-16.5 0V6A2.25 2.25 0 016 3.75h3.879a1.5 1.5 0 011.06.44l2.122 2.12a1.5 1.5 0 001.06.44H18A2.25 2.25 0 0120.25 9v.776"
                          />
                        </svg>
                        <span className="text-primary-gray pt-5">
                          Click Or Drop to load folders
                        </span>
                      </div>
                      <input
                        {...getInputProps()}
                        webkitdirectory="true"
                        mozdirectory="true"
                      />
                    </div>
                  </div>
                  <div className="flex gap-x-3">
                    <div
                      onClick={() => setServerFS(!serverFS)}
                      className={`${serverFS == true ? 'bg-primary-newgray' : ''} border-primary-gray hover:border-primary-darkgray text-primary-gray mt-5 flex cursor-pointer items-center rounded-3xl border-[1px] px-4 py-1 text-sm transition duration-200`}
                    >
                      Upload to Server's Local
                    </div>
                    <div
                      onClick={() => navigate('/')}
                      className="border-primary-gray hover:border-primary-darkgray text-primary-gray mt-5 flex cursor-pointer items-center rounded-3xl border-[1px] px-4 py-1 text-sm transition duration-200"
                    >
                      Back
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
}

export default Local;
