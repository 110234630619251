import React from 'react';
import type { IconProps } from '../types';

export const LaunchInfo = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="22"
    viewBox="0 0 21 22"
    {...props}
  >
    <g fill-rule="evenodd">
      <circle
        cx="10.5"
        cy="11"
        r="10.192"
        fill="#fff"
      />
      <path
        fill="#348cfd"
        d="M10.5.81C4.87.81.308 5.372.308 11.001S4.871 21.193 10.5 21.193s10.192-4.563 10.192-10.192S16.13.809 10.5.809zm0 1a9.192 9.192 0 1 1-9.192 9.192A9.19 9.19 0 0 1 10.5 1.809z"
      />
      <path
        fill="#348cfd"
        d="M9.692 8.885c.677 0 1.234.514 1.301 1.174l.007.133v5.654a.5.5 0 0 1-.992.09l-.008-.09v-5.654a.31.31 0 0 0-.237-.3l-.07-.007H8.076a.5.5 0 0 1-.09-.992l.09-.008zm-.404-4.039.114.007a.904.904 0 1 1-.227 0z"
      />
      <path
        fill="#348cfd"
        d="M12.923 15.348a.5.5 0 0 1 .09.992l-.09.008H8.077a.5.5 0 0 1-.09-.992l.09-.008z"
      />
    </g>
  </svg>
);

export default LaunchInfo;
