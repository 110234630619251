import React, { useState } from 'react';
import Plan from './packages/plan';
import PayGo from './packages/paygo';
import Free from './packages/free';

export default function Package() {
  const [activeTab, setActiveTab] = useState('Plan');

  const tabs = [
    {
      id: 'Plan',
      label: 'Plan',
      cardtitle: 'Tailored DICOM Solutions',
      carddesc:
        'Select the perfect plan that fits your imaging needs. Whether youre a solo practitioner or a growing clinic, our tiered packages offer the flexibility and scalability your workflow demands. Enjoy peace of mind with our free unlimited updates and premium support included with every subscription.',
    },
    {
      id: 'Pay as you go',
      label: 'Pay as you go',
      cardtitle: 'Your DICOM, Your Way',
      carddesc:
        'Dive into a truly flexible DICOM experience. Our Pay-As-You-Go plan adjusts dynamically to your workflow, ensuring you only invest in the resources you need. No guesswork, no waste-just seamless efficiency tailored to your personal or professional demands.',
    },
    {
      id: 'Free',
      label: 'Free',
      cardtitle: 'Access DICOM Viewing Capabilities at No Cost',
      carddesc: 'Experience the essential features of our DICOM Viewer without a subscription fee.',
    },
  ];

  const activeTabDetails = tabs.find(tab => tab.id === activeTab);

  const renderSwitch = () => {
    switch (activeTab) {
      case 'Plan':
        return <Plan />;
      case 'Pay as you go':
        return <PayGo />;
      case 'Free':
        return <Free />;
      default:
        return <div></div>;
    }
  };

  return (
    <div className="mt-3 flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center text-white md:w-[700px] md:flex-row md:items-start md:justify-start lg:w-[1045px]">
        <span className="mb-2 text-[18px] font-semibold md:mb-0">Package Selection</span>
        <div className="flex items-start justify-start">
          {tabs.map(tab => (
            <div
              key={tab.id}
              className={`ml-2 cursor-pointer rounded-3xl px-5 text-sm transition duration-300 first:ml-0 hover:scale-105 md:first:ml-2 ${
                activeTab === tab.id
                  ? 'from-primary-signactivedark to-primary-signactivelight bg-gradient-to-r p-1'
                  : 'border-primary-darktable border-2 p-[2px]'
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>
      <div className="bg-primary-signcard mx-5 mt-5 flex flex-col items-start justify-start rounded-xl bg-opacity-40 p-3 md:mx-0 md:w-[700px] lg:w-[1045px]">
        <span className="text-primary-signhoverlight text-sm font-semibold">
          {activeTabDetails.cardtitle}
        </span>
        <span className="text-sm text-white">{activeTabDetails.carddesc}</span>
      </div>
      {renderSwitch()}
    </div>
  );
}
