import React from 'react';
import type { IconProps } from '../types';

export const Separator = (props: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke-width="0" />
    <g
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 21V3"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Separator;
