import React from 'react';
import type { IconProps } from '../types';

export const CheckBoxUnchecked = (props: IconProps) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 17 15"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x=".5"
      y=".5"
      width="15"
      height="15"
      rx="3"
      stroke="#0097A2"
      fill="none"
      fill-rule="evenodd"
    />
  </svg>
);

export default CheckBoxUnchecked;
