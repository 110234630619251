import axios from 'axios';
import api_constants from '../../constants/urls';

const addbucket = async ({
  bucketType,
  accessKeyId,
  secretAccessKey,
  bucketName,
  dcmcAccessKey,
  user,
}) => {
  const url = api_constants.ADD_BUCKET;
  const params = {
    bucketType: bucketType,
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    bucketName: bucketName,
    dcmcAccessKey: dcmcAccessKey,
    user: user,
  };

  const token = localStorage.getItem('token');

  try {
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/sign-in';
      return;
    }

    return response;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data || error.response.statusText);
    } else if (error.request) {
      throw new Error('No response received from server');
    } else {
      throw new Error(error.message);
    }
  }
};

export default addbucket;
