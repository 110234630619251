import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { select } from '../../../../app/src/redux/features/select/select.js';
import { MyContext } from '../../../../app/src/contexts/context.tsx';
import StudyListTableRow from './StudyListTableRow';
import ShareModal from '../ShareModal/ShareModal';
import ViewPDF from '../ViewPDF/ViewPDF';
import CheckBox from '../CheckBox';

const StudyListTable = ({
  tableDataSource,
  querying,
  checked,
  handleChecked,
  selectAll,
  selectAllChange,
}) => {
  const [isModal, setIsModal] = useState(false);
  const [isPDF, setIsPDF] = useState(false);
  const [isIndex, setIsIndex] = useState(0);
  const [isItChecked, SetIsItChecked] = useState(false);
  const parentRef = useRef(null);
  const [isScroll, setIsScroll] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const timeoutRef = useRef(null);
  const { selectContext, handleSelectChange } = useContext(MyContext);
  const isSelected = useSelector(state => state.selector.value);
  const isLocal = useSelector(state => state.list.isLocal);
  const dispatch = useDispatch();
  const length = tableDataSource.length;

  const onClickShareHandler = () => {
    setIsModal(!isModal);
  };

  const onOpen = index => {
    setIsIndex(index);
  };

  const onClickPDFHandler = () => {
    setIsPDF(!isPDF);
  };

  //TODO: REMOVE REDUX AND CONTEXTAPI FROM HERE LATER
  const handleOnClickChange = () => {
    handleSelectChange();
    dispatch(select());
    for (let i = 0; i < checked.length; i++) {
      handleChecked(!selectAll, i);
    }
    handleChange();
  };

  const handleChange = () => {
    selectAllChange();
  };

  const handleScroll = () => {
    setIsScroll(true);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setIsScroll(false);
    }, 200);
  };

  useEffect(() => {
    const scrollContainer = parentRef.current;
    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const is_demo = user_data && user_data.email == 'userdemo@gmail.com';
    const is_share = JSON.parse(localStorage.getItem('share'));
    const share_case =
      user_data &&
      user_data.isShared &&
      is_share &&
      user_data.isShared == 'true' &&
      is_share == true
        ? true
        : false;

    setIsShare(share_case);
    setIsDemo(is_demo);

    // if (share_case == false) {
    //   const share_service = user_data?.sharingwithusers || false;
    //   setIsShare(!share_service);
    //   if (user_data && user_data.admin && user_data.admin == 'true') {
    //     setIsShare(false);
    //   }
    // }
  }, []);

  return (
    <div className="dark:bg-primary-modalgray bg-white">
      {isModal && (
        <div>
          <div
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              width: '100%',
              height: '100%',
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 21,
            }}
          ></div>
          <ShareModal
            onClose={onClickShareHandler}
            tableData={tableDataSource}
            index={isIndex}
          />
        </div>
      )}
      {isPDF && (
        <ViewPDF
          onClose={onClickPDFHandler}
          tableData={tableDataSource}
          index={isIndex}
        />
      )}
      <div className="relative mx-1 sm:mx-4 md:mx-6">
        <div
          ref={parentRef}
          onScroll={() => setIsScroll(true)}
          className={`max-h-[75vh] ${tableDataSource.length > 10 ? 'overflow-y-auto' : ''} rounded-l-lg shadow-sm dark:rounded-lg`}
        >
          <table className="w-full rounded-xl text-black">
            <thead
              className="bg-primary-lightblue dark:bg-primary-darkTable dark:border-primary-newgray sticky top-0 border-l-2 text-left dark:text-white"
              style={{ zIndex: 9 }}
            >
              <tr>
                <th className="dark:border-primary-newgray border-primary-lightgray flex w-16 border-2 border-r-0 border-l-0 border-b-0 border-t-0 py-2 pl-4 text-lg">
                  <CheckBox
                    checked={isSelected}
                    onChange={handleOnClickChange}
                  />
                  <span className="ml-2 mt-[2px] mr-1 hidden md:block">Select</span>
                </th>
                <th className="dark:border-primary-newgray border-primary-lightgray w-20 border-2 border-t-0 py-2 pl-2 text-lg">
                  Sr No.
                </th>
                <th className="dark:border-primary-newgray border-primary-lightgray border-2 border-t-0 py-2 pl-2 text-lg">
                  Patient Name
                </th>
                <th className="dark:border-primary-newgray border-primary-lightgray border-2 border-t-0 py-2 pl-2 text-lg">
                  Study Date
                </th>
                <th className="dark:border-primary-newgray border-primary-lightgray border-2 border-t-0 py-2 pl-2 text-lg">
                  Modality
                </th>
                <th className="dark:border-primary-newgray border-primary-lightgray border-2 border-t-0 py-2 pl-2 text-lg">
                  Accession
                </th>
                {isLocal && (
                  <th className="dark:border-primary-newgray border-primary-lightgray border-2 border-t-0 py-2 pl-2 text-lg">
                    Series
                  </th>
                )}
                {isLocal && (
                  <th className="dark:border-primary-newgray border-primary-lightgray border-2 border-t-0 py-2 pl-2 text-lg">
                    Instances
                  </th>
                )}
                <th className="dark:border-primary-newgray border-primary-lightgray w-20 border-2 border-t-0 py-2 pl-2 text-lg">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody
              data-cy="study-list-results"
              data-querying={querying}
            >
              {tableDataSource.map((tableData, i) => (
                <StudyListTableRow
                  key={i}
                  tableData={tableData}
                  uniqueKey={i}
                  shareModal={onClickShareHandler}
                  onOpen={onOpen}
                  pdfModal={onClickPDFHandler}
                  checked={checked}
                  handleChecked={handleChecked}
                  selectAll={selectAll}
                  parentRef={parentRef}
                  isScroll={isScroll}
                  length={length}
                  isShare={isShare}
                  isDemo={isDemo}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

StudyListTable.propTypes = {
  tableDataSource: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.array.isRequired,
      expandedContent: PropTypes.node.isRequired,
      querying: PropTypes.bool,
      onClickRow: PropTypes.func.isRequired,
      isExpanded: PropTypes.bool.isRequired,
    })
  ).isRequired,
  querying: PropTypes.bool.isRequired,
  checked: PropTypes.arrayOf(
    PropTypes.shape({
      index_value: PropTypes.number.isRequired,
      index_state: PropTypes.bool.isRequired,
    })
  ).isRequired,
  handleChecked: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
};

export default StudyListTable;
