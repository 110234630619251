import React from 'react';
import type { IconProps } from '../types';

export const MainLoader = (props: IconProps) => (
  <svg viewBox="290 0 1000 1024">
    <g opacity=".7">
      <path
        fill="transparent"
        d="M787.6 7.5c-273.8 0-495.8 226-495.8 504.8s222 504.7 495.8 504.7h.1V7.5z"
      />
      <path
        fill="#BFE6F6"
        d="M787.5 7.5c273.8 0 495.8 226 495.8 504.8s-222 504.7-495.8 504.7z"
      />
    </g>
  </svg>
);

export default MainLoader;
