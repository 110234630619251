import React, { useState } from 'react';
import PropTypes from 'prop-types';
import deIdentification from './assets/icons/de-identification.png';
import blockChain from './assets/icons/block-chain.png';
import clientApp from './assets/icons/client-app.png';
import sharing from './assets/icons/sharing.png';
import freeAccount from './assets/icons/free-account.png';
import machineLearning from './assets/icons/machine-learning.png';
import imageRender from './assets/icons/image-render.png';
import imageArchive from './assets/icons/image-archive.png';
import imageConverter from './assets/icons/image-converter.png';

const cards1 = [
  {
    id: 'de-identification',
    icon: deIdentification,
    title: 'De-Identification',
    description:
      'Safeguard your patients privacy with our de-identification services, ensuring that personal data remains confidential.',
    price: '$10',
    classes: '',
  },
  {
    id: 'image-render',
    icon: imageRender,
    title: '3D Image Rendering',
    description:
      'Transform the way you view medical images with our 3D rendering - clarity in every dimension.',
    price: '$40',
    classes: '',
  },
  {
    id: 'image-archive',
    icon: imageArchive,
    title: 'Image Archive',
    description:
      'Preserve critical medical images securely and efficiently with our comprehensive archiving services.',
    price: '$10',
    classes: '',
  },
  {
    id: 'sharing',
    icon: sharing,
    title: 'Sharing with Users',
    description:
      'Empower patients with access to their medical records, securely sharing study reports with a password-protected system.',
    price: '$15',
    classes: '',
  },
  {
    id: 'block-chain',
    icon: blockChain,
    title: 'Block Chain',
    description:
      'Revolutionize data integrity with our blockchain technology, ensuring transparent and secure handling of medical records.',
    price: '$35',
    classes: '',
  },
];

const cards2 = [
  {
    id: 'client',
    icon: clientApp,
    title: 'Client App Templates',
    description:
      'Customize your experience with our versatile DCM client app templates - tailored to fit your needs.',
    price: '$45',
    classes: 'ml-1 h-9',
  },
  {
    id: 'converter',
    icon: imageConverter,
    title: 'Image Converter',
    description:
      'Flexibility at your fingertips - effortlessly convert DICOM images to various formats, such as .jpg or .png, and vice versa.',
    price: '$8',
    classes: '',
  },
  {
    id: 'machine',
    icon: machineLearning,
    title: 'Machine Learning',
    description:
      'Harness the power of AI to enhance diagnostic accuracy and efficiency with our machine learning solutions.',
    price: '$6',
    classes: '',
  },
  {
    id: 'free',
    icon: freeAccount,
    title: 'Free Account',
    description:
      'Experience our platform with no commitment - access essential features with a free account.',
    price: '$0',
    classes: '',
  },
];

export default function Services({ selectedCards, setSelectedCards, handleCompletedSteps }) {
  const toggleSelect = id => {
    if (selectedCards.includes(id)) {
      setSelectedCards(selectedCards.filter(cardId => cardId !== id));
    } else {
      setSelectedCards([...selectedCards, id]);
    }
  };

  const getCardClass = id => {
    return selectedCards.includes(id)
      ? 'to-primary-signactivelight from-primary-signactivedark'
      : 'from-primary-signcard to-primary-signcarddark';
  };

  const getCircleClass = id => {
    return selectedCards.includes(id) ? 'border-white' : 'border-primary-darktable';
  };

  const renderCard = card => (
    <div
      key={card.id}
      onClick={() => toggleSelect(card.id)}
      className={`${getCardClass(card.id)} ml-[0.5rem] mb-10 flex h-[20rem] w-64 cursor-pointer flex-col items-center justify-center rounded-xl bg-gradient-to-b px-3 text-white shadow-lg shadow-gray-900 transition duration-300 hover:scale-105 md:mb-0 md:h-[15rem] md:w-48 md:first:ml-0 lg:ml-5`}
    >
      <div className="flex h-20 items-center justify-center">
        <div
          className={`${getCircleClass(card.id)} border-r-primary-signhoverdark mb-3 mt-2 flex h-16 w-16 -rotate-45 items-center justify-center rounded-full border-2 p-3 transition-all duration-300 ease-in-out md:mt-5`}
        >
          <img
            className={`${card.classes} rotate-45`}
            src={card.icon}
          />
        </div>
      </div>
      <span>{card.title}</span>
      <span className="mt-2 text-center text-sm md:text-[10px]">{card.description}</span>
      <span className="mt-5 text-center text-xl">{card.price}</span>
    </div>
  );

  return (
    <div className="mt-2 flex flex-col items-center justify-center">
      <div className="flex items-start justify-start md:w-[750px] lg:w-[1045px]">
        <span className="text-xl font-semibold text-white md:text-[18px]">Services</span>
      </div>
      <div className="mt-5 flex scale-100 flex-col md:mt-0 md:scale-75 md:flex-row lg:mt-5 lg:scale-100">
        {cards1.map(renderCard)}
      </div>
      <div className="mt-5 flex scale-100 flex-col md:scale-75 md:flex-row lg:scale-100">
        {cards2.map(renderCard)}
      </div>
    </div>
  );
}

Services.propTypes = {
  selectedCards: PropTypes.array.isRequired,
  setSelectedCards: PropTypes.func.isRequired,
};
