import { createSlice } from '@reduxjs/toolkit';

export const selectorSlice = createSlice({
  name: 'selector',
  initialState: {
    value: false,
  },
  reducers: {
    select: state => {
      state.value = !state.value;
    },
  },
});

export const { select } = selectorSlice.actions;

export default selectorSlice.reducer;
