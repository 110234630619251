import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { packages } from '../../../../../../platform/app/src/redux/features/register/packagelist';

export default function Card({ cards, tab }) {
  const dispatch = useDispatch();
  const isPackages = useSelector(state => state.packages.value);

  const handleOnClick = index => {
    if (isPackages.id == cards[index].id) {
      dispatch(
        packages({
          id: '',
          title: '',
          subtitle: '',
          price: '',
          type: '',
        })
      );
    } else {
      dispatch(
        packages({
          id: cards[index].id,
          title: cards[index].title,
          subtitle: cards[index].subtitle,
          price: cards[index].price,
          type: cards[index].type,
        })
      );
    }
  };

  return (
    <div
      className={`flex w-full items-center justify-center ${tab == 'plan' ? 'flex-col md:flex-row' : 'flex-col sm:flex-row'}`}
    >
      {cards.map((card, index) => (
        <div
          onClick={() => handleOnClick(index)}
          className={` ${isPackages.id == card.id ? 'from-primary-signactivedark to-primary-signactivelight' : 'from-primary-signcard to-primary-signcarddark'} relative ${tab == 'plan' ? 'mb-10 w-72 sm:w-96 md:ml-5 md:mb-0' : 'mb-10 w-72 sm:ml-5 sm:mb-0 sm:w-64'} flex h-96 cursor-pointer flex-col items-center rounded-xl bg-gradient-to-r shadow-xl transition duration-300 first:ml-0 hover:scale-105 md:w-64`}
        >
          <span className="mt-5 w-48 text-center text-xl font-semibold">{card.title}</span>
          <span className="mt-1 mb-3 font-semibold">{card.subtitle}</span>
          {card.features.map(feature => (
            <div className="my-1 flex">
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="#1DADE4"
                strokeWidth="3"
                viewBox="0 0 24 24"
              >
                <path d="M5 13l4 4L19 7" />
              </svg>
              <span className="ml-3 w-48 text-sm">{feature}</span>
            </div>
          ))}
          <div className="absolute bottom-0 flex w-full flex-col items-center justify-center">
            <div
              className={`flex ${card.subprice != '' ? 'h-12' : 'h-8'} w-[70%] flex-col items-center justify-center rounded-t-xl bg-gradient-to-b from-gray-600 to-black`}
            >
              <span>{card.price}</span>
              {card.subprice != '' && <span className="-mt-1 text-base">{card.subprice}</span>}
            </div>
            <div
              className={` ${isPackages.id == card.id ? 'from-primary-signcard to-primary-signcarddark' : 'from-primary-signactivedark to-primary-signactivelight'} flex h-10 w-full items-center justify-center rounded-b-xl bg-gradient-to-r font-semibold text-white`}
            >
              {isPackages.id == card.id ? 'Selected' : 'Select Package'}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
