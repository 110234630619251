import React, { useState } from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { packages } from '../../../../../platform/app/src/redux/features/register/packagelist';

function extractAndSumPrices(isPackages, toggledServices) {
  const extractPrice = priceString => {
    return parseFloat(priceString.replace(/[^0-9.]/g, ''));
  };

  const isPackagesPrice = extractPrice(isPackages.price || '0');
  const toggledServicesPrice = toggledServices.reduce((total, service) => {
    return total + extractPrice(service.price || '0');
  }, 0);

  const totalPrice = isPackagesPrice + toggledServicesPrice;
  return isNaN(totalPrice) ? 0 : totalPrice;
}

export default function Checkout({ selectedServices, setSelectedCards, setChecked }) {
  const [toggledServices, setToggledServices] = useState([]);
  const isPackages = useSelector(state => state.packages.value);

  const servicesTotal = [
    {
      id: 'de-identification',
      title: 'De-Identification',
      subtitle: 'Hide Your Patients Sensitive Information',
      price: '$10',
    },
    {
      id: 'image-render',
      title: '3D Image Rendering',
      subtitle: 'View Dicom Images in 3D View',
      price: '$40',
    },
    {
      id: 'image-archive',
      title: 'Image Archive',
      subtitle: 'Store Your Dicom Images Securely',
      price: '$10',
    },
    {
      id: 'sharing',
      title: 'Share Studies',
      subtitle: 'Share Studies with Custom Password',
      price: '$15',
    },
    {
      id: 'block-chain',
      title: 'Block Chain',
      subtitle: 'Ensure Data Integrity and Security',
      price: '$35',
    },
    {
      id: 'client',
      title: 'Client App Templates',
      subtitle: 'Select DCM Client App of your own choice',
      price: '$45',
    },
    {
      id: 'converter',
      title: 'Image Converter',
      subtitle: 'Convert Dicom to Jpg/Gif/Png or vice versa',
      price: '$8',
    },
    {
      id: 'machine',
      title: 'Machine Learning',
      subtitle: 'Enhance Image Analysis with AI',
      price: '$6',
    },
    {
      id: 'free',
      title: 'Free Account',
      subtitle: 'Get Started with Basic Features',
      price: '$0',
    },
  ];

  const deleteSelect = id => {
    setSelectedCards(selectedServices.filter(cardId => cardId !== id));
  };

  const toggleService = id => {
    setToggledServices(prev =>
      prev.includes(id) ? prev.filter(serviceId => serviceId !== id) : [...prev, id]
    );
  };

  const services = servicesTotal.filter(service => selectedServices.includes(service.id));
  const finalServices = services.filter(service => !toggledServices.includes(service.id));

  return (
    <div className="mt-3 flex flex-col items-center justify-center">
      <div className="flex items-start justify-start text-white md:w-[700px] lg:w-[1045px]">
        <span className="text-[18px] font-semibold">Checkout</span>
        {isPackages.id == '' ? (
          <div className="from-primary-signactivedark to-primary-signactivelight mt-1 ml-3 rounded-xl bg-gradient-to-r p-[2px] px-8 text-sm">
            No Plan Selected
          </div>
        ) : (
          <div className="from-primary-signactivedark to-primary-signactivelight mt-1 ml-3 rounded-xl bg-gradient-to-r p-[2px] px-8 text-sm">
            {isPackages.title} {isPackages.subtitle == '' ? '' : ' - '} {isPackages.subtitle}
          </div>
        )}
      </div>
      <div className="mt-5 flex flex-col text-white md:flex-row">
        <div className="from-primary-signcard to-primary-signcarddark relative mb-10 ml-5 flex w-[310px] flex-col items-center rounded-xl bg-gradient-to-r shadow-xl transition duration-300 first:ml-0 sm:w-[470px] md:mb-0 lg:w-[720px]">
          <div className="to-primary-signactivedark from-primary-signactivelight mb-5 flex h-14 w-full items-center justify-center rounded-xl bg-gradient-to-r font-semibold">
            Order Review
          </div>
          {isPackages.id == '' ? (
            <div className="ml-7 flex w-full flex-col items-start justify-start lg:ml-10">
              <span>No Plan Selected</span>
            </div>
          ) : (
            <div className="ml-7 flex w-full flex-col items-start justify-start lg:ml-10">
              <span>Price Plan:</span>
              <div className={`${isPackages.price == 'Free' ? 'hidden' : ''}`}>
                Plan - {isPackages.type} - {isPackages.title} - {isPackages.subtitle}
              </div>
              <div className="from-primary-signactivedark to-primary-signactivelight mt-1 rounded-xl bg-gradient-to-r p-[2px] px-2 text-[16px]">
                {isPackages.price}
              </div>
            </div>
          )}

          <div className="ml-7 mt-5 flex w-full flex-col items-start justify-start lg:ml-10">
            <span>{services.length == 0 ? 'No Services Selected' : 'Services:'}</span>
          </div>
          <div className="mt-5 flex h-56 w-[95%] flex-col items-start justify-start overflow-y-auto">
            {services.map((service, index) => (
              <div
                key={index}
                className="bg-primary-signrow mb-3 flex h-12 w-[285px] justify-between rounded-xl sm:w-[430px] lg:w-[670px]"
              >
                <div className="ml-5 flex w-96 flex-col items-start justify-center text-sm lg:text-lg">
                  <span>{service.title}</span>
                  <span className="hidden sm:flex">{service.subtitle}</span>
                </div>
                <div className="flex items-center justify-center">
                  <div className="flex w-14 items-center justify-center">
                    <div
                      onClick={() => toggleService(service.id)}
                      className={`flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border-[2px] ${
                        toggledServices.includes(service.id)
                          ? 'border-gray-500'
                          : 'border-primary-signactivelight'
                      }`}
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        stroke={toggledServices.includes(service.id) ? '#a0aec0 ' : '#1DADE4'}
                        strokeWidth="3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                  </div>
                  <div className="flex w-14 items-center justify-center">
                    <div className="from-primary-signactivedark to-primary-signactivelight mt-1 cursor-pointer rounded-xl bg-gradient-to-r p-[2px] px-2 text-sm">
                      {service.price}
                    </div>
                  </div>
                  <div
                    className="flex w-14 cursor-pointer items-center justify-center"
                    onClick={() => deleteSelect(service.id)}
                  >
                    <DeleteOutlinedIcon />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="from-primary-signcard to-primary-signcarddark relative flex h-80 flex-col items-center rounded-xl bg-gradient-to-r shadow-xl transition duration-300 first:ml-0 md:ml-5 md:w-52 lg:ml-10 lg:h-72 lg:w-72">
          <div className="to-primary-signactivedark from-primary-signactivelight mb-5 flex h-14 w-full items-center justify-center rounded-xl bg-gradient-to-r font-semibold">
            Order Summary
          </div>
          <div className="flex w-full flex-col items-center justify-center">
            <div className="flex w-64 items-center justify-start border-b-[2px] border-gray-700 pb-2 md:w-48 lg:w-64">
              {selectedServices.length} Services Selected
            </div>
            <div className="mt-2 flex w-64 items-center justify-start border-b-[2px] border-gray-700 pb-2 md:w-48 lg:w-64">
              {isPackages.title} {isPackages.subtitle == '' ? '' : '-'} {isPackages.subtitle}
            </div>
            <div className="mt-2 flex w-64 items-center justify-between md:w-48 lg:w-64">
              <span>Subtotal (USD)</span>
              <span>${extractAndSumPrices(isPackages, finalServices).toFixed(2)}</span>
            </div>
            <div
              onClick={() => setChecked(true)}
              className="from-primary-signactivedark to-primary-signactivelight mt-5 flex w-28 cursor-pointer items-center justify-center rounded-xl bg-gradient-to-r p-1 text-sm transition duration-300 hover:scale-105"
            >
              Checkout
            </div>
            <div className="from-primary-signactivedark to-primary-signactivelight mt-3 flex w-28 cursor-pointer items-center justify-center rounded-xl bg-gradient-to-r p-1 text-sm transition duration-300 hover:scale-105">
              Back
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Checkout.propTypes = {
  packagee: PropTypes.string.isRequired,
  selectedServices: PropTypes.array.isRequired,
  setSelectedCards: PropTypes.func.isRequired,
};
