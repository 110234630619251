import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useLocalStorage } from '@uidotdev/usehooks';

const stickyClasses = 'sticky top-0';
const notStickyClasses = 'relative';

const NavBar = ({
  className,
  children,
  isSticky,
}: {
  className?: string;
  children?: React.ReactNode;
  isSticky?: boolean;
}) => {
  const [isDark, setIsDark] = useState(() => {
    const savedMode = localStorage.getItem('dark-mode');
    return savedMode === 'false' ? false : true;
  });
  const [newDark, setNewDark] = useLocalStorage('dark-mode', isDark);

  return (
    <div className={`${newDark ? 'dark' : ''}`}>
      <div
        className={classnames(
          'dark:bg-primary-modalgray z-20 border-black bg-white px-1',
          isSticky && stickyClasses,
          !isSticky && notStickyClasses,
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isSticky: PropTypes.bool,
};

export default NavBar;
