import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { getSpecificBucket } from '../../../../../utils/bucket';
import { setFolders } from '../../../../../platform/app/src/redux/features/studylist/userlist';
import { useDispatch } from 'react-redux';
import { servicesManager } from '../../../../../platform/app/src/App';
import { KeyImagesAPI } from '../../../../../constants/baseurl';

const CreateFolderModal = ({ isOpen, onClose }) => {
  const [folderName, setFolderName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { uiNotificationService } = servicesManager.services;

  const handleSubmit = async e => {
    e.preventDefault();
    if (folderName.trim() === '') {
      setErrorMessage('Folder name cannot be empty.');
      return;
    }

    const token = localStorage.getItem('token');
    const bucketId = localStorage.getItem('bucketId');
    const bucket = await getSpecificBucket(bucketId);

    const apiUrl = `${KeyImagesAPI}/keyImages/v1/updateFolder/v4/createFolder`;
    // const apiUrl = `http://localhost:8001/keyImages/v1/updateFolder/v4/createFolder`;
    const body = {
      AwsAccessKey: bucket.accessKeyId,
      AwsSecretAccessKey: bucket.secretAccessKey,
      AwsBucket: bucket.bucketName,
      folderName: folderName,
    };

    try {
      const response = await axios.post(apiUrl, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data) {
        const response_list = await axios.post(
          `${KeyImagesAPI}/keyImages/v1/updateFolder/v4/getFolderList`,
          // `http://localhost:8001/keyImages/v1/updateFolder/v4/getFolderList`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response_list && response_list.data) {
          dispatch(setFolders(response_list.data.data));

          uiNotificationService.show({
            title: 'Folders',
            message: 'New Folder Created Successfully',
            type: 'success',
            duration: 3000,
          });
        }
        setFolderName('');
        setErrorMessage('');
        onClose(false);
      } else {
        console.error('Failed to create folder');
      }
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  // Close modal when clicking outside of the modal content
  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={modalRef}
        className="dark:bg-primary-modalgray w-full max-w-md rounded-2xl bg-white p-6 shadow-lg"
      >
        <form
          onSubmit={handleSubmit}
          className="space-y-4"
        >
          <div>
            <label
              htmlFor="folderName"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300"
            >
              Folder Name:
            </label>
            <input
              type="text"
              id="folderName"
              value={folderName}
              onChange={e => {
                setFolderName(e.target.value);
                setErrorMessage('');
              }}
              required
              className="dark:border-primary-newgray dark:bg-primary-modalgray border-primary-gray mt-1 w-full rounded-md border p-2 text-gray-900 dark:text-white"
            />
            {errorMessage && <p className="mt-2 text-sm text-red-500">{errorMessage}</p>}
          </div>
          <div className="flex justify-end">
            <div className="flex gap-x-3">
              <button
                type="button"
                onClick={() => onClose(false)}
                className="bg-primary-gray dark:bg-primary-newgray rounded-lg px-4 py-2 text-sm text-black focus:outline-none focus:ring-2 focus:ring-opacity-50 dark:text-white"
              >
                Close
              </button>
              <button
                type="submit"
                className="rounded-lg bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Create Folder
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateFolderModal;
