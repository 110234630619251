import React from 'react';
import type { IconProps } from '../types';

export const TabLinear = (props: IconProps) => (
  // <svg
  //   width="22"
  //   height="22"
  //   viewBox="0 0 22 22"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     fill="none"
  //     fillRule="evenodd"
  //   >
  //     <rect
  //       stroke="currentColor"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       x="1.5"
  //       y="16.37"
  //       width="4.13"
  //       height="4.13"
  //       rx="1"
  //     />
  //     <rect
  //       stroke="currentColor"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       x="16.37"
  //       y="1.5"
  //       width="4.13"
  //       height="4.13"
  //       rx="1"
  //     />
  //     <path
  //       stroke="currentColor"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       d="M5.388 16.612 16.612 5.388"
  //     />
  //     <path d="M0 0h22v22H0z" />
  //   </g>
  // </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="-6.2 -5.2 44.4 44.4"
    fill="currentColor"
    stroke="currentColor"
    stroke-width=".768"
    {...props}
  >
    <g stroke-width="0" />
    <g
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M25.492 1.171c-.609-.61-1.674-.61-2.283 0l-3.557 3.557L4.269 20.111a.5.5 0 0 0-.118.186L.331 31.03a.5.5 0 0 0 .639.639l10.733-3.82.011-.008a.5.5 0 0 0 .175-.111L27.05 12.57l.044-.044.178-.178 3.557-3.557c.305-.305.473-.71.473-1.142s-.168-.837-.473-1.142zM11.447 26.759c-3.785-1.995-5.525-4.771-6.099-5.899-.056-.11-.099-.2-.136-.279L20.005 5.788l6.207 6.207zm-6.751-5.01c.746 1.31 2.43 3.676 5.613 5.535l-8.683 3.09zM30.122 8.084l-3.203 3.204-6.206-6.206 3.204-3.203a.63.63 0 0 1 .869 0l5.337 5.337a.61.61 0 0 1-.001.868z" />
    <path d="M20.653 8.504a.5.5 0 0 0-.707 0l-12.22 12.22a.5.5 0 0 0 .708.707l12.22-12.22a.5.5 0 0 0-.001-.707zM23.7 11.15a.5.5 0 0 0-.707 0L10.576 23.567a.5.5 0 0 0 .708.707L23.7 11.857a.5.5 0 0 0 0-.707zM7.607.447a1.527 1.527 0 0 0-2.16 0l-5 5a1.53 1.53 0 0 0 0 2.16l6.66 6.66a.5.5 0 0 0 .708 0 .5.5 0 0 0 0-.707L6.11 11.855 9.285 8.68a.5.5 0 0 0-.707-.707l-3.175 3.175-1.717-1.716 1.757-1.758a.5.5 0 0 0-.707-.707L2.979 8.725 1.154 6.9a.53.53 0 0 1 0-.746l5-5a.53.53 0 0 1 .746 0l6.661 6.661a.5.5 0 0 0 .707-.707zm17.286 17.286a.5.5 0 0 0-.707.707l6.66 6.66a.53.53 0 0 1 0 .746l-5 5a.53.53 0 0 1-.746 0l-1.637-1.637 3.175-3.175a.5.5 0 0 0-.707-.707l-3.175 3.175-1.716-1.716 1.758-1.758a.5.5 0 0 0-.707-.707l-1.758 1.758-1.893-1.893a.5.5 0 0 0-.707.707l6.66 6.66c.298.298.689.447 1.08.447s.782-.149 1.08-.447l5-5a1.53 1.53 0 0 0 0-2.16z" />
  </svg>
);

export default TabLinear;
