import React from 'react';
import type { IconProps } from '../types';

export const ColorLUT = (props: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      fill-rule="evenodd"
      stroke-width=".909"
    >
      <path
        fill="#59a869"
        d="M0 0h5v5H0z"
        transform="translate(2 2)"
      />
      <path
        fill="#eda200"
        d="M7 0h5v5H7z"
        transform="translate(2 2)"
      />
      <path
        fill="#389fd6"
        d="M0 7h5v5H0z"
        transform="translate(2 2)"
      />
      <path
        fill="#db5860"
        d="M7 7h5v5H7z"
        transform="translate(2 2)"
      />
    </g>
  </svg>
);

export default ColorLUT;
