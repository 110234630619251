import axios from 'axios';
import api_constants from '../../constants/urls';

const signup = async (
  email,
  firstName,
  lastName,
  password,
  phone,
  storageLocation,
  storageService
) => {
  const url = api_constants.SIGN_UP;
  const params = {
    email: email,
    firstName: firstName,
    lastName: lastName,
    password: password,
    phone: phone,
    storageLocation: storageLocation,
    storageService: storageService,
  };

  try {
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/sign-in';
      return;
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data || error.response.statusText);
    } else if (error.request) {
      throw new Error('No response received from server');
    } else {
      throw new Error(error.message);
    }
  }
};

export default signup;
