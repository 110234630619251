import React, { useState, useEffect } from 'react';
import { Icons } from '@ohif/ui-next';
import { useSelector, useDispatch } from 'react-redux';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ShareIcon from '@mui/icons-material/Share';
import {
  setFolders,
  setFolderStructure,
  setSelectedFolder,
} from '../../../../../platform/app/src/redux/features/studylist/userlist';
import { getSpecificBucket } from '../../../../../utils/bucket';
import axios from 'axios';
import { servicesManager } from '../../../../../platform/app/src/App';
import ShareModal from '../ShareModal/ShareModal';
import { KeyImagesAPI } from '../../../../../constants/baseurl';

const FolderList = () => {
  const folders = useSelector(state => state.user.folders);
  const dispatch = useDispatch();
  const { uiNotificationService } = servicesManager.services;
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [selectedShareFolder, setSelectedShareFolder] = useState('');

  useEffect(() => {
    try {
      const user_data = JSON.parse(localStorage.getItem('user_data'));
      const admin = user_data?.admin && user_data?.admin == 'true' ? true : false;
      setIsAdmin(admin);
    } catch (error) {
      setIsAdmin(false);
    }
  }, []);

  const deleteFolder = async (e, folderName) => {
    e.stopPropagation();
    const token = localStorage.getItem('token');
    const bucketId = localStorage.getItem('bucketId');
    const bucket = await getSpecificBucket(bucketId);

    const apiUrl = `${KeyImagesAPI}/keyImages/v1/updateFolder/v4/deleteFolder`;
    // const apiUrl = `http://localhost:8001/keyImages/v1/updateFolder/v4/deleteFolder`;
    const body = {
      AwsAccessKey: bucket.accessKeyId,
      AwsSecretAccessKey: bucket.secretAccessKey,
      AwsBucket: bucket.bucketName,
      folderName: folderName,
    };

    try {
      const response = await axios.post(apiUrl, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data) {
        const response_list = await axios.post(
          `${KeyImagesAPI}/keyImages/v1/updateFolder/v4/getFolderList`,
          // `http://localhost:8001/keyImages/v1/updateFolder/v4/getFolderList`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response_list && response_list.data) {
          dispatch(setFolders(response_list.data.data));

          uiNotificationService.show({
            title: 'Folders',
            message: 'Folder Deleted Successfully',
            type: 'success',
            duration: 3000,
          });
        }
      } else {
        console.error('Failed to create folder');
      }
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  const openFolder = async folderName => {
    dispatch(setSelectedFolder(folderName));
    dispatch(setFolderStructure(false));
  };

  const handleFolder = (e, folderName) => {
    e.stopPropagation();
    setSelectedShareFolder(folderName);
    setIsModal(true);
  };

  const onClickShareHandler = () => {
    setIsModal(false);
  };

  return (
    <div className="h-full w-full overflow-y-auto p-4">
      {isModal && (
        <div>
          <div
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              width: '100%',
              height: '100%',
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 21,
            }}
          ></div>
          <ShareModal
            onClose={onClickShareHandler}
            tableData={[{ StudyInstanceUIDs: 'undefined' }]}
            index={0}
            shareFolder={selectedShareFolder}
          />
        </div>
      )}
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7">
        {folders.map(item => {
          return (
            <div
              onClick={() => openFolder(item)}
              className="dark:bg-primary-shadeddarkgray dark:border-primary-newgray border-primary-gray hover:dark:bg-primary-darkTable relative flex cursor-pointer flex-col items-center justify-center rounded-lg bg-white py-3 shadow transition-all hover:shadow-md"
            >
              <Icons.ByName
                name="folder-icon"
                className="h-20 w-20"
              />
              {isAdmin && (
                <span
                  title="Delete Folder"
                  className="absolute top-1 right-1"
                >
                  <DeleteOutlinedIcon
                    fontSize="small"
                    onClick={e => deleteFolder(e, item)}
                    className="text-red-500 dark:text-red-300"
                  />
                </span>
              )}
              {isAdmin && (
                <span
                  title="Share Folder"
                  className="absolute top-8 right-[0.4rem]"
                >
                  <ShareIcon
                    fontSize="small"
                    onClick={e => handleFolder(e, item)}
                    className="text-black dark:text-white"
                  />
                </span>
              )}

              <p className="w-full truncate text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                {item}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FolderList;
