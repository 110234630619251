import React from 'react';
import type { IconProps } from '../types';

export const Findings = (props: IconProps) => (
  <svg
    viewBox="-2 -2 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke-width="0" />
    <g
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="m13 3 .707-.707A1 1 0 0 0 13 2zm6 6h1a1 1 0 0 0-.293-.707zm-5.891-.546L14 8zm.437.437L14 8zM10 13a1 1 0 1 0-2 0zm-2 3a1 1 0 1 0 2 0zm.5-7a1 1 0 0 0 0 2zm1 2a1 1 0 1 0 0-2zm-1-5a1 1 0 0 0 0 2zm1 2a1 1 0 1 0 0-2zm8.408 12.782-.454-.891zm.874-.874.891.454zm-13.564 0-.891.454zm.874.874.454-.891zm0-17.564-.454-.891zm-.874.874-.891-.454zM12 3v4.4h2V3zm2.6 7H19V8h-4.4zM12 7.4c0 .264 0 .521.017.738.019.229.063.499.201.77L14 8c.03.058.019.08.01-.025A8 8 0 0 1 14 7.4zm2.6.6c-.296 0-.459 0-.575-.01-.105-.009-.082-.02-.025.01l-.908 1.782c.271.138.541.182.77.201.217.018.474.017.738.017zm-2.382.908a2 2 0 0 0 .874.874L14 8zM8 13v3h2v-3zm.5-2h1V9h-1zm0-3h1V6h-1zM13 2H8.2v2H13zM4 6.2v11.6h2V6.2zM8.2 22h7.6v-2H8.2zM20 17.8V9h-2v8.8zm-.293-9.507-6-6-1.414 1.414 6 6zM15.8 22c.544 0 1.011 0 1.395-.03.395-.033.789-.104 1.167-.297l-.908-1.782c-.05.025-.15.063-.422.085C16.75 20 16.377 20 15.8 20zm2.2-4.2c0 .577 0 .949-.024 1.232-.022.272-.06.372-.085.422l1.782.908c.193-.378.264-.772.296-1.167.032-.384.031-.851.031-1.395zm.362 3.873a3 3 0 0 0 1.311-1.311l-1.782-.908a1 1 0 0 1-.437.437zM4 17.8c0 .544 0 1.011.03 1.395.033.395.104.789.297 1.167l1.782-.908c-.025-.05-.063-.15-.085-.422C6 18.75 6 18.377 6 17.8zM8.2 20c-.577 0-.949 0-1.232-.024-.272-.022-.373-.06-.422-.085l-.908 1.782c.378.193.772.264 1.167.296.384.032.851.031 1.395.031zm-3.873.362a3 3 0 0 0 1.311 1.311l.908-1.782a1 1 0 0 1-.437-.437zM8.2 2c-.544 0-1.011 0-1.395.03-.395.033-.789.104-1.167.297l.908 1.782c.05-.025.15-.063.422-.085C7.25 4 7.623 4 8.2 4zM6 6.2c0-.577 0-.949.024-1.232.022-.272.06-.373.085-.422l-1.782-.908c-.193.378-.264.772-.296 1.167C3.999 5.189 4 5.656 4 6.2zm-.362-3.873a3 3 0 0 0-1.311 1.311l1.782.908a1 1 0 0 1 .437-.437z"
      fill="currentColor"
    />
  </svg>
);

export const ReportsPDF = (props: IconProps) => (
  <svg
    height="64"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill="currentColor"
    {...props}
  >
    <path d="M378.413 0H195.115L185.8 9.314 57.02 138.102l-9.314 9.314v278.69c0 47.36 38.528 85.895 85.896 85.895h244.811c47.353 0 85.881-38.535 85.881-85.895V85.896C464.294 38.528 425.766 0 378.413 0m54.084 426.105c0 29.877-24.214 54.091-54.084 54.091H133.602c-29.884 0-54.098-24.214-54.098-54.091V160.591h83.716c24.885 0 45.077-20.178 45.077-45.07V31.804h170.116c29.87 0 54.084 24.214 54.084 54.092z" />
    <path d="M171.947 252.785h-28.529c-5.432 0-8.686 3.533-8.686 8.825v73.754c0 6.388 4.204 10.599 10.041 10.599 5.711 0 9.914-4.21 9.914-10.599v-22.406c0-.545.279-.817.824-.817h16.436c20.095 0 32.188-12.226 32.188-29.612.001-17.658-11.953-29.744-32.188-29.744m-1.228 42.103h-15.208c-.545 0-.824-.272-.824-.81v-23.23c0-.545.279-.816.824-.816h15.208c8.42 0 13.447 5.027 13.447 12.498.001 7.47-5.027 12.358-13.447 12.358m79.472-42.103h-21.868c-5.432 0-8.686 3.533-8.686 8.825v74.843c0 5.3 3.253 8.693 8.686 8.693h21.868c19.69 0 31.923-6.249 36.81-21.324 1.76-5.3 2.723-11.681 2.723-24.857s-.964-19.557-2.723-24.856c-4.888-15.075-17.12-21.324-36.81-21.324m17.665 64.111c-2.318 7.331-8.965 10.459-18.21 10.459h-9.23c-.545 0-.824-.272-.824-.816v-55.146c0-.545.279-.817.824-.817h9.23c9.245 0 15.892 3.128 18.21 10.46.95 3.128 1.62 8.56 1.62 17.93s-.671 14.802-1.62 17.93m93.311-64.111h-44.812c-5.432 0-8.7 3.533-8.7 8.825v73.754c0 6.388 4.218 10.599 10.055 10.599 5.697 0 9.914-4.21 9.914-10.599v-26.351c0-.538.265-.81.81-.81h26.086c5.837 0 9.23-3.532 9.23-8.56s-3.393-8.553-9.23-8.553h-26.086c-.545 0-.81-.272-.81-.817v-19.425c0-.545.265-.816.81-.816h32.733c5.572 0 9.245-3.666 9.245-8.553-.001-5.029-3.674-8.694-9.245-8.694" />
  </svg>
);
