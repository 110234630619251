import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    height: '1000px',
    padding: 5,
    backgroundColor: '#f8f8f8',
  },
  section: {
    marginBottom: 10,
    padding: 10,
    borderBottom: '1 solid #e0e0e0',
  },
  header: {
    fontSize: 16,
    color: 'black',
    marginBottom: 5,
    fontWeight: 'bold',
  },
  content: {
    color: 'black',
    fontSize: 12,
    marginBottom: 5,
  },
});

function MyDocument({ tableData, index }) {
  const { row } = tableData[index];

  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
      >
        <View style={styles.section}>
          <Text style={styles.header}>Patient Name</Text>
          <Text style={styles.content}>{row[0].content.props.children}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.header}>Study Date</Text>
          <Text style={styles.content}>{row[1].title}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.header}>Modality</Text>
          <Text style={styles.content}>{row[2].content}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.header}>Accession</Text>
          <Text style={styles.content}>{row[3].content.props.children}</Text>
        </View>
      </Page>
    </Document>
  );
}

export default MyDocument;
