import React, { useCallback, useEffect, useState, createRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getSpecificBucket } from '../../../../../utils/bucket';
import Typography from '../Typography';
import Input from '../Input';
import Tooltip from '../Tooltip';
import IconButton from '../IconButton';
import Select from '../Select';
import InputLabelWrapper from '../InputLabelWrapper';
import Button, { ButtonEnums } from '../Button';
import { Icons } from '@ohif/ui-next';
import axios from 'axios';
import {
  API,
  KeyImagesAPI,
  LocalKeyImages,
  LocalKeyImagesXRAD,
} from '../../../../../constants/baseurl';
import html2canvas from 'html2canvas';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

const FILE_TYPE_OPTIONS = [
  {
    value: 'jpg',
    label: 'jpg',
  },
  {
    value: 'png',
    label: 'png',
  },
];

const DEFAULT_FILENAME = 'image';

const REFRESH_VIEWPORT_TIMEOUT = 100;

const ViewportDownloadForm = ({
  activeViewportElement,
  onClose,
  updateViewportPreview,
  enableViewport,
  disableViewport,
  toggleAnnotations,
  loadImage,
  downloadBlob,
  defaultSize,
  minimumSize,
  maximumSize,
  canvasClass,
}) => {
  const { t } = useTranslation('Modals');

  const [filename, setFilename] = useState(DEFAULT_FILENAME);
  const [fileType, setFileType] = useState(['jpg']);

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const studyUID = params.get('StudyInstanceUIDs');

  const [dimensions, setDimensions] = useState({
    width: defaultSize,
    height: defaultSize,
  });

  const [showAnnotations, setShowAnnotations] = useState(true);

  const [keyImages, setKeyImages] = useState([]);
  const [isCaseFinalized, setIsCaseFinalized] = useState(false);

  const [keepAspect, setKeepAspect] = useState(true);
  const [aspectMultiplier, setAspectMultiplier] = useState({
    width: 1,
    height: 1,
  });

  const [viewportElement, setViewportElement] = useState();
  const [viewportElementDimensions, setViewportElementDimensions] = useState({
    width: defaultSize,
    height: defaultSize,
  });

  const [downloadCanvas, setDownloadCanvas] = useState({
    ref: createRef(),
    width: defaultSize,
    height: defaultSize,
  });

  const [viewportPreview, setViewportPreview] = useState({
    src: null,
    width: defaultSize,
    height: defaultSize,
  });

  const [error, setError] = useState({
    width: false,
    height: false,
    filename: false,
  });

  const hasError = Object.values(error).includes(true);

  const refreshViewport = useRef(null);

  const onKeepAspectToggle = () => {
    const { width, height } = dimensions;
    if (!keepAspect) {
      const aspectMultiplier = {
        width: width / height,
        height: height / width,
      };
      setAspectMultiplier(aspectMultiplier);
    }

    setKeepAspect(!keepAspect);
  };

  const downloadImage = () => {
    downloadBlob(
      filename || DEFAULT_FILENAME,
      fileType,
      viewportElement,
      downloadCanvas.ref.current
    );
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const finalized = urlParams.get('isFinalized');
    const isRADX = window.location.href.includes('/radx/viewer');
    if (finalized) {
      const isFinalizedValue = finalized.toLowerCase() === 'true';
      setIsCaseFinalized(isFinalizedValue);
    }

    if (isRADX) {
      setIsCaseFinalized(true);
    }
  }, []);

  const cropImage = canvas => {
    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    let minX = canvas.width,
      minY = canvas.height,
      maxX = 0,
      maxY = 0;

    for (let y = 0; y < canvas.height; y++) {
      for (let x = 0; x < canvas.width; x++) {
        const i = (y * canvas.width + x) * 4;
        if (data[i] > 0 || data[i + 1] > 0 || data[i + 2] > 0) {
          minX = Math.min(minX, x);
          minY = Math.min(minY, y);
          maxX = Math.max(maxX, x);
          maxY = Math.max(maxY, y);
        }
      }
    }

    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');
    const width = maxX - minX + 1;
    const height = maxY - minY + 1;

    croppedCanvas.width = width;
    croppedCanvas.height = height;

    croppedCtx.drawImage(canvas, minX, minY, width, height, 0, 0, width, height);

    return croppedCanvas;
  };

  const fetchBlob = async () => {
    const VIEWPORT_ID = 'cornerstone-viewport-download-form';

    const divForDownloadViewport = document.querySelector(
      `div[data-viewport-uid="${VIEWPORT_ID}"]`
    );

    try {
      const canvas = await html2canvas(divForDownloadViewport);
      //const croppedCanvas = cropImage(canvas);
      const jpgDataUrl = canvas.toDataURL('image/png', 1);

      const response = await fetch(jpgDataUrl);
      const blob = await response.blob();

      return blob;
    } catch (error) {
      console.log(error);
    }
  };

  const blobToBase64 = blob => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const saveKeyImage = async () => {
    const localParams = new URLSearchParams(window.location.search);
    const patientName = localParams.get('PatientName');
    const blob = await fetchBlob();
    const base64Image = await blobToBase64(blob);
    const token = localStorage.getItem('token');
    const isXRAD =
      window.location.href.includes('/xrad/') || window.location.href.includes('/radx/');

    const folderPath = localParams.get('folderPath');

    if (isXRAD) {
      if (patientName && patientName != 'undefined') {
        const body = {
          dirPath: patientName,
          keyImages: `data:image/png;base64,${base64Image}`,
        };

        try {
          const response = await axios.post(
            `${LocalKeyImagesXRAD}/keyImages/v4/keyImages/local_key`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.data) {
            getAllKeyImages();
          }
        } catch (err) {
          console.log(err);
        }
      } else if (folderPath && folderPath != 'undefined') {
        const bucketId = localParams.get('bucketId');
        if (bucketId) {
          const bucket = await getSpecificBucket(bucketId);
          const body = {
            AwsAccessKey: bucket.accessKeyId,
            AwsBucket: bucket.bucketName,
            AwsSecretAccessKey: bucket.secretAccessKey,
            SUID: folderPath.replaceAll('()', '/') + '' + studyUID,
            dirPath: '',
            keyImages: `data:image/png;base64,${base64Image}`,
          };

          try {
            const response = await axios.post(`${KeyImagesAPI}/keyImages/v1/keyImages`, body, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (response.data) {
              getAllKeyImages();
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
    } else if (patientName && patientName != 'undefined') {
      const body = {
        dirPath: patientName,
        keyImages: `data:image/png;base64,${base64Image}`,
      };

      try {
        const response = await axios.post(
          `${LocalKeyImages}/keyImages/v4/keyImages/local_key`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data) {
          getAllKeyImages();
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      const bucketId = localStorage.getItem('bucketId');
      if (bucketId) {
        const bucket = await getSpecificBucket(bucketId);
        const body =
          folderPath && folderPath != 'undefined'
            ? {
                AwsAccessKey: bucket.accessKeyId,
                AwsBucket: bucket.bucketName,
                AwsSecretAccessKey: bucket.secretAccessKey,
                SUID: folderPath.replaceAll('()', '/') + '' + studyUID,
                dirPath: '',
                keyImages: `data:image/png;base64,${base64Image}`,
              }
            : {
                AwsAccessKey: bucket.accessKeyId,
                AwsBucket: bucket.bucketName,
                AwsSecretAccessKey: bucket.secretAccessKey,
                SUID: studyUID,
                dirPath: '',
                keyImages: `data:image/png;base64,${base64Image}`,
              };

        try {
          const response = await axios.post(`${KeyImagesAPI}/keyImages/v1/keyImages`, body, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data) {
            getAllKeyImages();
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const getAllKeyImages = async () => {
    const localParams = new URLSearchParams(window.location.search);
    const patientName = localParams.get('PatientName');
    const token = localStorage.getItem('token');
    const isXRAD =
      window.location.href.includes('/xrad/') || window.location.href.includes('/radx/');

    const folderPath = localParams.get('folderPath');
    if (isXRAD) {
      if (patientName && patientName != 'undefined') {
        try {
          const response = await axios.get(
            `${LocalKeyImagesXRAD}/keyImages/v4/keyImages/local_key?dirPath=${encodeURIComponent(patientName)}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.data) {
            setKeyImages(response.data.data);
          }
        } catch (err) {
          console.log(err);
        }
      } else if (folderPath && folderPath != 'undefined') {
        const bucketId = localParams.get('bucketId');
        if (bucketId) {
          const bucket = await getSpecificBucket(bucketId);

          try {
            const response = await axios.get(
              `${KeyImagesAPI}/keyImages/v1/keyImages?SUID=${folderPath.replaceAll('()', '/') + '' + studyUID}&AwsAccessKey=${
                bucket.accessKeyId
              }&AwsSecretAccessKey=${encodeURIComponent(bucket.secretAccessKey)}&AwsBucket=${
                bucket.bucketName
              }`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            setKeyImages(response.data.data);
          } catch (err) {
            console.log(err);
          }
        }
      }
    } else if (patientName && patientName != 'undefined') {
      try {
        const response = await axios.get(
          `${LocalKeyImages}/keyImages/v4/keyImages/local_key?dirPath=${encodeURIComponent(patientName)}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setKeyImages(response.data.data);
      } catch (err) {
        console.log(err);
      }
    } else {
      const bucketId = localStorage.getItem('bucketId');

      if (bucketId) {
        const bucket = await getSpecificBucket(bucketId);

        try {
          const response = await axios.get(
            `${KeyImagesAPI}/keyImages/v1/keyImages?SUID=${folderPath && folderPath != 'undefined' ? folderPath.replaceAll('()', '/') + '' + studyUID : studyUID}&AwsAccessKey=${
              bucket.accessKeyId
            }&AwsSecretAccessKey=${encodeURIComponent(bucket.secretAccessKey)}&AwsBucket=${
              bucket.bucketName
            }`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setKeyImages(response.data.data);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const deleteKeyImage = async suid => {
    const token = localStorage.getItem('token');
    const localParams = new URLSearchParams(window.location.search);
    const patientName = localParams.get('PatientName');
    const isXRAD =
      window.location.href.includes('/xrad/') || window.location.href.includes('/radx/');

    const folderPath = localParams.get('folderPath');

    if (isXRAD) {
      if (patientName && patientName != 'undefined') {
        try {
          const response = await axios.delete(
            `${LocalKeyImagesXRAD}/keyImages/v4/keyImages/local_key?dirPath=${encodeURIComponent(
              patientName
            )}&key=${suid}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.data) {
            getAllKeyImages();
          }
        } catch (err) {
          console.log(err);
        }
      } else if (folderPath && folderPath != 'undefined') {
        const bucketId = localParams.get('bucketId');
        if (bucketId) {
          const bucket = await getSpecificBucket(bucketId);

          try {
            const response = await axios.delete(
              `${KeyImagesAPI}/keyImages/v1/keyImages?SUID=${suid}&AwsAccessKey=${
                bucket.accessKeyId
              }&AwsSecretAccessKey=${encodeURIComponent(bucket.secretAccessKey)}&AwsBucket=${bucket.bucketName}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.data) {
              getAllKeyImages();
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
    } else if (patientName && patientName != 'undefined') {
      try {
        const response = await axios.delete(
          `${LocalKeyImages}/keyImages/v4/keyImages/local_key?dirPath=${encodeURIComponent(patientName)}&key=${suid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data) {
          getAllKeyImages();
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      const bucketId = localStorage.getItem('bucketId');

      if (bucketId) {
        const bucket = await getSpecificBucket(bucketId);

        try {
          const response = await axios.delete(
            `${KeyImagesAPI}/keyImages/v1/keyImages?SUID=${suid}&AwsAccessKey=${
              bucket.accessKeyId
            }&AwsSecretAccessKey=${encodeURIComponent(bucket.secretAccessKey)}&AwsBucket=${bucket.bucketName}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.data) {
            getAllKeyImages();
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  useEffect(() => {
    getAllKeyImages();
  }, []);

  /**
   * @param {object} value - Input value
   * @param {string} dimension - "height" | "width"
   */
  const onDimensionsChange = (value, dimension) => {
    const oppositeDimension = dimension === 'height' ? 'width' : 'height';
    const sanitizedTargetValue = value.replace(/\D/, '');
    const isEmpty = sanitizedTargetValue === '';
    const newDimensions = { ...dimensions };
    const updatedDimension = isEmpty ? '' : Math.min(sanitizedTargetValue, maximumSize);

    if (updatedDimension === dimensions[dimension]) {
      return;
    }

    newDimensions[dimension] = updatedDimension;

    if (keepAspect && newDimensions[oppositeDimension] !== '') {
      newDimensions[oppositeDimension] = Math.round(
        newDimensions[dimension] * aspectMultiplier[oppositeDimension]
      );
    }

    // In current code, keepAspect is always `true`
    // And we always start w/ a square width/height
    setDimensions(newDimensions);

    // Only update if value is non-empty
    if (!isEmpty) {
      setViewportElementDimensions(newDimensions);
      setDownloadCanvas(state => ({
        ...state,
        ...newDimensions,
      }));
    }
  };

  const error_messages = {
    width: 'The minimum valid width is 100px.',
    height: 'The minimum valid height is 100px.',
    filename: 'The file name cannot be empty.',
  };

  const renderErrorHandler = errorType => {
    if (!error[errorType]) {
      return null;
    }

    return (
      <Typography
        className="mt-2 pl-1"
        color="error"
      >
        {error_messages[errorType]}
      </Typography>
    );
  };

  const validSize = useCallback(
    value => (value >= minimumSize ? value : minimumSize),
    [minimumSize]
  );

  const loadAndUpdateViewports = useCallback(async () => {
    const { width: scaledWidth, height: scaledHeight } = await loadImage(
      activeViewportElement,
      viewportElement,
      dimensions.width,
      dimensions.height
    );

    toggleAnnotations(showAnnotations, viewportElement, activeViewportElement);

    const scaledDimensions = {
      height: validSize(scaledHeight),
      width: validSize(scaledWidth),
    };

    setViewportElementDimensions(scaledDimensions);
    setDownloadCanvas(state => ({
      ...state,
      ...scaledDimensions,
    }));

    const {
      dataUrl,
      width: viewportElementWidth,
      height: viewportElementHeight,
    } = await updateViewportPreview(viewportElement, downloadCanvas.ref.current, fileType);

    setViewportPreview(state => ({
      ...state,
      src: dataUrl,
      width: validSize(viewportElementWidth),
      height: validSize(viewportElementHeight),
    }));
  }, [
    loadImage,
    activeViewportElement,
    viewportElement,
    dimensions.width,
    dimensions.height,
    toggleAnnotations,
    showAnnotations,
    validSize,
    updateViewportPreview,
    downloadCanvas.ref,
    fileType,
  ]);

  useEffect(() => {
    enableViewport(viewportElement);

    return () => {
      disableViewport(viewportElement);
    };
  }, [disableViewport, enableViewport, viewportElement]);

  useEffect(() => {
    if (refreshViewport.current !== null) {
      clearTimeout(refreshViewport.current);
    }

    refreshViewport.current = setTimeout(() => {
      refreshViewport.current = null;
      loadAndUpdateViewports();
    }, REFRESH_VIEWPORT_TIMEOUT);
  }, [
    activeViewportElement,
    viewportElement,
    showAnnotations,
    dimensions,
    loadImage,
    toggleAnnotations,
    updateViewportPreview,
    fileType,
    downloadCanvas.ref,
    minimumSize,
    maximumSize,
    loadAndUpdateViewports,
  ]);

  useEffect(() => {
    const { width, height } = dimensions;
    const hasError = {
      width: width < minimumSize,
      height: height < minimumSize,
      filename: !filename,
    };

    setError({ ...hasError });
  }, [dimensions, filename, minimumSize]);

  return (
    <div>
      {/* <Typography variant="h6">
        {t('Please specify the dimensions, filename, and desired type for the output image.')}
      </Typography> */}

      <div className="mt-1">
        <div
          className="dark:bg-primary-modalgray border-secondary-primary w-max-content min-w-full rounded bg-white p-4 py-0 pl-0"
          data-cy="image-preview"
        >
          {/* <Typography variant="h5">{t('Image preview')}</Typography> */}
          <div className="flex">
            <div className="flex max-h-[309px] flex-col overflow-y-auto pr-3 pl-0 pt-1">
              {keyImages.length > 0 ? (
                keyImages.map(item => {
                  return (
                    <div
                      key={item.Body}
                      className="relative my-2"
                    >
                      <div
                        className="absolute right-1 top-1 cursor-pointer"
                        onClick={() => deleteKeyImage(item.key)}
                      >
                        <DeleteRoundedIcon
                          className="text-red-500"
                          fontSize="small"
                        />
                      </div>
                      <img
                        className="dark:border-primary-newgray border-primary-gray h-36 w-44 border"
                        src={`data:image/png;base64,${item.Body}`}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="text-black dark:text-white">No Marked Keyimages</div>
              )}
            </div>
            <div className="border-primary-newgray h-[305px] w-[2px] border-r"></div>
            {activeViewportElement && (
              <div
                className="mx-auto my-2"
                style={{
                  height: viewportElementDimensions.height,
                  width: viewportElementDimensions.width,
                }}
                ref={ref => setViewportElement(ref)}
              ></div>
            )}
          </div>

          {!activeViewportElement && (
            <Typography className="mt-4">{t('Active viewport has no displayed image')}</Typography>
          )}
        </div>
      </div>
      <div className="mt-2 flex flex-col">
        <div className="flex">
          <div className="flex w-1/3">
            <div className="flex grow flex-col">
              <div className="w-full">
                <Input
                  type="number"
                  min={minimumSize}
                  max={maximumSize}
                  label={t('Image width (px)')}
                  value={dimensions.width}
                  onChange={evt => onDimensionsChange(evt.target.value, 'width')}
                  data-cy="image-width"
                />
                {renderErrorHandler('width')}
              </div>
              <div className="mt-4 w-full">
                <Input
                  type="number"
                  min={minimumSize}
                  max={maximumSize}
                  label={t('Image height (px)')}
                  value={dimensions.height}
                  onChange={evt => onDimensionsChange(evt.target.value, 'height')}
                  data-cy="image-height"
                />
                {renderErrorHandler('height')}
              </div>
            </div>

            <div className="mt-8 flex items-center">
              <Tooltip
                position="right"
                content={keepAspect ? 'Dismiss Aspect' : 'Keep Aspect'}
              >
                <IconButton
                  onClick={onKeepAspectToggle}
                  size="small"
                  rounded="full"
                >
                  <Icons.ByName name={keepAspect ? 'link' : 'unlink'} />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div className="border-primary-newgray ml-6 w-1/2 border-l pl-6">
            <div className="mb-4 w-full">
              <Input
                data-cy="file-name"
                value={filename}
                onChange={evt => setFilename(evt.target.value)}
                label={t('File Name')}
              />
              {renderErrorHandler('filename')}
            </div>
            <div>
              <InputLabelWrapper
                sortDirection="none"
                label={t('File Type')}
                isSortable={false}
                onLabelClick={() => {}}
              >
                <Select
                  className="mt-2 text-white"
                  isClearable={false}
                  value={fileType}
                  data-cy="file-type"
                  onChange={value => {
                    setFileType([value.value]);
                  }}
                  hideSelectedOptions={false}
                  options={FILE_TYPE_OPTIONS}
                  placeholder="File Type"
                />
              </InputLabelWrapper>
            </div>
            <div className="mt-4 ml-2">
              <label
                htmlFor="show-annotations"
                className="flex items-center"
              >
                <input
                  id="show-annotations"
                  data-cy="show-annotations"
                  type="checkbox"
                  className="mr-2"
                  checked={showAnnotations}
                  onChange={event => setShowAnnotations(event.target.checked)}
                />
                <Typography>{t('Show Annotations')}</Typography>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 flex justify-end">
        <Button
          name="cancel"
          type={ButtonEnums.type.secondary}
          onClick={onClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          className="ml-2"
          disabled={hasError}
          onClick={downloadImage}
          type={ButtonEnums.type.primary}
          name={'download'}
        >
          {t('Download')}
        </Button>
        {!isCaseFinalized && (
          <Button
            className="ml-2"
            disabled={hasError}
            onClick={saveKeyImage}
            type={ButtonEnums.type.primary}
            name={'download'}
          >
            {t('Save')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ViewportDownloadForm;
