import React from 'react';
import type { IconProps } from '../types';

export const Tooth = (props: IconProps) => (
  <svg
    viewBox="0 0 15 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.36 14c-1 0-.56-2.67-.86-5-.1-.76-1-1.49-1.12-2.06C2 5 1.39 1.44 3.66 1S6 3 7.54 3 9.11.64 11.39 1s1.59 3.9 1.29 5.9c-.1.45-1.1 1.48-1.18 2.06-.33 2.4.32 5-.8 5-.93 0-1.32-2.72-2-4.5C8.43 8.63 8.06 8 7.54 8 6 8 5.75 14 4.36 14" />
  </svg>
);

export const Teeth3D = (props: IconProps) => (
  <svg
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke-width="0" />
    <g
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <path
        stroke="#E43535"
        stroke-linecap="round"
        stroke-width="6"
        d="M6 20c0-4.97 11.864-9 26.5-9h0C47.136 11 59 15.03 59 20"
      />
      <path
        fill="#0096fa"
        d="M23 4c-6.627 0-11 7.611-11 17s4.373 39 11 39c3.866 0 4.305-24.59 9.5-24.612C38.695 35.41 38.134 60 42 60c6.627 0 11-29.611 11-39S48.627 4 42 4c-3.167 0-6.333 3-9.5 3S26.167 4 23 4"
      />
      <path
        stroke="#FF5656"
        stroke-linecap="round"
        stroke-width="6"
        d="M6 20c0 4.97 11.864 9 26.5 9m15.42-1.68C54.629 25.687 59 23.017 59 20"
      />
      <path
        fill="#FF5656"
        d="m40 29-9 6V23z"
      />
      <path
        stroke="silver"
        stroke-linecap="round"
        stroke-width="2"
        d="M25 8a8 8 0 0 0-8 8"
      />
    </g>
  </svg>
);

export default Tooth;
