import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import LegacyButton from '../LegacyButton';
import Icon from '../Icon';
import Typography from '../Typography';
import InputGroup from '../InputGroup';
import Tooltip from '../Tooltip';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InputText from '../InputText';
import DropDownInputGroup from '../InputGroup/DropDownInputGroup';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { servicesManager } from '../../../../app/src/App';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLocal } from '../../../../../platform/app/src/redux/features/studylist/studylist';
import {
  setFolderStructure,
  setSelectedFolder,
} from '../../../../../platform/app/src/redux/features/studylist/userlist';
import CreateFolderModal from './CreateFolderModal';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

const StudyListFilter = ({
  filtersMeta,
  filterValues,
  onChange,
  clearFilters,
  isFiltering,
  numOfStudies,
  onUploadClick,
  getDataSourceConfigurationComponent,
  tableSource,
  checked,
  selectAll,
}) => {
  const { t } = useTranslation('StudyList');
  const { sortBy, sortDirection } = filterValues;
  const filterSorting = { sortBy, sortDirection };
  const [isDropDown, setIsDropDown] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [isSwitchDataSource, setSwitchDataSource] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFolderModal, setIsFolderModal] = useState(false);
  const isLocal = useSelector(state => state.list.isLocal);
  const isFolderStructure = useSelector(state => state.user.folderStructure);
  const folderName = useSelector(state => state.user.selectedFolder);
  const folders = useSelector(state => state.user.folders);
  const [isAllowed, setIsAllowed] = useState(false);
  const [hasStudyId, setHasStudyId] = useState(false);
  const dispatch = useDispatch();
  const setFilterSorting = sortingValues => {
    onChange({
      ...filterValues,
      ...sortingValues,
    });
  };
  const isSortingEnabled = true;
  const { uiNotificationService } = servicesManager.services;

  const navigate = useNavigate();
  const total_buckets = filtersMeta.find(item => item.name === 'bucket');
  const numBuckets = total_buckets ? total_buckets.inputProps.options.length : 0;

  const extractTextContent = content => {
    if (typeof content === 'string') {
      return content;
    } else if (React.isValidElement(content)) {
      return content.props.children || 'N/A';
    } else {
      return 'N/A';
    }
  };

  const navigateToLocal = () => {
    navigate(`/local`);
  };

  const navigateToCloud = () => {
    navigate(`/cloud-upload`);
  };

  const openDropDown = () => {
    setIsDropDown(!isDropDown);
  };

  const closeDropDown = () => {
    setIsDropDown(false);
  };

  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeDropDown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropDown]);

  const downloadCSV = () => {
    if (!tableSource || !tableSource.length) {
      alert('No data available to download');
      return;
    }

    const fields = [
      { label: 'Sr No.', value: 'srNo' },
      { label: 'Patient Name', value: 'PatientName' },
      { label: 'Study Date', value: 'StudyDate' },
      { label: 'Modality', value: 'modality' },
      { label: 'Accession', value: 'AccessionNumber' },
      { label: 'Bucket', value: 'Bucket', default: 'N/A' },
    ];

    const checkedData = tableSource.filter((item, index) =>
      checked.some(checkedItem => checkedItem.index_value === index && checkedItem.index_state)
    );

    const csvData = checkedData.map((item, index) => {
      const rowContent = item.row.reduce((acc, rowItem) => {
        acc[rowItem.key] = extractTextContent(rowItem.content);
        return acc;
      }, {});

      return {
        srNo: index + 1,
        PatientName: rowContent.PatientName || 'N/A',
        StudyDate: rowContent.StudyDate?.props?.children || 'N/A',
        modality: rowContent.modality || 'N/A',
        AccessionNumber: rowContent.AccessionNumber || 'N/A',
      };
    });

    // const defaultCsvData = tableSource.map((item, index) => {
    //   const rowContent = item.row.reduce((acc, rowItem) => {
    //     acc[rowItem.key] = extractTextContent(rowItem.content);
    //     return acc;
    //   }, {});

    //   return {
    //     srNo: index + 1,
    //     PatientName: rowContent.PatientName || 'N/A',
    //     StudyDate: rowContent.StudyDate || 'N/A',
    //     modality: rowContent.modality || 'N/A',
    //     AccessionNumber: rowContent.AccessionNumber || 'N/A',
    //     Bucket: rowContent.Bucket || 'N/A',
    //   };
    // });

    try {
      // const csv = Papa.unparse(csvData.length > 0 ? csvData : defaultCsvData, { fields });
      if (csvData.length > 0) {
        const csv = Papa.unparse(csvData, { fields });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'Patients-Study.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        uiNotificationService.show({
          title: 'Download CSV',
          message: 'No rows selected, please select a row before downloading!',
          type: 'warning',
          duration: 3000,
        });
      }
    } catch (err) {
      console.error('Error generating CSV: ', err);
      alert('Error generating CSV. Please try again.');
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const user_data = JSON.parse(localStorage.getItem('user_data'));
  const is_share = JSON.parse(localStorage.getItem('share'));
  const admin = user_data && user_data.admin && user_data.admin == 'true' ? true : false;
  const share_case =
    user_data && user_data.isShared && is_share && user_data.isShared == 'true' && is_share == true
      ? true
      : false;

  const filtersMeta3 = filtersMeta.filter(filter => filter.name === 'PatientName');
  const filtersMeta4 =
    admin == true
      ? filtersMeta.filter(filter => filter.name !== 'PatientName')
      : filtersMeta.filter(filter => filter.name !== 'PatientName' && filter.name !== 'clients');

  const filtersMeta2 =
    share_case == true || isLocal
      ? filtersMeta4.filter(filter => filter.name !== 'bucket' && filter.name !== 'clients')
      : filtersMeta4;

  useEffect(() => {
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const is_demo = user_data && user_data.email == 'userdemo@gmail.com';
    const is_share = JSON.parse(localStorage.getItem('share'));
    const is_allowed = JSON.parse(localStorage.getItem('is_allowed'));

    const shareStudyInstanceUid = localStorage.getItem('shareUid');
    const admin = user_data && user_data.admin && user_data.admin == 'true' ? true : false;
    const share_case =
      user_data &&
      user_data.isShared &&
      is_share &&
      user_data.isShared == 'true' &&
      is_share == true
        ? true
        : false;

    setIsAdmin(admin);
    setSwitchDataSource(share_case);
    setIsShare(share_case);
    setIsDemo(is_demo);
    setIsAllowed(is_allowed);

    if (shareStudyInstanceUid && shareStudyInstanceUid != '') {
      setHasStudyId(true);
    } else {
      setHasStudyId(false);
    }

    // if (share_case == false) {
    //   const share_service = user_data?.sharingwithusers || false;
    //   setIsShare(!share_service);
    //   if (user_data && user_data.admin && user_data.admin == 'true') {
    //     setIsShare(false);
    //   }
    // }
  }, []);

  const switchDataSource = () => {
    dispatch(setIsLocal(!isLocal));
  };

  const setFolder = () => {
    dispatch(setSelectedFolder(''));
    dispatch(setFolderStructure(!isFolderStructure));
  };

  const setRoot = () => {
    dispatch(setSelectedFolder(''));
    dispatch(setFolderStructure(false));
  };

  return (
    <React.Fragment>
      {/* <div>
        <div className="dark:bg-primary-modalgray bg-white">
          <div className="relative mx-4 flex flex-col pt-2 md:mx-7">
            <div className="mb-2 flex justify-between md:flex-row">
              <div className="mt-1 flex min-w-[1px] shrink flex-row items-center gap-3">
                <Typography
                  variant="h6"
                  className="text-black dark:text-white"
                >
                  {t('StudyList')}
                </Typography>
                {getDataSourceConfigurationComponent && getDataSourceConfigurationComponent()}
                {onUploadClick && (
                  <div
                    className="text-primary-active flex cursor-pointer items-center gap-2 self-center text-lg font-semibold"
                    onClick={onUploadClick}
                  >
                    <Icon name="icon-upload"></Icon>
                    <span>{t('Upload')}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {isFolderModal && (
        <CreateFolderModal
          isOpen={isFolderModal}
          onClose={setIsFolderModal}
        />
      )}
      <div className="-top-1 z-10">
        <div className="dark:bg-primary-modalgray flex flex-col items-center justify-between bg-white pt-0 pb-3 md:flex-row md:items-start">
          <div className="flex">
            {!isFolderStructure && (
              <div className="relative">
                <div
                  className="dark:bg-primary-newgray border-primary-gray dark:hover:bg-primary-darktable dark:border-primary-newgray hover:border-primary-darktable mt-[10px] ml-7 flex h-8 cursor-pointer justify-between rounded-md border-[1px] bg-white px-2 py-[4px] shadow-sm transition duration-300 dark:text-white"
                  onClick={handleToggle}
                >
                  <span className="mr-2 mt-[1px] text-base">Filters</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5acce6"
                  >
                    <path d="M710-150q-63 0-106.5-43.5T560-300q0-63 43.5-106.5T710-450q63 0 106.5 43.5T860-300q0 63-43.5 106.5T710-150Zm0-80q29 0 49.5-20.5T780-300q0-29-20.5-49.5T710-370q-29 0-49.5 20.5T640-300q0 29 20.5 49.5T710-230Zm-550-30v-80h320v80H160Zm90-250q-63 0-106.5-43.5T100-660q0-63 43.5-106.5T250-810q63 0 106.5 43.5T400-660q0 63-43.5 106.5T250-510Zm0-80q29 0 49.5-20.5T320-660q0-29-20.5-49.5T250-730q-29 0-49.5 20.5T180-660q0 29 20.5 49.5T250-590Zm230-30v-80h320v80H480Zm230 320ZM250-660Z" />
                  </svg>
                </div>

                <div
                  className={`dark:bg-primary-modalgray border-primary-newgray absolute left-5 z-50 mt-2 w-80 rounded-md border-[1px] bg-white shadow-lg transition-all duration-300 ${
                    isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
                  }`}
                >
                  {isOpen && (
                    <div className="py-4 px-0">
                      <InputGroup
                        inputMeta={filtersMeta2}
                        values={filterValues}
                        onValuesChange={onChange}
                        placeHolder="Search..."
                        sorting={filterSorting}
                        onSortingChange={setFilterSorting}
                        isSortingEnabled={isSortingEnabled}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {isFiltering && !isFolderStructure && (
              <div
                onClick={clearFilters}
                className="dark:bg-primary-newgray border-primary-gray dark:hover:bg-primary-darktable dark:border-primary-newgray hover:border-primary-darktable dark:text-primary-light mt-[10px] ml-2 flex h-8 cursor-pointer justify-between rounded-md border-[1px] bg-white px-2 py-[2px] shadow-sm transition duration-300"
              >
                <span title="Clear Filters">
                  <SearchOffIcon />
                </span>
              </div>
            )}
            {!isFolderStructure && (
              <InputGroup
                inputMeta={filtersMeta3}
                values={filterValues}
                onValuesChange={onChange}
                placeHolder="Search Patients..."
                sorting={filterSorting}
                onSortingChange={setFilterSorting}
                isSortingEnabled={isSortingEnabled}
              />
            )}
            {!isDemo && isFolderStructure && (
              <div
                className="border-primary-gray dark:border-primary-newgray hover:bg-primary-gray dark:hover:bg-primary-darktable dark:bg-primary-newgray mx-5 mt-2 h-8 cursor-pointer rounded-md border-[1px] p-[2px] px-[3px] pb-[3px] shadow-sm transition duration-300"
                onClick={() => setIsFolderModal(true)}
              >
                <span
                  title="Folder Structure"
                  className="flex px-1 text-base font-semibold text-black dark:text-white"
                >
                  <span className="pt-1">Create Folder</span>
                </span>
              </div>
            )}
            {isAdmin && (
              <div
                className="border-primary-gray dark:border-primary-newgray hover:bg-primary-gray dark:hover:bg-primary-darktable dark:bg-primary-newgray mt-2 h-8 cursor-pointer rounded-md border-[1px] p-[2px] px-[3px] pb-[3px] shadow-sm transition duration-300"
                onClick={setFolder}
              >
                <span
                  title="Folder Structure"
                  className="flex px-1 text-base font-semibold text-black dark:text-white"
                >
                  {folderName != '' ? (
                    <span className="flex items-center gap-x-1">
                      <ArrowLeftIcon className="pt-[0.5px]" />
                      <span className="pt-[1px]">Folders / {folderName}</span>
                    </span>
                  ) : (
                    <span className="pt-1">{isFolderStructure ? 'Studylist' : 'Folders'}</span>
                  )}
                </span>
              </div>
            )}
            {/* {isAdmin && folderName != '' && !isFolderStructure && (
              <div
                className="border-primary-gray dark:border-primary-newgray hover:bg-primary-gray dark:hover:bg-primary-darktable dark:bg-primary-newgray ml-2 mt-2 h-8 cursor-pointer rounded-md border-[1px] p-[2px] px-[3px] pb-[3px] shadow-sm transition duration-300"
                onClick={setRoot}
              >
                <span
                  title="Folder Structure"
                  className="flex px-1 text-base font-semibold text-black dark:text-white"
                >
                  <span className="pt-1">Root</span>
                </span>
              </div>
            )} */}
          </div>
          <div className="mt-2">
            <span className="text-2xl font-semibold text-black dark:text-white">
              {isFolderStructure ? 'Folder List' : 'Study List'}
            </span>
          </div>
          <div className="mt-2 ml-40 flex h-[34px] flex-row items-center sm:mr-1 md:ml-0 md:mr-6 lg:mr-8">
            {/* {isFiltering && (
              <LegacyButton
                rounded="full"
                variant="outlined"
                color="lightActive"
                border="buttonBorder"
                className="mx-5"
                startIcon={<Icon name="cancel" />}
                onClick={clearFilters}
              >
                {t('ClearFilters')}
              </LegacyButton>
            )} */}
            {!isFolderStructure &&
              folderName == '' &&
              !isSwitchDataSource &&
              (isDemo || isAdmin) && (
                <div
                  className="border-primary-gray dark:border-primary-newgray hover:bg-primary-gray dark:hover:bg-primary-darktable dark:bg-primary-newgray ml-4 mr-1 cursor-pointer rounded-md border-[1px] p-[2px] px-[3px] pb-[3px] shadow-sm transition duration-300"
                  onClick={switchDataSource}
                >
                  <span
                    title="Switch Data Source"
                    className="flex py-0.5 px-1 text-base font-semibold text-black dark:text-white"
                  >
                    {isLocal == true ? 'AWS S3' : 'LocalFS'}
                  </span>
                </div>
              )}
            {!isFolderStructure && !isShare && !isDemo && (
              <div
                className="border-primary-gray dark:border-primary-newgray hover:bg-primary-gray dark:hover:bg-primary-darktable dark:bg-primary-newgray ml-2 mr-1 cursor-pointer rounded-md border-[1px] p-[2px] px-[3px] pb-[3px] shadow-sm transition duration-300"
                onClick={downloadCSV}
              >
                <span
                  title="Download as CSV"
                  className="flex pl-1 text-base font-semibold text-black dark:text-white"
                >
                  <span className="pt-1">Download</span>{' '}
                  <FileDownloadOutlinedIcon className="ml-1" />
                </span>
              </div>
            )}
            {!isFolderStructure && !isDemo && !isShare && (
              <div
                ref={modalRef}
                className="border-primary-gray dark:border-primary-newgray hover:bg-primary-gray dark:hover:bg-primary-darktable dark:bg-primary-newgray relative ml-1 mr-4 cursor-pointer rounded-md border-[1px] p-[2px] px-[3px] pb-[3px] shadow-sm transition duration-300"
                onClick={openDropDown}
              >
                <span className="flex pl-1 text-base font-semibold text-black dark:text-white">
                  <span className="pt-1">Upload</span> <FileUploadOutlinedIcon className="ml-1" />
                </span>
                {isDropDown && (
                  <div className="dark:bg-primary-modalgray dark:border-primary-newgray border-primary-gray absolute -ml-1 mt-2 flex flex-col justify-center rounded-lg border-[1px] bg-white py-3 text-black dark:text-white">
                    {isAdmin && folderName == '' && (
                      <div
                        className="dark:hover:bg-primary-newgray hover:bg-primary-gray p-2 px-5"
                        onClick={navigateToLocal}
                      >
                        Local
                      </div>
                    )}
                    <div
                      className="dark:hover:bg-primary-newgray hover:bg-primary-gray p-2 px-5"
                      onClick={navigateToCloud}
                    >
                      Cloud
                    </div>
                  </div>
                )}
              </div>
            )}
            {isShare && folderName != '' && isAllowed && !hasStudyId && (
              <div
                ref={modalRef}
                className="border-primary-gray dark:border-primary-newgray hover:bg-primary-gray dark:hover:bg-primary-darktable dark:bg-primary-newgray relative ml-1 mr-4 cursor-pointer rounded-md border-[1px] p-[2px] px-[3px] pb-[3px] shadow-sm transition duration-300"
                onClick={openDropDown}
              >
                <span className="flex pl-1 text-base font-semibold text-black dark:text-white">
                  <span className="pt-1">Upload</span> <FileUploadOutlinedIcon className="ml-1" />
                </span>
                {isDropDown && (
                  <div className="dark:bg-primary-modalgray dark:border-primary-newgray border-primary-gray absolute -ml-1 mt-2 flex flex-col justify-center rounded-lg border-[1px] bg-white py-3 text-black dark:text-white">
                    <div
                      className="dark:hover:bg-primary-newgray hover:bg-primary-gray p-2 px-5"
                      onClick={navigateToCloud}
                    >
                      Cloud
                    </div>
                  </div>
                )}
              </div>
            )}
            {!isFolderStructure && !isShare ? (
              <>
                <Typography
                  variant="h6"
                  className="!text-primary-light text-[1.15rem]"
                >
                  {`${t('Total Studies')}: `}
                </Typography>
                <Typography
                  variant="h6"
                  className="mr-2 text-[1.15rem] text-black dark:text-white"
                  data-cy={'num-studies'}
                >
                  {numOfStudies}
                </Typography>
                {folderName == '' && (
                  <>
                    <Typography
                      variant="h6"
                      className="!text-primary-light text-[1.15rem]"
                    >
                      {`${t('Buckets')}: `}
                    </Typography>
                    <Typography
                      variant="h6"
                      className="mr-2 text-[1.15rem] text-black dark:text-white"
                      data-cy={'num-studies'}
                    >
                      {numBuckets}
                    </Typography>
                  </>
                )}
              </>
            ) : (
              <>
                <Typography
                  variant="h6"
                  className="!text-primary-light text-[1.15rem]"
                >
                  {`${t('Total Folders')}: `}
                </Typography>
                <Typography
                  variant="h6"
                  className="mr-2 text-[1.15rem] text-black dark:text-white"
                  data-cy={'num-studies'}
                >
                  {folders?.length || 'N/A'}
                </Typography>
              </>
            )}
          </div>
        </div>
        {/* <div>
          {isOpen && (
            <div className="py-4 px-0">
              <DropDownInputGroup
                inputMeta={filtersMeta2}
                values={filterValues}
                onValuesChange={onChange}
                placeHolder="Search..."
                sorting={filterSorting}
                onSortingChange={setFilterSorting}
                isSortingEnabled={isSortingEnabled}
              />
            </div>
          )}
        </div> */}
        {numOfStudies > 100 && (
          <div className="container m-auto hidden">
            <div className="bg-primary-main rounded-b py-1 text-center text-base">
              <p className="text-white">
                {t('Filter list to 100 studies or less to enable sorting')}
              </p>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

StudyListFilter.propTypes = {
  filtersMeta: PropTypes.arrayOf(
    PropTypes.shape({
      /** Identifier used to map a field to its value in `filterValues` */
      name: PropTypes.string.isRequired,
      /** Friendly label for filter field */
      displayName: PropTypes.string.isRequired,
      /** One of the supported filter field input types */
      inputType: PropTypes.oneOf(['Text', 'MultiSelect', 'DateRange', 'None']).isRequired,
      isSortable: PropTypes.bool.isRequired,
      /** Size of filter field in a 12-grid system */
      gridCol: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]).isRequired,
      /** Options for a "MultiSelect" inputType */
      option: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  filterValues: PropTypes.object.isRequired,
  numOfStudies: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  isFiltering: PropTypes.bool.isRequired,
  onUploadClick: PropTypes.func,
  getDataSourceConfigurationComponent: PropTypes.func,
  tableSource: PropTypes.arrayOf(
    PropTypes.shape({
      StudyInstanceUIDs: PropTypes.string.isRequired,
      dataCY: PropTypes.string,
      clickableCY: PropTypes.string,
      row: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          content: PropTypes.node.isRequired,
          gridCol: PropTypes.number.isRequired,
        })
      ).isRequired,
      expandedContent: PropTypes.node,
    })
  ).isRequired,
};

export default StudyListFilter;
