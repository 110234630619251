import React from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { PDFViewer } from '@react-pdf/renderer';
import MyDocument from './PdfViewer';

function ViewPDF({ onClose, tableData, index }) {
  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div className="border-primary-gray dark:bg-primary-modalgray absolute left-[28%] top-[5%] z-50 flex w-[45%] flex-col items-center justify-center rounded-xl border-2 bg-white py-10">
        <PDFViewer
          width="500px"
          height="550px"
        >
          <MyDocument
            tableData={tableData}
            index={index}
          />
        </PDFViewer>
        <div>
          <button
            onClick={onClose}
            className="bg-primary-light hover:bg-primary-darknav mt-5 w-20 rounded-md p-2 font-bold transition duration-300"
          >
            Close
          </button>
        </div>
      </div>
    </OutsideClickHandler>
  );
}

ViewPDF.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ViewPDF;
