import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ThreeSixtyOutlinedIcon from '@mui/icons-material/ThreeSixtyOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ShareIcon from '@mui/icons-material/Share';
import CheckBox from '../CheckBox';
import OutsideClickHandler from 'react-outside-click-handler';
import detectElementOverflow from 'detect-element-overflow';
import { useSelector, useDispatch } from 'react-redux';
import { patient } from '../../../../../platform/app/src/redux/features/studylist/patient';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { Icons } from '@ohif/ui-next';
import { useAppConfig } from '@state';
import axios from 'axios';
import { API, LocalAPI } from '../../../../../constants/baseurl';
import { servicesManager } from '../../../../app/src/App';
import { getSpecificBucket } from '../../../../../utils/bucket';

const StudyListTableRow = ({
  tableData,
  uniqueKey,
  shareModal,
  onOpen,
  pdfModal,
  checked,
  handleChecked,
  selectAll,
  parentRef,
  isScroll,
  length,
  isShare,
  isDemo,
}) => {
  const {
    row,
    //expandedContent,
    onClickRow,
    isExpanded,
    dataCY,
    clickableCY,
    StudyInstanceUIDs,
    modalities,
    study,
  } = tableData;
  const navigate = useNavigate();
  const isSelected = useSelector(state => state.selector.value);
  const isLocal = useSelector(state => state.list.isLocal);
  const folderName = useSelector(state => state.user.selectedFolder);
  const dispatch = useDispatch();
  const [appConfig] = useAppConfig();
  const [routeCount, setRouteCount] = useState(0);
  const routeArray = [
    '-top-[8rem]',
    '-top-[10rem]',
    '-top-[12rem]',
    '-top-[13rem]',
    '-top-[15rem]',
    '-top-[15rem]',
  ];
  const { uiNotificationService } = servicesManager.services;

  const isValidRoute = routeName => {
    return (
      appConfig.groupEnabledModesFirst
        ? appConfig.loadedModes.sort((a, b) => {
            const isValidA = a.isValidMode({
              modalities: modalities.replaceAll('/', '\\'),
              study,
            }).valid;
            const isValidB = b.isValidMode({
              modalities: modalities.replaceAll('/', '\\'),
              study,
            }).valid;

            return isValidB - isValidA;
          })
        : appConfig.loadedModes
    ).some(mode => {
      const modalitiesToCheck = modalities.replaceAll('/', '\\');

      const { valid: isValidMode } = mode.isValidMode({
        modalities: modalitiesToCheck,
        study,
      });

      return isValidMode && mode.routeName == routeName;
    });
  };

  useEffect(() => {
    const count = ['segmentation', 'microscopy', 'dynamic-volume', 'tmtv', 'dentistry'].reduce(
      (acc, routeName) => (isValidRoute(routeName) ? acc + 1 : acc),
      0
    );
    setRouteCount(count);
  }, [modalities, study, appConfig]);

  const [isDicomLocal, setIsDicomLocal] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    // const dicom_local = sessionStorage.getItem('isDicomLocal');
    if (
      params.get('datasources') === 'dicomlocal'
      //  || dicom_local == 'true'
    ) {
      setIsDicomLocal(true);
    }
  }, []);

  const navigateToViewer = mode => {
    dispatch(patient(row));
    navigate(
      `/${mode}${isDicomLocal ? '/dicomlocal' : ''}?StudyInstanceUIDs=${StudyInstanceUIDs}${folderName != '' ? `&folderPath=${encodeURIComponent(`FolderStructure()${folderName}()`)}` : ''}${isLocal == true ? `&PatientName=${encodeURIComponent(study.folderPath)}` : ''}`
    );
  };

  const handleShareClick = () => {
    if (!isShare && !isLocal) {
      setIsOpen(false);
      onOpen(uniqueKey);
      shareModal();
    }
  };

  const handlePdfClick = () => {
    onOpen(uniqueKey);
    pdfModal();
  };

  useEffect(() => {
    if (isScroll === true) {
      setIsOpen(false);
    }
  });

  const [isDropdownAbove, setIsDropdownAbove] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    if (childRef.current && parentRef.current) {
      const parentRect = parentRef.current.getBoundingClientRect();
      const childRect = childRef.current.getBoundingClientRect();

      const spaceAbove = childRect.top - parentRect.top;
      const spaceBelow = parentRect.bottom - childRect.bottom;

      if (length < 11) {
        setIsDropdownAbove(false);
      } else {
        setIsDropdownAbove(spaceBelow < 200 && spaceAbove > spaceBelow);
      }
    }
    setIsOpen(!isOpen);
  };

  const childRef = useRef(null);
  const dropdownRef = useRef(null);

  const [isDropDown, setIsDropDown] = useState(false);

  const closeDropDown = () => {
    setIsOpen(false);
  };

  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeDropDown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleToggle]);

  const downloadStudy = async () => {
    if (!isShare) {
      if (isLocal) {
        try {
          let csvURL = `${LocalAPI}/apilocal/v1/local/download?patientName=${study.studyInstanceUid}`;

          uiNotificationService.show({
            title: 'Download Study',
            message: 'Preparing your download, please wait.',
            type: 'info',
            duration: 3000,
          });

          var tempLink = document.createElement('a');
          tempLink.href = csvURL;
          tempLink.click();
        } catch (error) {
          console.log(error);
        }
      } else {
        const bucketId = localStorage.getItem('bucketId');
        const bucket = await getSpecificBucket(bucketId);
        try {
          let csvURL = `${API}/dicomzip?Bucket=${encodeURIComponent(bucket.bucketName)}&path=${study.studyInstanceUid}&region=us-east-1&accesskey=${encodeURIComponent(
            bucket.accessKeyId
          )}&secretaccesskey=${encodeURIComponent(bucket.secretAccessKey)}`;

          uiNotificationService.show({
            title: 'Download Study',
            message: 'Preparing your download, please wait.',
            type: 'info',
            duration: 3000,
          });

          var tempLink = document.createElement('a');
          tempLink.href = csvURL;
          tempLink.click();
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <tr
      className={`${uniqueKey % 2 === 0 ? 'dark:bg-primary-modalgray bg-white dark:text-white' : 'bg-primary-lightblue dark:bg-primary-darkTable dark:text-white'} ${isOpen === true ? '' : 'hover:bg-primary-tablehover dark:hover:bg-primary-darktable'} select-none transition duration-150`}
      data-cy={dataCY}
      ref={childRef}
    >
      <td className="dark:border-primary-newgray border-primary-lightgray mx-4 border-2 p-2 py-1 pl-4">
        {/* <CheckBox
          checked={checked.find(item => item.index_value === uniqueKey)?.index_state || false}
          onChange={() =>
            handleChecked(
              !checked.find(item => item.index_value === uniqueKey)?.index_state || false,
              uniqueKey
            )
          }
        /> */}
        {isSelected || checked.find(item => item.index_value === uniqueKey)?.index_state ? (
          <div
            onClick={() =>
              handleChecked(
                !checked.find(item => item.index_value === uniqueKey)?.index_state || false,
                uniqueKey
              )
            }
          >
            {checked.find(item => item.index_value === uniqueKey)?.index_state ? (
              <Icons.ByName name="checkbox-checked" />
            ) : (
              <Icons.ByName name="checkbox-unchecked" />
            )}
          </div>
        ) : (
          <div
            onClick={() =>
              handleChecked(
                !checked.find(item => item.index_value === uniqueKey)?.index_state || false,
                uniqueKey
              )
            }
          >
            <Icons.ByName name="checkbox-unchecked" />
          </div>
        )}
      </td>
      <td className="dark:border-primary-newgray border-primary-lightgray mr-4 border-2 p-2 py-1">
        <div className="w-full">{uniqueKey + 1}</div>
      </td>
      {row.map((cell, index) => (
        <td
          key={index}
          className="dark:border-primary-newgray border-primary-lightgray border-2 p-0"
          onClick={onClickRow}
          data-cy={clickableCY}
        >
          <div className="w-full p-2 py-1">
            <div className="flex rounded-3xl">
              <div className="truncate text-lg">{cell.content}</div>
            </div>
          </div>
        </td>
      ))}
      {isLocal && (
        <td className="dark:border-primary-newgray border-primary-lightgray border-2 p-0">
          <div className="w-full p-2 py-1">
            <div className="flex rounded-3xl">
              <div className="truncate text-lg">{study.totalSeries}</div>
            </div>
          </div>
        </td>
      )}
      {isLocal && (
        <td className="dark:border-primary-newgray border-primary-lightgray border-2 p-0">
          <div className="w-full p-2 py-1">
            <div className="flex rounded-3xl">
              <div className="truncate text-lg">{study.instances}</div>
            </div>
          </div>
        </td>
      )}
      <td
        className="dark:border-primary-newgray border-primary-lightgray border-2 p-2 py-1"
        onClick={e => e.preventDefault()}
      >
        <div className="inline-flex cursor-pointer">
          <span
            title="View Study"
            onClick={() => navigateToViewer(modalities == 'SM' ? 'microscopy' : 'viewer')}
          >
            <VisibilityOutlinedIcon className="mr-1" />
          </span>
          <div
            className="relative"
            ref={modalRef}
          >
            <div
              className=""
              onClick={handleToggle}
            >
              <span className="text-lg">
                <MoreVertOutlinedIcon />
              </span>
            </div>

            <div
              ref={dropdownRef}
              style={{ zIndex: 10 }}
              className={`dark:bg-primary-modalgray border-primary-newgray absolute ${isDropdownAbove ? routeArray[routeCount] : ''} -left-[10.5rem] mt-2 w-48 rounded-md border-[1px] bg-white text-left shadow-lg transition-all duration-300 ${
                isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
              }`}
            >
              {isOpen && (
                <div className="cursor-pointer py-4 px-0 text-base">
                  {isValidRoute('segmentation') && (
                    <div
                      className="hover:bg-primary-lightgray dark:hover:bg-primary-newgray px-4 py-1"
                      onClick={() => navigateToViewer('segmentation')}
                    >
                      <CategoryOutlinedIcon fontSize="small" />
                      <span className="ml-2">View Segmentation</span>
                    </div>
                  )}
                  {isValidRoute('dentistry') && (
                    <div
                      className="hover:bg-primary-lightgray dark:hover:bg-primary-newgray flex gap-x-[2px] px-4 py-1"
                      onClick={() => navigateToViewer('dentistry')}
                    >
                      <Icons.ByName
                        name="tooth-dentistry"
                        className="h-5 w-5 text-black dark:text-white"
                      />
                      <span className="ml-2">View Dentistry</span>
                    </div>
                  )}
                  {isValidRoute('microscopy') && (
                    <div
                      className="hover:bg-primary-lightgray dark:hover:bg-primary-newgray px-4 py-1"
                      onClick={() => navigateToViewer('microscopy')}
                    >
                      <BiotechOutlinedIcon fontSize="small" />
                      <span className="ml-2">View Microscopy</span>
                    </div>
                  )}
                  {isValidRoute('dynamic-volume') && (
                    <div
                      className="hover:bg-primary-lightgray dark:hover:bg-primary-newgray px-4 py-1"
                      onClick={() => navigateToViewer('dynamic-volume')}
                    >
                      <ThreeSixtyOutlinedIcon fontSize="small" />
                      <span className="ml-2">View 4D</span>
                    </div>
                  )}
                  {isValidRoute('tmtv') && (
                    <div
                      className="hover:bg-primary-lightgray dark:hover:bg-primary-newgray px-4 py-1"
                      onClick={() => navigateToViewer('tmtv')}
                    >
                      <AssessmentOutlinedIcon fontSize="small" />
                      <span
                        className="ml-2"
                        title="Total Metabolic Tumor Volume"
                      >
                        View TMTV
                      </span>
                    </div>
                  )}

                  <hr className="bg-primary-darktable mx-4 my-3" />
                  <div
                    className="hover:bg-primary-lightgray dark:hover:bg-primary-newgray flex justify-between px-4 py-1"
                    onClick={() => downloadStudy()}
                  >
                    <div>
                      <FileDownloadOutlinedIcon fontSize="small" />
                      <span className="ml-2">Download Study</span>
                    </div>
                    {(isShare || isDemo) && (
                      <span title="Service Locked">
                        <LockPersonIcon fontSize="small" />
                      </span>
                    )}
                  </div>
                  <div
                    className="hover:bg-primary-lightgray dark:hover:bg-primary-newgray flex justify-between px-4 py-1"
                    onClick={handleShareClick}
                  >
                    <div>
                      <ShareIcon fontSize="small" />
                      <span className="ml-2">Share</span>
                    </div>
                    {(isShare || isDemo || isLocal) && (
                      <span title="Service Locked">
                        <LockPersonIcon fontSize="small" />
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

StudyListTableRow.propTypes = {
  onOpen: PropTypes.func.isRequired,
  shareModal: PropTypes.func.isRequired,
  tableData: PropTypes.shape({
    row: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        content: PropTypes.node,
        title: PropTypes.string,
        gridCol: PropTypes.number.isRequired,
      })
    ).isRequired,
    expandedContent: PropTypes.node.isRequired,
    onClickRow: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    dataCY: PropTypes.string,
    clickableCY: PropTypes.string,
    StudyInstanceUIDs: PropTypes.string.isRequired,
  }).isRequired,
  uniqueKey: PropTypes.number.isRequired,
  checked: PropTypes.arrayOf(
    PropTypes.shape({
      index_value: PropTypes.number.isRequired,
      index_state: PropTypes.bool.isRequired,
    })
  ).isRequired,
  handleChecked: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
};

export default StudyListTableRow;
