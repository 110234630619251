import React from 'react';
import type { IconProps } from '../types';

export const Sharp = (props: IconProps) => (
  <svg
    height="64"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="#e21b1b"
      d="M0 121.32 184.152 15.544l184.16 105.776v211.544L184.152 438.64 0 332.864z"
    />
    <path
      fill="currentColor"
      d="M346.84 496.456 512 211.024H181.672z"
    />
  </svg>
);

export const Dull = (props: IconProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    stroke="white"
    y="0px"
    fill="currentColor"
    viewBox="-43 68 689.1 546.5"
    {...props}
  >
    <style type="text/css"></style>
    <path
      d="M117.2,277.4C116.7,158,215.7,57.4,338.5,57.2c121.7-0.2,222,98,222.3,222.1c0.3,122.1-100.7,224-226.8,221.4
	C214.6,498.3,116,400,117.2,277.4z M339,89.8c0,126.7,0,252.1,0,377.4c49.7,2.2,120.2-22,160.3-88.8c40.4-67.2,39.5-136.1-2.4-202.1
	C460.8,119.4,406.6,92,339,89.8z"
    />
  </svg>
);
