import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ThemeToggle = ({
  lightModeColor = '#000000',
  lightModeHover = '#292828',
  darkModeColor = '#FFFFFF',
  darkModeHover = '#e6e6e6',
  animationDuration = '.25s',
  animationDelay = '.25s',
  size = '20px',
  title = 'Toggle dark mode',
  themeOption,
}) => {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('dark-mode');
    return savedMode === 'false' ? false : true;
  });

  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    themeOption();
  };

  return (
    <button
      className={`theme-toggle -mt-[5px] ${darkMode ? 'dark-mode' : ''}`}
      title={title}
      style={{
        '--hoverColor': darkMode ? darkModeHover : lightModeHover,
        '--animationDuration': animationDuration,
        '--animationDelay': animationDelay,
      }}
      onClick={toggleDarkMode}
    >
      <svg
        className="sun-and-moon"
        aria-hidden="true"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <mask
          className="moon"
          id="moon-mask"
        >
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="white"
          />
          <circle
            cx="24"
            cy="10"
            r="6"
            fill="black"
          />
        </mask>
        <circle
          className="sun"
          cx="12"
          cy="12"
          r="5"
          mask="url(#moon-mask)"
          fill={darkMode ? darkModeColor : lightModeColor}
        />
        <g
          className="sun-beams"
          stroke={lightModeColor}
          strokeWidth="2px"
        >
          <line
            x1="12"
            y1="1"
            x2="12"
            y2="4"
          />
          <line
            x1="17.6568"
            y1="6.3431"
            x2="19.7781"
            y2="4.2218"
          />
          <line
            x1="20"
            y1="12"
            x2="23"
            y2="12"
          />
          <line
            x1="17.6568"
            y1="17.6568"
            x2="19.7781"
            y2="19.7781"
          />
          <line
            x1="12"
            y1="20"
            x2="12"
            y2="23"
          />
          <line
            x1="6.3431"
            y1="17.6568"
            x2="4.2218"
            y2="19.7781"
          />
          <line
            x1="1"
            y1="12"
            x2="4"
            y2="12"
          />
          <line
            x1="6.3431"
            y1="6.3431"
            x2="4.2218"
            y2="4.2218"
          />
        </g>
      </svg>

      <style jsx>{`
        .theme-toggle {
          border: 0;
          background-color: transparent;
        }
        .sun-and-moon > :is(.moon, .sun, .sun-beams) {
          transform-origin: center;
        }
        .theme-toggle:is(:hover, :focus-visible) > .sun-and-moon > :is(.moon, .sun) {
          fill: var(--hoverColor);
        }
        .theme-toggle:is(:hover, :focus-visible) .sun-and-moon > .sun-beams {
          stroke: var(--hoverColor);
        }
        .sun {
          transition: transform calc(var(--animationDuration) * 2)
            cubic-bezier(0.5, 1.25, 0.75, 1.25);
        }
        .dark-mode .sun {
          transition-timing-function: cubic-bezier(0.25, 0, 0.3, 1);
          transition-duration: var(--animationDuration);
          transform: scale(1.75);
        }
        .sun-beams {
          stroke-linecap: round;
          transition:
            transform 0.5s ease,
            opacity 0.5s cubic-bezier(0.5, 1.5, 0.75, 1.25);
        }
        .dark-mode .sun-beams {
          transition-duration: var(--animationDuration);
          transform: rotateZ(-25deg);
          opacity: 0;
        }
        .moon > circle {
          transition: transform var(--animationDuration) cubic-bezier(0, 0, 0, 1);
        }
        .dark-mode .moon > circle {
          transition: transform var(--animationDuration) cubic-bezier(0, 0, 0, 1);
          transition-delay: var(--animationDelay);
          transform: translateX(-7px);
        }
      `}</style>
    </button>
  );
};

ThemeToggle.propTypes = {
  themeOption: PropTypes.func,
};

export default ThemeToggle;
