import React, { useState } from 'react';
import Card from './cards';
export default function PayGo() {
  const [activeTab, setActiveTab] = useState('Go');

  const monthly = [
    {
      id: 'without',
      title: 'Without Your Own Storage',
      subtitle: '',
      features: [
        'Cloud Storage: 100GB',
        'Number of Shareable Links: 70 per month',
        'Radiologist Accounts: 1',
        'Supported Locations: 2',
        'Customer Support: Basic',
        'Add-On services: All available',
      ],
      price: '$1.5 / per case',
      subprice: 'includes',
    },
    {
      id: 'with',
      title: 'With Your Own Storage',
      subtitle: '',
      features: [
        'Cloud Storage: 500GB',
        'Number of Shareable Links: 250 per month',
        'Radiologist Accounts: 3',
        'Supported Locations: 3',
        'Customer Support: Priority',
        'Add-On services: All Available',
      ],
      price: '$0.9 / per case',
      subprice: 'includes',
    },
  ];

  const tabs = [
    {
      id: 'Go',
      cards: monthly,
    },
  ];

  const activeTabDetails = tabs.find(tab => tab.id === activeTab);

  return (
    <div className="mt-2 flex flex-col items-center justify-center">
      <div className="mt-3 flex w-[1045px] items-start justify-start text-white">
        <Card
          cards={activeTabDetails.cards}
          tab="paygo"
        />
      </div>
    </div>
  );
}
