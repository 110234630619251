import React from 'react';
import type { IconProps } from '../types';

export const PrintDCM = (props: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M8 3h8a1 1 0 0 1 1 1v3H7V4a1 1 0 0 1 1-1"
      stroke="currentColor"
      fill="currentColor"
    />
    <path
      data-name="primary"
      d="M17 17h3a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h3"
      stroke="currentColor"
      fill="transparent"
    />
    <path
      d="M17 12v8a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-8z"
      stroke="#db5860"
      fill="transparent"
    />
  </svg>
);

export default PrintDCM;
