import { createSlice } from '@reduxjs/toolkit';

export const packagesSlice = createSlice({
  name: 'packages',
  initialState: {
    value: { id: '', title: '', subtitle: '', price: '', type: '' },
  },
  reducers: {
    packages: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { packages } = packagesSlice.actions;

export default packagesSlice.reducer;
