import axios from 'axios';
import api_constants from '../../constants/urls';

const updateservices = async (id, services) => {
  const url = api_constants.UPDATE_SERVICES;
  const params = {
    id: id,
    services: services,
  };

  try {
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/sign-in';
      return;
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data || error.response.statusText);
    } else if (error.request) {
      throw new Error('No response received from server');
    } else {
      throw new Error(error.message);
    }
  }
};

export default updateservices;
