import React, { useEffect, useState } from 'react';
import { servicesManager } from '../App';
import { PanoAPI, DentistrySegAPI } from '../../../../constants/baseurl';
import axios from 'axios';
import { Icons } from '@ohif/ui-next';
import { getBase64 } from '../../../../utils/bucket';

function EmptyViewport() {
  const { hangingProtocolService, uiNotificationService } = servicesManager.services;
  const urlParams = new URLSearchParams(window.location.search);
  const patientName = urlParams.get('PatientName');

  const [isPano, setIsPano] = useState(false);
  const [panoURL, setPanoURL] = useState('');
  const [panoImages, setPanoImages] = useState([]);
  const [isSharp, setIsSharp] = useState(false);
  const [segmentedImage, setSegmentedImage] = useState(null);
  const [showSegmented, setShowSegmented] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDentistryFour, setDentistryFour] = useState(false);

  const getPano = async () => {
    setIsPano(true);
    try {
      const isXRAD =
        window.location.href.includes('/xrad/') || window.location.href.includes('/radx/');
      const urlParams = new URLSearchParams(window.location.search);
      const bucketIdXrad = urlParams.get('bucketId');
      const bucketId = isXRAD ? bucketIdXrad : localStorage.getItem('bucketId');
      const base64 = await getBase64(bucketId);
      const StudyInstanceUID = urlParams.get('StudyInstanceUIDs');
      const isLocal = patientName?.includes(StudyInstanceUID) || false;
      const response = isLocal
        ? await axios.get(
            `${PanoAPI}/GetPano_local?patient_name=${encodeURIComponent(patientName)}&suid=${StudyInstanceUID}`
          )
        : await axios.get(
            `${PanoAPI}/get_pano?base64=${base64}&StudyInstanceUID=${StudyInstanceUID}&region=us-east-1`
          );
      if (response.status === 200 && response.data) {
        setPanoImages(response.data.pano_image);
        setPanoURL(response.data.pano_image[0]);
      } else {
        setPanoURL(null);
      }
    } catch (err) {
      setPanoURL(null);
      console.error(err);
    }
  };

  useEffect(() => {
    const hpInfo = hangingProtocolService.getState();
    if (hpInfo.protocolId === 'dentistryFour') {
      getPano();
      setDentistryFour(true);
    }
  }, []);

  useEffect(() => {
    if (panoURL && isPano) {
      setPanoURL(isSharp ? panoImages[1] : panoImages[0]);
    }
  }, [isSharp]);

  useEffect(() => {
    if (panoURL == null) {
      uiNotificationService.show({
        title: 'Panoramic View',
        message: 'Panoramic View is not available for this dataset.',
        type: 'warning',
        duration: 3000,
      });
    }
  }, [panoURL]);

  const handleAI = async () => {
    if (!panoURL) return;

    setIsLoading(true);

    try {
      const blob = await fetch(panoURL).then(res => res.blob());
      const formData = new FormData();
      formData.append('image', blob, 'pano_image.png');
      const response = await axios.post(`${DentistrySegAPI}/pano_inference`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setSegmentedImage(response.data.image);
        setShowSegmented(true);
      }
    } catch (error) {
      console.error('Error while processing image:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleImage = () => {
    setShowSegmented(!showSegmented);
  };

  return (
    <div className="h-full">
      {isDentistryFour && (
        <>
          {isPano && panoURL && (
            <div style={{ position: 'relative' }}>
              <img
                src={showSegmented && segmentedImage ? segmentedImage : panoURL}
                alt="Panoramic View"
                style={{ width: '100%' }}
              />
              <span
                title="AI Segmentation"
                className="absolute right-3 top-2 h-5 w-5 cursor-pointer"
                onClick={segmentedImage ? toggleImage : handleAI}
              >
                <Icons.ByName
                  name="ai-segmentation"
                  className={`h-5 w-5`}
                />
              </span>
              <span
                title="Toggle Sharp Image"
                className="absolute right-8 top-3 h-8 w-8 cursor-pointer"
                onClick={() => setIsSharp(!isSharp)}
              >
                <Icons.ByName
                  name="pano-sharp"
                  className={`h-5 w-5 ${isSharp ? 'text-primary-light' : 'text-black'}`}
                />
              </span>
            </div>
          )}
          {panoURL === null ? (
            <p className="flex h-full items-center justify-center text-black dark:text-white">
              Failed to load. Dataset isn't compatible with Panoramic View.
            </p>
          ) : (
            <p className="flex h-full items-center justify-center text-black dark:text-white">
              Loading...
            </p>
          )}
        </>
      )}
    </div>
  );
}

export default EmptyViewport;
