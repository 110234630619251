import React, { useEffect, useState } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import EmailIcon from '@mui/icons-material/Email';
import darklogo from './assets/svgs/dcmcloud-logos-dark.svg';
import signin from './apis/signin/signin';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import getToken from './apis/signin/getToken';
import { servicesManager } from '../../../../app/src/App';
import { useDispatch } from 'react-redux';
import { setSelectedFolder } from '../../../../../platform/app/src/redux/features/studylist/userlist';

function SignIn() {
  const [email, setEmail] = useState('userdemo@gmail.com');
  const [password, setPassword] = useState('G8v!zQp#2Xr$MdT9');
  const [errors, setErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const validateEmail = value => value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);
  const [isLoading, setIsLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const [share, setShare] = useState(false);
  const [studyInstanceUid, setStudyInstanceUid] = useState('');
  const [isFolder, setIsFolder] = useState(false);
  const [shareData, setShareData] = useState({});
  const [deidentified, setDeidentified] = useState(false);
  const [expDate, setExpDate] = useState('');
  const [bucket, setBucket] = useState('');
  const [isAllowChecked, setIsAllowChecked] = useState('');

  const { uiNotificationService } = servicesManager.services;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of searchParams) {
      params[key] = value.replace(/ /g, '+');
    }

    const secretKey = 'w7TBiRfSE9HXRWPvmvM5';
    if (
      params['share'] == 'true' &&
      params['d1'] &&
      params['d3'] &&
      params['d4'] &&
      params['d5'] &&
      params['d6'] &&
      params['d7'] &&
      params['d8']
    ) {
      setShare(true);

      const modifiedToken = params['d2'].replace(/bbbb/g, '/');
      const modifiedpass = params['d3'].replace(/bbbb/g, '/');
      const modifiedDate = params['d4'].replace(/bbbb/g, '/');
      const modifiedId = params['d6'].replace(/bbbb/g, '/');
      const modifiedBucket = params['d7'].replace(/bbbb/g, '/');

      const decryptedFolderBytes = CryptoJS.AES.decrypt(modifiedToken, secretKey);
      const decryptedFolder = decryptedFolderBytes.toString(CryptoJS.enc.Utf8);

      const decryptedPassBytes = CryptoJS.AES.decrypt(modifiedpass, secretKey);
      const decryptedPass = decryptedPassBytes.toString(CryptoJS.enc.Utf8);
      const decryptedDateBytes = CryptoJS.AES.decrypt(modifiedDate, secretKey);
      const decryptedDate = decryptedDateBytes.toString(CryptoJS.enc.Utf8);
      const decryptedIdBytes = CryptoJS.AES.decrypt(modifiedId, secretKey);
      const decryptedId = decryptedIdBytes.toString(CryptoJS.enc.Utf8);
      const decryptedBucketBytes = CryptoJS.AES.decrypt(modifiedBucket, secretKey);
      const decryptedBucket = decryptedBucketBytes.toString(CryptoJS.enc.Utf8);

      const currentDate = new Date().toISOString().split('T')[0];
      const isPastDate = decryptedDate < currentDate;

      if (isPastDate === true) {
        uiNotificationService.show({
          title: 'Share Case',
          message: 'Share Link has been expired.',
          type: 'error',
          duration: 3000,
        });

        window.location.href = '/sign-in';
      }

      setExpDate(decryptedDate);
      setEmail(decryptedFolder);
      setPassword(decryptedPass);
      setIsAllowChecked(params['d8']);
      setDeidentified(params['d5']);
      setBucket(decryptedBucket);

      if (params['d0'] == 'true') {
        setIsFolder(true);
        const modifiedStudyId = params['d1'].replace(/bbbb/g, '/');
        const decryptedStudyIdBytes = CryptoJS.AES.decrypt(modifiedStudyId, secretKey);
        const decryptedStudyId = decryptedStudyIdBytes.toString(CryptoJS.enc.Utf8);
        setEmail(decryptedStudyId);
      } else {
        setStudyInstanceUid(params['d1']);
      }

      setShareData({
        _id: decryptedId,
        firstName: 'Share',
        lastName: 'Case',
        isShared: 'true',
        email: 'share@dcmcloud.com',
        phone: 9211111111111,
        password: 'N/A',
        storageService: 'aws',
        storageLocation: 'us-east-1',
        count: '10',
        files_count: 17261,
        monthly: false,
        annual: false,
        __v: 6,
        stripeId: 'free',
        admin: 'false',
        lastLogin: '2000-12-30T04:45:23.114Z',
        cases_count: 9999,
        clientapptemplate: false,
        deidentification: false,
        imageArchieve: false,
        imageconvertor: false,
        sharingwithusers: false,
        threedimagerendering: false,
        preferences: [],
      });
    }
  }, []);

  const validateForm = () => {
    const newErrors = {};

    if (!email.trim()) newErrors.email = 'Email is missing';
    if (!password.trim()) newErrors.password = 'Password is missing';
    if (!validateEmail(email)) newErrors.emailInvalid = 'Email is not valid';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const handleSignIn = async e => {
    e.preventDefault();
    setIsLoading(true);
    if (share == true) {
      const tokenData = await getToken(expDate);
      localStorage.setItem('token', tokenData.token);
      localStorage.setItem('share', share);
      if (!isFolder) {
        localStorage.setItem('shareUid', studyInstanceUid);
      }
      localStorage.setItem('deIdentified', deidentified);
      localStorage.setItem('user_data', JSON.stringify(shareData));
      localStorage.setItem('bucketId', bucket);
      localStorage.setItem('is_allowed', isAllowChecked);
      dispatch(setSelectedFolder(email));
      setTimeout(() => {
        navigate(`/`);
      }, 1000);
      setIsLoading(false);
    } else if (validateForm()) {
      signin(email, password)
        .then(response => {
          if (!response.error) {
            localStorage.setItem('token', response.token);
            localStorage.setItem('user_data', JSON.stringify(response.data));
            uiNotificationService.show({
              title: 'Authentication',
              message: 'Successfully signed in.',
              type: 'success',
              duration: 3000,
            });
            setTimeout(() => {
              window.location.href = '/';
            }, 500);
          } else {
            uiNotificationService.show({
              title: 'Authentication',
              message: 'Email or Password is incorrect.',
              type: 'error',
              duration: 3000,
            });
            //setErrorResponse(true);
          }
        })
        .catch(error => {
          console.error(error);
          uiNotificationService.show({
            title: 'Authentication',
            message: 'Encountered an error while signing in.',
            type: 'error',
            duration: 3000,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const spinner = () => {
    return (
      <div
        className={`border-t-primary-light mr-2 h-4 w-4 animate-spin rounded-full border-[2px] border-t-[3px] border-solid`}
      ></div>
    );
  };

  const signinForm = () => {
    return (
      <form
        onSubmit={handleSignIn}
        className="from-primary-signcard to-primary-signcarddark relative mt-5 flex w-72 flex-col items-center rounded-xl bg-gradient-to-r shadow-xl sm:w-96 lg:w-[25rem]"
      >
        <span className="mt-6 text-xl text-white">Sign In</span>
        {share == false && (
          <div className="flex flex-col">
            <div
              className={`mt-7 flex w-64 border-b-2 py-2 pl-2 transition duration-300 lg:w-80 ${errors.email || errors.emailInvalid ? 'border-red-500' : 'border-primary-gray hover:border-primary-signactivedark'}`}
            >
              <EmailIcon className="text-white" />
              <input
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none autofill:bg-none"
              />
            </div>
            {errors.email && <p className="mt-2 text-sm text-red-500">{errors.email}</p>}
            {errors.emailInvalid && (
              <p className="mt-2 text-sm text-red-500">{errors.emailInvalid}</p>
            )}
          </div>
        )}
        <div className="flex flex-col">
          <div
            className={`${errors.password ? 'border-red-500' : 'border-primary-gray hover:border-primary-signactivedark'} mt-8 flex w-64 border-b-2 p-2 transition duration-300 lg:w-80`}
          >
            <LockIcon className="text-white" />
            <input
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
            />
            <button
              type="button"
              onClick={() => setPasswordVisible(!passwordVisible)}
              className="focus:outline-none"
            >
              {passwordVisible ? (
                <VisibilityOffOutlinedIcon className="text-gray-500" />
              ) : (
                <VisibilityOutlinedIcon className="text-gray-500" />
              )}
            </button>
          </div>
          {errors.password && <p className="mt-2 text-sm text-red-500">{errors.password}</p>}
          {errorResponse == true && (
            <p className="mt-2 text-sm text-red-500">Email or Password is incorrect</p>
          )}
        </div>
        <div className="mt-3 flex w-64 items-center justify-between lg:w-80">
          <div className="mt-7 flex items-start justify-start">
            <div
              className={`mt-1 h-4 w-4 border border-white transition duration-150 ${isChecked ? 'bg-white' : 'bg-transparent'}`}
              onClick={toggleCheckbox}
              style={{ cursor: 'pointer' }}
            >
              <svg
                className={`h-full w-full transition duration-150 ${isChecked ? 'scale-100' : 'scale-0'}`}
                fill="none"
                stroke="black"
                strokeWidth="3"
                viewBox="0 0 24 24"
              >
                <path d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <label
              className="ml-2 mt-[3px] cursor-pointer text-sm text-white"
              onClick={toggleCheckbox}
            >
              Remember Me
            </label>
          </div>
          {/* <span className="mt-7 cursor-pointer text-sm italic text-white">Forgot Password?</span> */}
        </div>
        <button
          type="submit"
          onClick={handleSignIn}
          className="from-primary-signactivedark to-primary-signactivelight mb-5 mt-5 flex w-28 cursor-pointer items-center justify-center rounded-xl bg-gradient-to-r p-1 text-sm text-white transition duration-300 hover:scale-105"
        >
          {isLoading == true && spinner()}
          Sign In
        </button>
        <div className="mb-5 flex items-center justify-center text-sm text-white">
          <span>Not a member?</span>
          <span
            className="text-primary-light ml-1 cursor-pointer hover:underline"
            onClick={() => navigate('/sign-up')}
          >
            Sign Up
          </span>
        </div>
      </form>
    );
  };

  return (
    <div
      className="flex h-screen flex-col items-center justify-center overflow-y-auto"
      style={{
        background:
          'radial-gradient(ellipse, rgba(37,58,86,1) 0%, rgba(18,23,51,1) 50%, rgba(20,20, 33,1) 75%)',
      }}
    >
      <img
        src={darklogo}
        alt="logo-dark"
        className="h-24 w-56"
      />
      <div className="flex items-center justify-center">{signinForm()}</div>
    </div>
  );
}

export default SignIn;
