import React, { useState } from 'react';
import Card from './cards';
export default function Plan() {
  const [activeTab, setActiveTab] = useState('Monthly');

  const monthly = [
    {
      id: 'basic-imaging',
      title: 'Basic',
      subtitle: 'Imaging Essentials',
      features: [
        'Cloud Storage: 100GB',
        'Number of Shareable Links: 70 per month',
        'Radiologist Accounts: 1',
        'Supported Locations: 2',
        'Customer Support: Basic',
        'Add-On services: All available',
      ],
      price: '$89.99 / month',
      subprice: '',
      type: 'Monthly',
    },
    {
      id: 'standard-imaging',
      title: 'Standard',
      subtitle: 'Imaging Expertise',
      features: [
        'Cloud Storage: 500GB',
        'Number of Shareable Links: 250 per month',
        'Radiologist Accounts: 3',
        'Supported Locations: 3',
        'Customer Support: Priority',
        'Add-On services: All Available',
      ],
      price: '$149.99 / month',
      subprice: '',
      type: 'Monthly',
    },
    {
      id: 'premium-imaging',
      title: 'Premium',
      subtitle: 'Imaging Unlimited',
      features: [
        'Cloud Storage: 2TB',
        'Number of Shareable Links: Unlimited',
        'Radiologist Accounts: Up to 10',
        'Supported Locations: Unlimited',
        'Customer Support: Dedicated',
        'Add-On services: All Available',
      ],
      price: '$499.99 / month',
      subprice: '',
      type: 'Monthly',
    },
  ];

  const yearly = [
    {
      id: 'basic-imaging-year',
      title: 'Basic',
      subtitle: 'Imaging Essentials',
      features: [
        'Cloud Storage: 100GB',
        'Number of Shareable Links: 70 per year',
        'Radiologist Accounts: 1',
        'Supported Locations: 2',
        'Customer Support: Basic',
        'Add-On services: All available',
      ],
      price: '$1079.88 / per year',
      subprice: 'with 5% discount',
      type: 'Yearly',
    },
    {
      id: 'standard-imaging-year',
      title: 'Standard',
      subtitle: 'Imaging Expertise',
      features: [
        'Cloud Storage: 500GB',
        'Number of Shareable Links: 250 per year',
        'Radiologist Accounts: 3',
        'Supported Locations: 3',
        'Customer Support: Priority',
        'Add-On services: All Available',
      ],
      price: '$1799.88 / per year',
      subprice: 'with 10% discount',
      type: 'Yearly',
    },
    {
      id: 'premium-imaging-year',
      title: 'Premium',
      subtitle: 'Imaging Unlimited',
      features: [
        'Cloud Storage: 2TB',
        'Number of Shareable Links: Unlimited',
        'Radiologist Accounts: Up to 10',
        'Supported Locations: Unlimited',
        'Customer Support: Dedicated',
        'Add-On services: All Available',
      ],
      price: '$5999.88 / per year',
      subprice: 'with 10% discount',
      type: 'Yearly',
    },
  ];

  const tabs = [
    {
      id: 'Monthly',
      label: 'Monthly',
      cards: monthly,
    },
    {
      id: 'Yearly',
      label: 'Yearly',
      cards: yearly,
    },
  ];

  const activeTabDetails = tabs.find(tab => tab.id === activeTab);

  return (
    <div className="mt-2 flex flex-col items-center justify-center">
      <div className="flex items-start justify-start text-white md:w-[700px] lg:w-[1045px]">
        {tabs.map(tab => (
          <div
            key={tab.id}
            className={`ml-2 cursor-pointer rounded-3xl px-5 text-sm transition duration-300 first:ml-0 hover:scale-105 ${
              activeTab === tab.id
                ? 'from-primary-signactivedark to-primary-signactivelight bg-gradient-to-r p-1'
                : 'border-primary-darktable border-2 p-[2px]'
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="mt-3 flex items-start justify-start text-white md:w-[700px] lg:w-[1045px]">
        <Card
          cards={activeTabDetails.cards}
          tab="plan"
        />
      </div>
    </div>
  );
}
