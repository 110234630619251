import React from 'react';
import type { IconProps } from '../types';

export const TabSegmentation = (props: IconProps) => (
  // <svg
  //   width="22"
  //   height="22"
  //   viewBox="0 0 22 22"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     fill="none"
  //     fillRule="evenodd"
  //   >
  //     <circle
  //       stroke="currentColor"
  //       cx="11.037"
  //       cy="10.912"
  //       r="8"
  //     />
  //     <g stroke="currentColor">
  //       <path
  //         strokeLinecap="square"
  //         d="m11.354 3.575-7.779 7.779M17.364 6.757 6.757 17.364"
  //       />
  //       <path d="m18.955 9.763-9.192 9.192" />
  //       <path
  //         strokeLinecap="square"
  //         d="M15.066 4.46 4.459 15.065"
  //       />
  //     </g>
  //     <path d="M0 0h22v22H0z" />
  //   </g>
  // </svg>
  <svg
    viewBox="-9 -10 68.011 68.011"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.295 47.708a1 1 0 0 1-.243-1.027L5.02 33.78a1 1 0 0 1 .242-.392L37.21 1.465a5.036 5.036 0 0 1 7.1 0l2.231 2.252a5.027 5.027 0 0 1 0 7.1l-4.922 4.919q-.022.026-.047.05l-.051.048-2.73 2.728q-.027.033-.058.063t-.064.058L14.597 42.74a1 1 0 0 1-.392.242L1.32 47.951a1 1 0 0 1-1.028-.243Zm2.3-2.3 4.27-1.691-2.588-2.562Zm5.295-2.1 5.46-2.162 23.258-23.241-6.494-6.51-23.257 23.24-2.175 5.5Zm30.13-26.817 1.42-1.419-6.493-6.511-1.42 1.419Zm2.841-2.838 4.26-4.256a3.02 3.02 0 0 0 0-4.262l-2.23-2.251a3.1 3.1 0 0 0-4.26 0l-4.262 4.259Zm-10.2 14.994 13-13 .707.706-13 13Z"
      fill="currentColor"
    />
  </svg>
);

export default TabSegmentation;
