import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { servicesManager } from '../../../../app/src/App';
import { API } from '../../../../../constants/baseurl';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';

function ShareModal({ onClose, tableData, index, shareFolder = '' }) {
  const { StudyInstanceUIDs } = tableData[index];
  const currentUrl = window.location.origin;

  const folderName = useSelector(state => state.user.selectedFolder);

  const { uiNotificationService } = servicesManager.services;

  const [showQR, setShowQR] = useState(false);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('user_data')));

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [date, setDate] = useState('');

  const [receiverEmail, setReceiverEmail] = useState('');
  const [subject, setSubject] = useState('');

  const [urlSafeEmail, setUrlSafeEmail] = useState('');
  const [urlSafePass, setUrlSafePass] = useState('');
  const [urlSafeDate, setUrlSafeDate] = useState('');
  const [urlSafeidentify, setUrlSafeidentify] = useState(false);
  const [onEmail, setOnEmail] = useState(false);
  const [tinyUrl, setTinyUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const [isChecked, setChecked] = useState(false);

  const secretKey = 'w7TBiRfSE9HXRWPvmvM5';

  const [url, setUrl] = useState(
    `${currentUrl}/sign-in?share=true&d1=${StudyInstanceUIDs == 'undefined' && shareFolder != '' ? '' : StudyInstanceUIDs}&d2=${urlSafeEmail}&d3=${urlSafePass}&d4=${urlSafeDate}`
  );

  const onChangeField = event => {
    setLoading(true);
    const value = event.target.value;
    const name = event.target.name;
    const type = event.target.type;

    let encryptedEmail;
    let encryptedPass;
    let encryptedDate;
    let encryptedId = CryptoJS.AES.encrypt(userData._id, secretKey).toString();
    let urlSafePId = encryptedId.replace(/\//g, 'bbbb');

    let uid = StudyInstanceUIDs + '&d0=false';

    let tinyCurrentUrl = url;

    const bucketId = localStorage.getItem('bucketId');
    let encryptedBucketId = bucketId;

    let encryptedbucket = CryptoJS.AES.encrypt(encryptedBucketId, secretKey).toString();
    encryptedBucketId = encryptedbucket.replace(/\//g, 'bbbb');

    if (StudyInstanceUIDs == 'undefined' && shareFolder != '') {
      let encryptedUid = CryptoJS.AES.encrypt(shareFolder, secretKey).toString();
      let urlSafeUid1 = encryptedUid.replace(/\//g, 'bbbb');
      uid = urlSafeUid1 + '&d0=true';
    }
    if (folderName !== '') {
      setEmail(folderName);
      encryptedEmail = CryptoJS.AES.encrypt(folderName, secretKey).toString();
      let urlSafeEmail1 = encryptedEmail.replace(/\//g, 'bbbb');
      setUrlSafeEmail(urlSafeEmail1);
      setUrl(
        `${currentUrl}/sign-in?share=true&d1=${uid}&d2=${urlSafeEmail1}&d3=${urlSafePass}&d4=${urlSafeDate}&d5=${urlSafeidentify}&d6=${urlSafePId}&d7=${encryptedBucketId}&d8=${isChecked}`
      );
      tinyCurrentUrl = `${currentUrl}/sign-in?share=true&d1=${uid}&d2=${urlSafeEmail1}&d3=${urlSafePass}&d4=${urlSafeDate}&d5=${urlSafeidentify}&d6=${urlSafePId}&d7=${encryptedBucketId}&d8=${isChecked}`;
    }
    if (type === 'password') {
      setPassword(value);
      encryptedPass = CryptoJS.AES.encrypt(value, secretKey).toString();
      let urlSafePass1 = encryptedPass.replace(/\//g, 'bbbb');
      setUrlSafePass(urlSafePass1);
      setUrl(
        `${currentUrl}/sign-in?share=true&d1=${uid}&d2=${urlSafeEmail}&d3=${urlSafePass1}&d4=${urlSafeDate}&d5=${urlSafeidentify}&d6=${urlSafePId}&d7=${encryptedBucketId}&d8=${isChecked}`
      );
      tinyCurrentUrl = `${currentUrl}/sign-in?share=true&d1=${uid}&d2=${urlSafeEmail}&d3=${urlSafePass1}&d4=${urlSafeDate}&d5=${urlSafeidentify}&d6=${urlSafePId}&d7=${encryptedBucketId}&d8=${isChecked}`;
    }
    if (type === 'date') {
      setDate(value);
      encryptedDate = CryptoJS.AES.encrypt(value, secretKey).toString();
      let urlSafeDate1 = encryptedDate.replace(/\//g, 'bbbb');
      setUrlSafeDate(urlSafeDate1);
      setUrl(
        `${currentUrl}/sign-in?share=true&d1=${uid}&d2=${urlSafeEmail}&d3=${urlSafePass}&d4=${urlSafeDate1}&d5=${urlSafeidentify}&d6=${urlSafePId}&d7=${encryptedBucketId}&d8=${isChecked}`
      );
      tinyCurrentUrl = `${currentUrl}/sign-in?share=true&d1=${uid}&d2=${urlSafeEmail}&d3=${urlSafePass}&d4=${urlSafeDate1}&d5=${urlSafeidentify}&d6=${urlSafePId}&d7=${encryptedBucketId}&d8=${isChecked}`;
    }
    if (name === 'De-identify') {
      setUrlSafeidentify(event.target.checked);
      setUrl(
        `${currentUrl}/sign-in?share=true&d1=${uid}&d2=${urlSafeEmail}&d3=${urlSafePass}&d4=${urlSafeDate}&d5=${event.target.checked}&d6=${urlSafePId}&d7=${encryptedBucketId}&d8=${isChecked}`
      );
      tinyCurrentUrl = `${currentUrl}/sign-in?share=true&d1=${uid}&d2=${urlSafeEmail}&d3=${urlSafePass}&d4=${urlSafeDate}&d5=${event.target.checked}&d6=${urlSafePId}&d7=${encryptedBucketId}&d8=${isChecked}`;
    }
    if (name === 'upload') {
      setChecked(event.target.checked);
      setUrl(
        `${currentUrl}/sign-in?share=true&d1=${uid}&d2=${urlSafeEmail}&d3=${urlSafePass}&d4=${urlSafeDate}&d5=${urlSafeidentify}&d6=${urlSafePId}&d7=${encryptedBucketId}&d8=${event.target.checked}`
      );
      tinyCurrentUrl = `${currentUrl}/sign-in?share=true&d1=${uid}&d2=${urlSafeEmail}&d3=${urlSafePass}&d4=${urlSafeDate}&d5=${urlSafeidentify}&d6=${urlSafePId}&d7=${encryptedBucketId}&d8=${event.target.checked}`;
    }

    axios
      .get(`https://tinyurl.com/api-create.php?url=${encodeURIComponent(tinyCurrentUrl)}`)
      .then(response => {
        setLoading(false);
        setTinyUrl(response.data);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const onEmailClick = () => {
    if (password != '' && date != '' && !loading) {
      setOnEmail(!onEmail);
    } else {
      setOnEmail(false);
    }
  };

  const onQRClick = () => {
    if (password != '' && date != '' && !loading) {
      setShowQR(!showQR);
    } else {
      setShowQR(false);
    }
  };

  const SendEmail = async () => {
    const token = localStorage.getItem('token');

    let formdata = new FormData();

    formdata.append('address', receiverEmail);
    formdata.append('subject', subject);
    formdata.append('link', tinyUrl);
    formdata.append('userID', email);
    formdata.append('userPass', password);
    const axiosInstance = axios.create({
      baseURL: `${API}/api/v1/emailsend/`,
    });
    axiosInstance
      .post('sendEmailLink', formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async response => {
        uiNotificationService.show({
          title: 'Share Case',
          message: 'Email sent successfully, please check your spam/junk folder.',
          type: 'success',
          duration: 3000,
        });
        onClose();
      });
  };

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div
        className={`dark:border-primary-newgray border-primary-gray dark:bg-primary-modalgray absolute left-[34%] z-50 flex flex-col justify-center pb-8 ${onEmail && showQR ? 'top-[8%]' : onEmail ? 'top-[23%]' : showQR ? 'top-[16%]' : 'top-[28%]'} w-[585px] rounded-lg border-2 bg-white`}
      >
        <div className="flex justify-between p-5">
          <span className="text-primary-darknav dark:text-primary-light text-2xl font-bold">
            Share Files
          </span>
        </div>
        <div className="flex flex-col px-5">
          <input
            value={url}
            type="text"
            disabled
            className="border-primary-gray h-10 rounded-lg border-2 bg-white p-2"
          />
          <div className="mt-5 flex w-full gap-x-3">
            {/* <input
              style={{ display: 'none' }}
              type="text"
              value={email}
              onChange={e => onChangeField(e)}
              placeholder="Email ID"
              className="border-primary-gray focus:border-primary-light h-10 rounded-lg border-2 p-2 placeholder:text-black placeholder:text-opacity-60"
            /> */}
            <input
              autoComplete="new-password"
              type="password"
              value={password}
              onChange={e => onChangeField(e)}
              placeholder="Password"
              className="border-primary-gray focus:border-primary-light h-10 w-full rounded-lg border-2 p-2 placeholder:text-black placeholder:text-opacity-60"
            />
            <input
              value={date}
              onChange={e => onChangeField(e)}
              type="date"
              className="border-primary-gray focus:border-primary-light h-10 w-full rounded-lg border-2 p-2 placeholder:text-black placeholder:text-opacity-60"
            />
          </div>
          {onEmail && (
            <div className="mt-5 flex w-full gap-x-3">
              <input
                autoComplete="off"
                type="text"
                value={receiverEmail}
                onChange={e => setReceiverEmail(e.target.value)}
                placeholder="Receiver's Email ID"
                className="border-primary-gray focus:border-primary-light h-10 w-full rounded-lg border-2 p-2 placeholder:text-black placeholder:text-opacity-60"
              />
              <input
                autoComplete="off"
                type="text"
                value={subject}
                onChange={e => setSubject(e.target.value)}
                placeholder="Subject"
                className="border-primary-gray focus:border-primary-light h-10 w-full rounded-lg border-2 p-2 placeholder:text-black placeholder:text-opacity-60"
              />
            </div>
          )}
          {StudyInstanceUIDs == 'undefined' && shareFolder != '' && (
            <div className="mt-2 flex gap-x-1">
              <input
                type="checkbox"
                checked={isChecked}
                name="upload"
                id="upload-checkbox"
                onChange={e => onChangeField(e)}
                className="cursor-pointer"
              />
              <label
                htmlFor="upload-checkbox"
                className="cursor-pointer text-black dark:text-white"
              >
                Allow upload
              </label>
            </div>
          )}

          {/* <div className="mt-5 flex cursor-pointer">
            <input
              name="De-identify"
              type="checkbox"
              id="deidentification-checkbox"
              className="cursor-pointer"
              onChange={e => onChangeField(e)}
            />
            <label
              htmlFor="deidentification-checkbox"
              className="ml-3 cursor-pointer text-black dark:text-white"
            >
              de-identification
            </label>
          </div> */}

          {showQR && (
            <div className="flex w-full items-center justify-center">
              <QRCode
                size={144}
                style={{ height: 'auto', maxWidth: '200px', width: '200px', margin: '10px' }}
                value={tinyUrl}
                viewBox={`0 0 256 256`}
              />
            </div>
          )}
          <div className="mt-5 flex justify-between">
            <button
              onClick={onQRClick}
              className={`bg-primary-light ${password != '' && date != '' && !loading ? 'hover:bg-primary-darknav' : 'cursor-not-allowed'} rounded-md p-2 text-sm font-bold text-white shadow-sm transition duration-300`}
            >
              SHOW QR CODE
            </button>
            <div className="flex">
              {onEmail && (
                <button
                  onClick={SendEmail}
                  className="bg-primary-light hover:bg-primary-darknav rounded-md p-2 text-sm font-bold text-white shadow-sm transition duration-300"
                >
                  SEND EMAIL
                </button>
              )}
              <button
                onClick={onEmailClick}
                className={`bg-primary-light ${password != '' && date != '' && !loading ? 'hover:bg-primary-darknav' : 'cursor-not-allowed'} ml-3 rounded-md p-2 text-sm font-bold text-white shadow-sm transition duration-300`}
              >
                {onEmail ? 'CLOSE EMAIL' : 'SEND WITH EMAIL'}
              </button>
              <button
                className={`bg-primary-light ${date == '' && password != '' && !loading ? 'cursor-not-allowed' : 'hover:bg-primary-darknav'} mx-3 rounded-md p-2 text-sm font-bold text-white shadow-sm transition duration-300`}
                onClick={() => {
                  if (date != '' && password != '' && !loading) {
                    navigator.clipboard.writeText(tinyUrl);
                    uiNotificationService.show({
                      title: 'Share Case',
                      message: 'Coped to Clipboard Successfully.',
                      type: 'success',
                      duration: 3000,
                    });
                  }
                }}
              >
                COPY TO CLIPBOARD
              </button>
              <button
                onClick={onClose}
                className="hover:bg-primary-darknav from-primary-light to-primary-darknav rounded-md bg-gradient-to-r p-2 text-sm font-bold text-white shadow-sm transition duration-300"
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
}

ShareModal.propTypes = {
  linkValue: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ShareModal;
