import { createSlice } from '@reduxjs/toolkit';

export const bucketSlice = createSlice({
  name: 'bucket',
  initialState: {
    value: [],
  },
  reducers: {
    bucket: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { bucket } = bucketSlice.actions;

export default bucketSlice.reducer;
