import React from 'react';
import type { IconProps } from '../types';

export const AIIcon = (props: IconProps) => (
  <svg
    width="800"
    height="800"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    class="iconify iconify--fxemoji"
    fill="#fff"
    {...props}
  >
    <g stroke-width="0" />
    <g
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      class="small-sparkle-2"
      fill="#85a3ff"
      d="M210.3 65.5c28.8 7.3 51.4 29.9 58.7 58.7.7 2.8 4.3 2.8 5 0 7.3-28.8 29.9-51.4 58.7-58.7 2.8-.7 2.8-4.3 0-5-28.8-7.3-51.4-29.9-58.7-58.7-.7-2.8-4.3-2.8-5 0-7.3 28.8-29.9 51.4-58.7 58.7-2.8.7-2.8 4.3 0 5"
    />
    <path
      class="small-sparkle-1"
      fill="#6c7df9"
      d="M6.7 188.3c50.8 12.9 90.8 52.9 103.7 103.7 1.2 4.9 7.5 4.9 8.8 0 12.9-50.8 52.9-90.8 103.7-103.7 4.9-1.2 4.9-7.5 0-8.8C172 166.7 132 126.7 119.2 75.9c-1.2-4.9-7.5-4.9-8.8 0-12.9 50.8-52.9 90.8-103.7 103.7-4.9 1.2-4.9 7.5 0 8.7"
    />
    <path
      fill="#ff6b72"
      d="M180 350.7c76 19.3 135.9 79.1 155.1 155.1 1.9 7.3 11.3 7.3 13.1 0 19.3-76 79.1-135.9 155.1-155.1 7.3-1.9 7.3-11.3 0-13.1-76-19.3-135.9-79.1-155.1-155.1-1.9-7.3-11.3-7.3-13.1 0-19.3 76-79.1 135.9-155.1 155.1-7.3 1.8-7.3 11.2 0 13.1"
    />
  </svg>
);

export const AISeg = (props: IconProps) => (
  <svg
    width="28px"
    height="28px"
    viewBox="0 0 24 24"
    fill="rgb(255 125 125)"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="SVGRepo_bgCarrier"
      stroke-width="0"
    ></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M3.84453 7.92226C2.71849 6.79623 2.71849 4.97056 3.84453 3.84453C4.97056 2.71849 6.79623 2.71849 7.92226 3.84453L20.1555 16.0777C21.2815 17.2038 21.2815 19.0294 20.1555 20.1555C19.0294 21.2815 17.2038 21.2815 16.0777 20.1555L3.84453 7.92226Z"
        stroke="#ffffff"
        stroke-width="1.5"
      ></path>{' '}
      <path
        opacity="0.5"
        d="M6 10L10 6"
        stroke="#ffffff"
        stroke-width="1.5"
        stroke-linecap="round"
      ></path>{' '}
      <path
        d="M16.1 2.30719C16.261 1.8976 16.8385 1.8976 16.9994 2.30719L17.4298 3.40247C17.479 3.52752 17.5776 3.62651 17.7022 3.67583L18.7934 4.1078C19.2015 4.26934 19.2015 4.849 18.7934 5.01054L17.7022 5.44252C17.5776 5.49184 17.479 5.59082 17.4298 5.71587L16.9995 6.81115C16.8385 7.22074 16.261 7.22074 16.1 6.81116L15.6697 5.71587C15.6205 5.59082 15.5219 5.49184 15.3973 5.44252L14.3061 5.01054C13.898 4.849 13.898 4.26934 14.3061 4.1078L15.3973 3.67583C15.5219 3.62651 15.6205 3.52752 15.6697 3.40247L16.1 2.30719Z"
        stroke="#6c7df9"
        class="small-sparkle-2"
        fill="#6c7df9"
      ></path>{' '}
      <path
        opacity="0.5"
        d="M19.9672 9.12945C20.1281 8.71987 20.7057 8.71987 20.8666 9.12945L21.0235 9.5288C21.0727 9.65385 21.1713 9.75284 21.2959 9.80215L21.6937 9.95965C22.1018 10.1212 22.1018 10.7009 21.6937 10.8624L21.2959 11.0199C21.1713 11.0692 21.0727 11.1682 21.0235 11.2932L20.8666 11.6926C20.7057 12.1022 20.1281 12.1022 19.9672 11.6926L19.8103 11.2932C19.7611 11.1682 19.6625 11.0692 19.5379 11.0199L19.14 10.8624C18.732 10.7009 18.732 10.1212 19.14 9.95965L19.5379 9.80215C19.6625 9.75284 19.7611 9.65385 19.8103 9.5288L19.9672 9.12945Z"
        stroke="#85a3ff"
        class="small-sparkle-1"
        fill="#85a3ff"
      ></path>{' '}
      <path
        opacity="0.5"
        d="M5.1332 15.3072C5.29414 14.8976 5.87167 14.8976 6.03261 15.3072L6.18953 15.7065C6.23867 15.8316 6.33729 15.9306 6.46188 15.9799L6.85975 16.1374C7.26783 16.2989 7.26783 16.8786 6.85975 17.0401L6.46188 17.1976C6.33729 17.2469 6.23867 17.3459 6.18953 17.471L6.03261 17.8703C5.87167 18.2799 5.29414 18.2799 5.1332 17.8703L4.97628 17.471C4.92714 17.3459 4.82852 17.2469 4.70393 17.1976L4.30606 17.0401C3.89798 16.8786 3.89798 16.2989 4.30606 16.1374L4.70393 15.9799C4.82852 15.9306 4.92714 15.8316 4.97628 15.7065L5.1332 15.3072Z"
        fill="#ff6b72"
        stroke="#ff6b72"
        class="small-sparkle-1"
      ></path>{' '}
    </g>
  </svg>
);

export const AIGallery = (props: IconProps) => (
  <svg
    width="64px"
    height="64px"
    viewBox="0 0 48 48"
    version="1"
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 48 48"
    fill="#ffffff"
    stroke="#ffffff"
    {...props}
    stroke-width="0.00048000000000000007"
  >
    <g
      id="SVGRepo_bgCarrier"
      stroke-width="0"
    />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        fill="#E65100"
        d="M41,42H13c-2.2,0-4-1.8-4-4V18c0-2.2,1.8-4,4-4h28c2.2,0,4,1.8,4,4v20C45,40.2,43.2,42,41,42z"
      />{' '}
      <path
        fill="#F57C00"
        d="M35,36H7c-2.2,0-4-1.8-4-4V12c0-2.2,1.8-4,4-4h28c2.2,0,4,1.8,4,4v20C39,34.2,37.2,36,35,36z"
      />{' '}
      <circle
        fill="#FFF9C4"
        cx="30"
        cy="16"
        r="3"
      />{' '}
      <polygon
        fill="#942A09"
        points="17,17.9 8,31 26,31"
      />{' '}
      <polygon
        fill="#BF360C"
        points="28,23.5 22,31 34,31"
      />{' '}
    </g>
  </svg>
);
