import axios from 'axios';
import api_constants from '../../constants/urls';

const getToken = async expiresAt => {
  const url = api_constants.GET_TOKEN;

  try {
    const response = await axios.post(
      url,
      {
        expiresAt: expiresAt,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'E40AJER7CJZV6K07F448',
        },
      }
    );

    if (response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/sign-in';
      return;
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data || error.response.statusText);
    } else if (error.request) {
      throw new Error('No response received from server');
    } else {
      throw new Error(error.message);
    }
  }
};

export default getToken;
