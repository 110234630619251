/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Enums, ExtensionManager, MODULE_TYPES, log } from '@ohif/core';
//
import { extensionManager } from '../App.tsx';
import { useParams, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import useSearchParams from '../hooks/useSearchParams.ts';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { list, setIsLocal } from '../../../app/src/redux/features/studylist/studylist.js';
import {
  setFolders,
  setSpecsMessage,
  setValueKey,
  user,
} from '../../../app/src/redux/features/studylist/userlist.js';
import { bucket } from '../../../app/src/redux/features/studylist/bucketlist.js';
import CryptoJS from 'crypto-js';
import { API, KeyImagesAPI, LocalAPI } from '../../../../constants/baseurl.js';
import LeadForm from './LeadForm';
import { getSpecificBucket, secretKeyy } from '../../../../utils/bucket.js';

import { DICOMWeb, utils } from '@ohif/core';
import { processResults } from '../../../../extensions/default/src/DicomWebDataSource/qido.js';

const { getString, getName, getModalities } = DICOMWeb;

/**
 * Determines if two React Router location objects are the same.
 */
const areLocationsTheSame = (location0, location1) => {
  return (
    location0.pathname === location1.pathname &&
    location0.search === location1.search &&
    location0.hash === location1.hash
  );
};

/**
 * Uses route properties to determine the data source that should be passed
 * to the child layout template. In some instances, initiates requests and
 * passes data as props.
 *
 * @param {object} props
 * @param {function} props.children - Layout Template React Component
 */
function DataSourceWrapper(props: withAppTypes) {
  const user_demo = localStorage.getItem('user_data');
  if (user_demo && user_demo != 'undefined') {
    const parsed_obj = JSON.parse(user_demo);
    const is_demo = parsed_obj?.email == 'userdemo@gmail.com';
    const is_shown = localStorage.getItem('FormTrue');
    if (is_demo && (!is_shown || is_shown == 'false')) {
      return <LeadForm />;
    }
  }
  const { servicesManager } = props;
  const navigate = useNavigate();
  // const isSigned = localStorage.getItem('token');
  // if (!isSigned) {
  //   navigate('/sign-up');
  // }
  const { children: LayoutTemplate, ...rest } = props;
  const params = useParams();
  const location = useLocation();
  const lowerCaseSearchParams = useSearchParams({ lowerCaseKeys: true });
  const query = useSearchParams();
  // Route props --> studies.mapParams
  // mapParams --> studies.search
  // studies.search --> studies.processResults
  // studies.processResults --> <LayoutTemplate studies={} />
  // But only for LayoutTemplate type of 'list'?
  // Or no data fetching here, and just hand down my source
  const STUDIES_LIMIT = 101;
  const DEFAULT_DATA = {
    studies: [],
    buckets: [],
    users: [],
    total: 0,
    resultsPerPage: 25,
    pageNumber: 1,
    location: 'Not a valid location, causes first load to occur',
  };

  const [isDataSourceInitialized, setIsDataSourceInitialized] = useState(false);

  const isLocal = useSelector(state => state.list.isLocal);
  const isFolderStructure = useSelector(state => state.user.folderStructure);
  const isUser = useSelector(state => state.user.value);
  const isBucket = useSelector(state => state.bucket.value);
  const folderName = useSelector(state => state.user.selectedFolder);
  const dispatch = useDispatch();
  const [prevLocal, setPrevLocal] = useState(isLocal);
  const [prevFolder, setPrevFolder] = useState(isFolderStructure);

  useEffect(() => {
    dispatch(setSpecsMessage(false));
  }, []);

  const fetchBuckets = async param => {
    const token = localStorage.getItem('token');
    const { clients } = param;
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const client_id = user_data ? user_data._id : 'N/A';
    const new_clients = clients ? clients : client_id;
    try {
      const bucket_response = await axios.post(
        `${API}/api/v1/bucket/getSpecificUserBucket`,
        {
          user: new_clients,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const mappedBuckets = bucket_response.data.data.map(item => {
        return {
          _id: item._id,
          user: item.user,
          bucketName: item.bucketName,
          dcmcAccessKey: item.dcmcAccessKey,
          bucketType: item.bucketType,
          __v: item.__v,
          accessKeyId: CryptoJS.AES.decrypt(item.accessKeyId, secretKeyy).toString(
            CryptoJS.enc.Utf8
          ),
          secretAccessKey: CryptoJS.AES.decrypt(item.secretAccessKey, secretKeyy).toString(
            CryptoJS.enc.Utf8
          ),
        };
      });

      if (bucket_response.status === 401) {
        localStorage.removeItem('token');
        navigate('/sign-in');
        return [];
      }

      dispatch(bucket(mappedBuckets));

      return mappedBuckets;
    } catch (error) {
      if (error.request.status) {
        localStorage.removeItem('token');
        navigate('/sign-in');
      }
      console.error(error);
      return [];
    }
  };

  const fetchUsers = async () => {
    const token = localStorage.getItem('token');
    try {
      const user_response = await axios.get(`${API}/api/v1/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (user_response.status === 401) {
        localStorage.removeItem('token');
        navigate('/sign-in');
        return [];
      }

      dispatch(user(user_response.data.data));

      return user_response.data.data;
    } catch (error) {
      if (error.request.status) {
        localStorage.removeItem('token');
        navigate('/sign-in');
      }
      console.error(error);
      return [];
    }
  };
  const fetchLocal = async (queryFilterValues = {}) => {
    const token = localStorage.getItem('token');
    try {
      const study_response = await axios.get(`${LocalAPI}/apilocal/v1/local`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (study_response.status === 401) {
        localStorage.removeItem('token');
        //navigate('/sign-in');
        return [];
      }

      const data = study_response.data.data;

      // Apply filtering if queryFilterValues exists
      return data.filter(item => {
        const {
          accessionNumber = null,
          startDate = null,
          endDate = null,
          modalitiesInStudy = [],
        } = queryFilterValues || {};

        // Filter by accession number if provided
        const matchesAccession = accessionNumber ? item.accession === accessionNumber : true;

        // Filter by date range if both startDate and endDate are provided
        const matchesDate =
          startDate && endDate ? item.date >= startDate && item.date <= endDate : true;

        // Filter by modalities if provided
        const matchesModalities = modalitiesInStudy.length
          ? modalitiesInStudy.includes(item.modalities)
          : true;

        // Return true if all conditions are met
        return matchesAccession && matchesDate && matchesModalities;
      });
    } catch (error) {
      if (error.request?.status) {
        localStorage.removeItem('token');
        //window.location.href = '/sign-in';
      }
      console.error(error);
      return [];
    }
  };

  const fetchFolders = async bucket => {
    try {
      const token = localStorage.getItem('token');
      const body = {
        AwsAccessKey: bucket.accessKey,
        AwsSecretAccessKey: bucket.secretKey,
        AwsBucket: bucket.bucketName,
      };
      const response = await axios.post(
        `${KeyImagesAPI}/keyImages/v1/updateFolder/v4/getFolderList`,
        // `http://localhost:8001/keyImages/v1/updateFolder/v4/getFolderList`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response && response.data) {
        dispatch(setFolders(response.data.data));
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // function processResults(qidoStudies) {
  //   if (!qidoStudies || !qidoStudies.length) {
  //     return [];
  //   }

  //   const studies = [];

  //   qidoStudies.forEach(qidoStudy =>
  //     studies.push({
  //       studyInstanceUid:
  //         qidoStudy['0020000D'] != undefined
  //           ? getString(qidoStudy['0020000D'])
  //           : getString(qidoStudy['0020000d']),
  //       date: getString(qidoStudy['00080020']), // YYYYMMDD
  //       time: getString(qidoStudy['00080030']), // HHmmss.SSS (24-hour, minutes, seconds, fractional seconds)
  //       accession: getString(qidoStudy['00080050']) || '', // short string, probably a number?
  //       mrn: getString(qidoStudy['00100020']) || '', // medicalRecordNumber
  //       patientName: utils.formatPN(getName(qidoStudy['00100010'])) || '',
  //       instances: Number(getString(qidoStudy['00201208'])) || 0, // number
  //       description: getString(qidoStudy['00081030']) || '',
  //       modalities: getString(getModalities(qidoStudy['00080060'], qidoStudy['00080061'])) || '',
  //     })
  //   );

  //   return studies;
  // }

  const fetchFolderStudylist = async (queryFilterValues = {}, bucket) => {
    try {
      const token = localStorage.getItem('token');
      const body = {
        AwsAccessKey: bucket.accessKey,
        AwsSecretAccessKey: bucket.secretKey,
        bucketName: bucket.bucketName,
        folderName: folderName,
      };
      const response = await axios.post(
        `${LocalAPI}/apilocal/v1/local/getStudylist`,
        // `http://localhost:3081/apilocal/v1/local/getStudylist`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response && response.data) {
        const data = processResults(response.data.data);

        return data.filter(item => {
          const {
            accessionNumber = null,
            startDate = null,
            endDate = null,
            modalitiesInStudy = [],
          } = queryFilterValues || {};

          // Filter by accession number if provided
          const matchesAccession = accessionNumber ? item.accession === accessionNumber : true;

          // Filter by date range if both startDate and endDate are provided
          const matchesDate =
            startDate && endDate ? item.date >= startDate && item.date <= endDate : true;

          // Filter by modalities if provided
          const matchesModalities = modalitiesInStudy.length
            ? modalitiesInStudy.includes(item.modalities)
            : true;

          // Return true if all conditions are met
          return matchesAccession && matchesDate && matchesModalities;
        });
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getInitialDataSourceName = useCallback(() => {
    // TODO - get the variable from the props all the time...
    let dataSourceName = lowerCaseSearchParams.get('datasources');

    if (!dataSourceName && window.config.defaultDataSourceName) {
      return '';
    }

    if (!dataSourceName) {
      // Gets the first defined datasource with the right name
      // Mostly for historical reasons - new configs should use the defaultDataSourceName
      const dataSourceModules = extensionManager.modules[MODULE_TYPES.DATA_SOURCE];
      // TODO: Good usecase for flatmap?
      const webApiDataSources = dataSourceModules.reduce((acc, curr) => {
        const mods = [];
        curr.module.forEach(mod => {
          if (mod.type === 'webApi') {
            mods.push(mod);
          }
        });
        return acc.concat(mods);
      }, []);
      dataSourceName = webApiDataSources
        .map(ds => ds.name)
        .find(it => extensionManager.getDataSources(it)?.[0] !== undefined);
    }

    return dataSourceName;
  }, []);

  // The path to the data source to be used in the URL for a mode (e.g. mode/dataSourcePath?StudyIntanceUIDs=1.2.3)
  const [dataSourcePath, setDataSourcePath] = useState(() => {
    const dataSourceName = getInitialDataSourceName();
    return dataSourceName ? `/${dataSourceName}` : '';
  });

  const [dataSource, setDataSource] = useState(() => {
    const dataSourceName = getInitialDataSourceName();

    if (!dataSourceName) {
      return extensionManager.getActiveDataSource()[0];
    }

    const dataSource = extensionManager.getDataSources(dataSourceName)?.[0];
    if (!dataSource) {
      throw new Error(`No data source found for ${dataSourceName}`);
    }

    return dataSource;
  });

  const [data, setData] = useState(DEFAULT_DATA);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * The effect to initialize the data source whenever it changes. Similar to
   * whenever a different Mode is entered, the Mode's data source is initialized, so
   * too this DataSourceWrapper must initialize its data source whenever a different
   * data source is activated. Furthermore, a data source might be initialized
   * several times as it gets activated/deactivated because the location URL
   * might change and data sources initialize based on the URL.
   */
  useEffect(() => {
    const initializeDataSource = async () => {
      await dataSource.initialize({ params, query });
      setIsDataSourceInitialized(true);
    };

    initializeDataSource();
  }, [dataSource]);

  useEffect(() => {
    const dataSourceChangedCallback = () => {
      setIsLoading(false);
      setIsDataSourceInitialized(false);
      setDataSourcePath('');
      setDataSource(extensionManager.getActiveDataSource()[0]);
      // Setting data to DEFAULT_DATA triggers a new query just like it does for the initial load.
      setData(DEFAULT_DATA);
    };

    const sub = extensionManager.subscribe(
      ExtensionManager.EVENTS.ACTIVE_DATA_SOURCE_CHANGED,
      dataSourceChangedCallback
    );
    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (!isDataSourceInitialized) {
      return;
    }

    const queryFilterValues = _getQueryFilterValues(location.search, STUDIES_LIMIT);

    // 204: no content
    async function getData() {
      setIsLoading(true);
      setPrevLocal(isLocal);
      setPrevFolder(isFolderStructure);
      log.time(Enums.TimingEnum.SEARCH_TO_LIST);

      const user_data = JSON.parse(localStorage.getItem('user_data'));
      if (user_data == null) {
        window.location.href = '/sign-in';
      }
      const admin = user_data?.admin && user_data?.admin == 'true' ? true : false;
      let users;
      if (admin == true) {
        users = isUser.length > 0 ? isUser : await fetchUsers();
      }
      const { clients } = queryFilterValues;
      const buckets =
        clients === null && isBucket.length > 0 ? isBucket : await fetchBuckets(queryFilterValues);

      const is_share = JSON.parse(localStorage.getItem('share'));
      const share_case =
        user_data &&
        user_data.isShared &&
        is_share &&
        user_data.isShared == 'true' &&
        is_share == true
          ? true
          : false;
      const shareStudyInstanceUid = localStorage.getItem('shareUid');
      const deidentified = localStorage.getItem('deIdentified');

      const { bucket } = queryFilterValues;
      const bucketFilterValue = share_case == true ? localStorage.getItem('bucketId') : bucket;
      const bucketData = buckets?.filter(item => item._id === bucketFilterValue) || [];

      const defaultBucket = {
        accessKeyId: 'N/A',
        secretAccessKey: 'N/A',
        bucketName: 'N/A',
        dcmcAccessKey: 'N/A',
      };

      const selectedBucket =
        bucketData.length > 0 ? bucketData[0] : buckets.length > 0 ? buckets[0] : defaultBucket;

      localStorage.setItem(
        'bucketId',
        bucket ? bucket : buckets.length > 0 ? buckets[0]._id : null
      );
      const accessKey = selectedBucket.accessKeyId;
      const secretKey = selectedBucket.secretAccessKey;
      const bucketName = selectedBucket.bucketName;
      const dcmcAccessKey = selectedBucket.dcmcAccessKey;

      const combinedString = `${bucketName}^${accessKey}^${secretKey}^${dcmcAccessKey}`;
      const encryptedString = btoa(combinedString);

      // dispatch(setValueKey(encryptedString));
      const encryptedBase64 = CryptoJS.AES.encrypt(encryptedString, secretKeyy).toString();
      localStorage.setItem('new_value', encryptedBase64);

      const data = {
        accessKey: accessKey,
        secretKey: secretKey,
        bucketName: bucketName,
        SUID: shareStudyInstanceUid,
        Deidentified: deidentified,
      };

      let studies = [];

      if (isFolderStructure) {
        fetchFolders(data);
      } else if (isLocal == true && share_case == false) {
        studies = await fetchLocal(queryFilterValues);
      } else {
        if (share_case == true) {
          dispatch(setIsLocal(false));
        }

        if (folderName == '') {
          studies = await dataSource.query.studies.search(queryFilterValues, data);
        } else {
          studies = await fetchFolderStudylist(queryFilterValues, data);
        }
      }

      if (share_case == true && shareStudyInstanceUid) {
        studies = studies.filter(item => item.studyInstanceUid == shareStudyInstanceUid);
      } else if (share_case == true && !shareStudyInstanceUid && folderName == '') {
        studies = [];
        navigate('/sign-in');
      }
      setData({
        studies: studies || [],
        buckets: buckets || [],
        users: users || [],
        total: studies.length,
        resultsPerPage: queryFilterValues.resultsPerPage,
        pageNumber: queryFilterValues.pageNumber,
        location,
      });
      log.timeEnd(Enums.TimingEnum.SCRIPT_TO_VIEW);
      log.timeEnd(Enums.TimingEnum.SEARCH_TO_LIST);

      setIsLoading(false);
    }

    try {
      // Cache invalidation :thinking:
      // - Anytime change is not just next/previous page
      // - And we didn't cross a result offset range
      const isSamePage = data.pageNumber === queryFilterValues.pageNumber;
      const previousOffset =
        Math.floor((data.pageNumber * data.resultsPerPage) / STUDIES_LIMIT) * (STUDIES_LIMIT - 1);
      const newOffset =
        Math.floor(
          (queryFilterValues.pageNumber * queryFilterValues.resultsPerPage) / STUDIES_LIMIT
        ) *
        (STUDIES_LIMIT - 1);
      // Simply checking data.location !== location is not sufficient because even though the location href (i.e. entire URL)
      // has not changed, the React Router still provides a new location reference and would result in two study queries
      // on initial load. Alternatively, window.location.href could be used.
      const isLocationUpdated =
        typeof data.location === 'string' || !areLocationsTheSame(data.location, location);
      const local = prevLocal != isLocal;
      const folder = prevFolder != isFolderStructure;
      const isDataInvalid =
        folder ||
        local ||
        !isSamePage ||
        (!isLoading && (newOffset !== previousOffset || isLocationUpdated));

      if (isDataInvalid) {
        getData().catch(e => {
          console.error(e);

          const { configurationAPI, friendlyName } = dataSource.getConfig();
          // If there is a data source configuration API, then the Worklist will popup the dialog to attempt to configure it
          // and attempt to resolve this issue.
          if (configurationAPI) {
            return;
          }

          const isToken = localStorage.getItem('token');
          if (isToken) {
            servicesManager.services.uiModalService.show({
              title: 'Data Source Connection Error',
              containerDimensions: 'w-1/2',
              content: () => {
                return (
                  <div>
                    <p className="text-red-600">Error: {e.message}</p>
                    <p className="text-black dark:text-white">
                      Please ensure the following data source is configured correctly or is running:
                    </p>
                    <div className="mt-2 font-bold text-black dark:text-white">{friendlyName}</div>
                  </div>
                );
              },
            });
          } else {
            navigate('/sign-in');
          }
        });
      }
    } catch (ex) {
      console.warn(ex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    location,
    params,
    isLoading,
    setIsLoading,
    dataSource,
    isDataSourceInitialized,
    isLocal,
    isFolderStructure,
  ]);
  // queryFilterValues

  // TODO: Better way to pass DataSource?
  return (
    <LayoutTemplate
      {...rest}
      data={data.studies}
      buckets={data.buckets}
      users={data.users}
      dataPath={dataSourcePath}
      dataTotal={data.total}
      dataSource={dataSource}
      isLoadingData={isLoading}
      // To refresh the data, simply reset it to DEFAULT_DATA which invalidates it and triggers a new query to fetch the data.
      onRefresh={() => setData(DEFAULT_DATA)}
    />
  );
}

DataSourceWrapper.propTypes = {
  /** Layout Component to wrap with a Data Source */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

export default DataSourceWrapper;

/**
 * Duplicated in `workList`
 * Need generic that can be shared? Isn't this what qs is for?
 * @param {*} query
 */
function _getQueryFilterValues(query, queryLimit) {
  query = new URLSearchParams(query);
  const newParams = new URLSearchParams();
  for (const [key, value] of query) {
    newParams.set(key.toLowerCase(), value);
  }
  query = newParams;

  const pageNumber = _tryParseInt(query.get('pagenumber'), 1);
  const resultsPerPage = _tryParseInt(query.get('resultsperpage'), 25);

  const queryFilterValues = {
    // DCM
    bucket: query.get('bucket'),
    clients: query.get('clients'),
    patientId: query.get('mrn'),
    patientName: query.get('patientname'),
    studyDescription: query.get('description'),
    modalitiesInStudy: query.get('modalities') && query.get('modalities').split(','),
    accessionNumber: query.get('accession'),
    //
    startDate: query.get('startdate'),
    endDate: query.get('enddate'),
    page: _tryParseInt(query.get('page'), undefined),
    pageNumber,
    resultsPerPage,
    // Rarely supported server-side
    sortBy: query.get('sortby'),
    sortDirection: query.get('sortdirection'),
    // Offset...
    offset: Math.floor((pageNumber * resultsPerPage) / queryLimit) * (queryLimit - 1),
    config: query.get('configurl'),
  };

  // patientName: good
  // studyDescription: good
  // accessionNumber: good

  // Delete null/undefined keys
  Object.keys(queryFilterValues).forEach(
    key => queryFilterValues[key] == null && delete queryFilterValues[key]
  );

  return queryFilterValues;

  function _tryParseInt(str, defaultValue) {
    let retValue = defaultValue;
    if (str !== null) {
      if (str.length > 0) {
        if (!isNaN(str)) {
          retValue = parseInt(str);
        }
      }
    }
    return retValue;
  }
}
