import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  Icons,
  Button,
  Tooltip,
} from '../';
import { useAppConfig } from '@state';
import NavBar from '../NavBar';
import ThemeToggle from '../../../../ui/src/components/Header/ThemeToggleButton';
import Svg from '../Svg';
import { useLocalStorage } from '@uidotdev/usehooks';
import { servicesManager } from '../../../../app/src/App';

// Todo: we should move this component to composition and remove props base

interface HeaderProps {
  children?: ReactNode;
  menuOptions: Array<{
    title: string;
    icon?: string;
    onClick: () => void;
  }>;
  isReturnEnabled?: boolean;
  onClickReturnButton?: () => void;
  isSticky?: boolean;
  WhiteLabeling?: {
    createLogoComponentFn?: (React: any, props: any) => ReactNode;
  };
  PatientInfo?: ReactNode;
  Secondary?: ReactNode;
}

function Header({
  children,
  menuOptions,
  isReturnEnabled = true,
  onClickReturnButton,
  isSticky = false,
  WhiteLabeling,
  PatientInfo,
  Secondary,
  themeOption,
  themeMode,
  ...props
}: HeaderProps): ReactNode {
  const { t } = useTranslation('Header');
  const [appConfig] = useAppConfig();

  const { displaySetService } = servicesManager.services;
  let [overflow, setOverflow] = useState(true);
  const [isNewOverflow, setIsNewOverflow] = useState(() => {
    const savedMode = localStorage.getItem('stop-overflow');
    return savedMode === '0' ? '0' : '1';
  });

  const getModality = () => {
    const displaySets = displaySetService.getActiveDisplaySets();
    const displaySet = displaySets[0];
    const instance = displaySet?.instances?.[0] || displaySet?.instance;
    if (!instance) {
      return;
    }
    return instance?.Modality;
  };

  const [isOverflow, setIsOverflow] = useLocalStorage('stop-overflow', isNewOverflow);

  useEffect(() => {
    const menu1 = document.querySelector('[data-cy="MeasurementTools-list-menu"]');
    const menu2 = document.querySelector('[data-cy="MoreTools-list-menu"]');
    if (
      (menu1 && menu1.classList.contains('block')) ||
      (menu2 && menu2.classList.contains('block'))
    ) {
      setOverflow(false);
    } else {
      setOverflow(true);
    }
  }, [isOverflow]);

  const [userName, setUserName] = useState('');

  const isXRADReturn =
    window.location.href.includes('/xrad/') || window.location.href.includes('/radx/');

  useEffect(() => {
    const isXRAD =
      window.location.href.includes('/xrad/') || window.location.href.includes('/radx/');
    if (!isXRAD) {
      const user_data = localStorage.getItem('user_data');
      if (user_data) {
        const data = JSON.parse(user_data);
        setUserName(data.firstName + ' ' + data.lastName);
      }
    }
  }, []);

  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };

  const getListPosition = () => {
    const location = window.location.href;
    const urlParams = new URLSearchParams(location);
    const isMRC = urlParams.get('clientName') === 'mrc';
    if (location?.includes('dentistry')) {
      return location?.includes('/xrad/')
        ? `${overflow ? 'overflow-x-auto overflow-y-hidden' : ''} sm:max-w-[600px] md:max-w-[650px] lg:max-w-none max-w-[250px] right-[1.6rem] sm:-right-[1.3rem] md:right-[0.4rem] lg:-right-[1.3rem] xl:right-[2rem]`
        : `${overflow ? 'overflow-x-auto overflow-y-hidden' : ''} sm:max-w-[600px] md:max-w-[650px] lg:max-w-none max-w-[250px] right-[4.2rem] sm:right-[1.3rem] md:right-[4.9rem] lg:right-[2.1rem] xl:right-[6.3rem]`;
    } else if (location?.includes('viewer')) {
      return location?.includes('/xrad/')
        ? isMRC
          ? `${overflow ? 'overflow-x-auto overflow-y-hidden' : ''} sm:max-w-[600px] md:max-w-[650px] lg:max-w-none max-w-[250px] -right-[2rem] sm:-right-[4.5rem] md:-right-[4.7rem] lg:-right-[5rem] xl:-right-[2.6rem]`
          : getModality() == 'US' ||
              getModality() == 'XA' ||
              getModality() == 'XA' ||
              getModality() == 'DX'
            ? `${overflow ? 'overflow-x-auto overflow-y-hidden' : ''} sm:max-w-[600px] md:max-w-[650px] lg:max-w-none max-w-[250px] right-[1.6rem] sm:-right-[1.3rem] md:right-[0.4rem] lg:-right-[0.1rem] xl:right-[2.6rem]`
            : `${overflow ? 'overflow-x-auto overflow-y-hidden' : ''} sm:max-w-[600px] md:max-w-[650px] lg:max-w-none max-w-[250px] right-[1.6rem] sm:-right-[1.3rem] md:right-[0.4rem] lg:-right-[1.3rem] xl:right-[2rem]`
        : location?.includes('/radx/')
          ? `${overflow ? 'overflow-x-auto overflow-y-hidden' : ''} sm:max-w-[600px] md:max-w-[650px] lg:max-w-none max-w-[250px] right-[1.5rem] sm:-right-[0.2rem] md:right-[1.7rem] lg:right-[1.7rem] xl:right-[3.8rem]`
          : getModality() == 'US' ||
              getModality() == 'XA' ||
              getModality() == 'XA' ||
              getModality() == 'DX'
            ? `${overflow ? 'overflow-x-auto overflow-y-hidden' : ''} sm:max-w-[600px] md:max-w-[650px] lg:max-w-none max-w-[250px] right-[4.2rem] sm:right-[1.3rem] md:right-[4.9rem] lg:right-[3.45rem] xl:right-[7rem]`
            : `${overflow ? 'overflow-x-auto overflow-y-hidden' : ''} sm:max-w-[600px] md:max-w-[650px] lg:max-w-none max-w-[250px] right-[4.2rem] sm:right-[1.3rem] md:right-[4.9rem] lg:right-[2.2rem] xl:right-[6.3rem]`;
    } else if (location?.includes('segmentation')) {
      return 'right-[4.3rem] xl:right-[7.5rem]';
    } else {
      return 'right-[7.4rem] xl:right-[9.4rem]';
    }
  };

  const isXRAD_Viewer =
    window.location.href.includes('/xrad/') || window.location.href.includes('/radx/');

  if (isXRAD_Viewer) {
    menuOptions = menuOptions.filter(item => item.icon != 'power-off');
  }

  const location = window.location.href;
  const urlParams = new URLSearchParams(location);
  const isMRCViewer = urlParams.get('clientName') === 'mrc';

  return (
    <NavBar
      isSticky={isSticky}
      {...props}
    >
      <div className="relative h-[45px] items-end xl:h-[48px]">
        <div className="absolute left-0 top-1/2 hidden -translate-y-1/2 items-center md:flex">
          <div
            className={classNames('mr-3 inline-flex items-center', false && 'cursor-pointer')}
            //onClick={onClickReturn}
            data-cy="return-to-work-list"
          >
            {false && <Icons.ArrowLeft className="w-8 text-black dark:text-white" />}
            <div className="ml-1">
              {themeMode === true
                ? WhiteLabeling?.createLogoComponentFn?.(React, props) || (
                    <Svg name={appConfig.logoDark} />
                  )
                : WhiteLabeling?.createLogoComponentFn?.(React, props) || (
                    <Svg name={appConfig.logoLight} />
                  )}
            </div>
            {userName != '' && (
              <div className="flex gap-x-3">
                <div className="dark:border-primary-newgray border-primary-gray h-[30px] border-l-[3px]"></div>
                <div className="pt-1 text-base font-semibold text-black dark:text-white">
                  {userName}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-1/2 left-[250px] h-8 -translate-y-1/2">{Secondary}</div>
        <div
          className={`absolute top-1/2 flex -translate-y-1/2 scale-75 select-none items-center ${getListPosition()} xl:scale-[0.85]`}
        >
          <div className="flex items-center justify-center space-x-2">{children}</div>
        </div>
        {!isXRADReturn && (
          <div
            className={`absolute top-1/2 right-[2.9rem] flex -translate-y-1/2 scale-75 select-none items-center md:right-[6.8rem] xl:right-[7.7rem] xl:scale-[0.85]`}
          >
            <div className="flex items-center justify-center space-x-2">
              <div className="dark:bg-primary-shadeddarkgray bg-primary-shadedgray rounded-lg p-1">
                <div
                  onClick={onClickReturn}
                  className="dark:hover:bg-primary-newgray hover:bg-primary-gray transform cursor-pointer rounded-lg p-1.5 duration-200"
                >
                  <Icons.ByName
                    name="study-list"
                    className="h-7 w-8 text-black dark:text-white"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {!isMRCViewer && (
          <div className="dark:bg-primary-shadeddarkgray bg-primary-shadedgray absolute top-1/2 -right-2 flex -translate-y-1/2 scale-75 select-none items-center rounded-lg p-[0.1rem] md:-right-4 xl:-right-3 xl:scale-[0.85]">
            {/* {PatientInfo} */}
            <div></div>
            <div className="hover:bg-primary-gray dark:hover:bg-primary-newgray mx-2 flex items-center justify-center">
              <ThemeToggle themeOption={themeOption} />
            </div>
            <div className="flex-shrink-0 md:hidden">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="hover:bg-primary-dark mt-2 h-full w-full text-black dark:text-white"
                  >
                    <Icons.GearSettings />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {menuOptions.map((option, index) => {
                    const IconComponent = option.icon
                      ? Icons[option.icon as keyof typeof Icons]
                      : null;
                    return (
                      <DropdownMenuItem
                        key={index}
                        onSelect={option.onClick}
                        className="flex items-center gap-2 py-2"
                      >
                        {IconComponent && (
                          <span className="flex h-4 w-4 items-center justify-center">
                            <Icons.ByName name={IconComponent.name} />
                          </span>
                        )}
                        <span className="flex-1">{option.title}</span>
                      </DropdownMenuItem>
                    );
                  })}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <div className="hidden flex-shrink-0 md:flex">
              {/* <button className="hover:bg-primary-gray dark:hover:bg-primary-newgray flex h-full w-full items-center justify-center gap-2 py-2 text-black dark:text-white">
              <span
                title="Info"
                className="flex h-8 w-10 items-center justify-center rounded-md p-2 text-black dark:text-white"
              >
                <Icons.ByName name="info" />
              </span>
            </button> */}
              {menuOptions.map((option, index) => {
                const IconComponent = option.icon ? Icons[option.icon as keyof typeof Icons] : null;
                return (
                  <button
                    key={index}
                    onClick={option.onClick}
                    className="hover:bg-primary-gray dark:hover:bg-primary-newgray flex h-full w-full items-center justify-center gap-2 py-2 text-black dark:text-white"
                  >
                    {IconComponent && (
                      <span
                        title={option.title}
                        className="flex h-8 w-9 items-center justify-center rounded-md p-2 text-black dark:text-white"
                      >
                        <Icons.ByName name={IconComponent.name} />
                      </span>
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </NavBar>
  );
}

export default Header;
