import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: [],
    measurementLabels: [],
    tabs: [],
    activeTab: null,
    panelOpen: false,
    specsMessage: false,
    isPrint: false,
    valuekey: '',
    folderStructure: false,
    folders: [],
    selectedFolder: '',
  },
  reducers: {
    user: (state, action) => {
      state.value = action.payload;
    },
    setMeasurementLabels: (state, action) => {
      state.measurementLabels = action.payload;
    },
    setViewerTabs: (state, action) => {
      state.tabs = action.payload;
    },
    setViewerSidePanelOpen: (state, action) => {
      state.panelOpen = action.payload;
    },
    setViewerActiveTabIndex: (state, action) => {
      state.activeTab = action.payload;
    },
    setSpecsMessage: (state, action) => {
      state.specsMessage = action.payload;
    },
    setIsPrint: (state, action) => {
      state.isPrint = action.payload;
    },
    setValueKey: (state, action) => {
      state.valuekey = action.payload;
    },
    setFolderStructure: (state, action) => {
      state.folderStructure = action.payload;
    },
    setFolders: (state, action) => {
      state.folders = action.payload;
    },
    setSelectedFolder: (state, action) => {
      state.selectedFolder = action.payload;
    },
  },
});

export const {
  user,
  setMeasurementLabels,
  setViewerTabs,
  setViewerSidePanelOpen,
  setViewerActiveTabIndex,
  setSpecsMessage,
  setIsPrint,
  setValueKey,
  setFolderStructure,
  setFolders,
  setSelectedFolder,
} = userSlice.actions;

export default userSlice.reducer;
