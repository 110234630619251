import React from 'react';
import type { IconProps } from '../types';

export const Settings = (props: IconProps) => (
  // <svg
  //   width="15.7826087px"
  //   height="15.7826087px"
  //   viewBox="0 0 15.7826087 15.7826087"
  //   version="1.1"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g
  //     id="Artboards"
  //     stroke="none"
  //     strokeWidth="1"
  //     fill="none"
  //     fillRule="evenodd"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   >
  //     <g
  //       id="Segmentation---assets"
  //       transform="translate(-42.127, -62.1087)"
  //       stroke="currentColor"
  //     >
  //       <g
  //         id="icon-display-settings"
  //         transform="translate(40, 60)"
  //       >
  //         <circle
  //           id="Oval"
  //           cx="10"
  //           cy="4.34782609"
  //           r="1.73913043"
  //         ></circle>
  //         <line
  //           x1="11.7373913"
  //           y1="4.34782609"
  //           x2="17.4095652"
  //           y2="4.34782609"
  //           id="Path"
  //         ></line>
  //         <line
  //           x1="2.62695652"
  //           y1="4.34782609"
  //           x2="8.2573913"
  //           y2="4.34782609"
  //           id="Path"
  //         ></line>
  //         <g
  //           id="Group-26"
  //           transform="translate(10.0183, 10) scale(-1, 1) translate(-10.0183, -10)translate(2.627, 8.2609)"
  //         >
  //           <circle
  //             id="Oval"
  //             transform="translate(3.8948, 1.7391) scale(-1, 1) translate(-3.8948, -1.7391)"
  //             cx="3.89478261"
  //             cy="1.73913043"
  //             r="1.73913043"
  //           ></circle>
  //           <line
  //             x1="5.63043478"
  //             y1="1.73913043"
  //             x2="14.7826087"
  //             y2="1.73913043"
  //             id="Path"
  //             transform="translate(10.2065, 1.7391) scale(-1, 1) translate(-10.2065, -1.7391)"
  //           ></line>
  //           <line
  //             x1="0"
  //             y1="1.73913043"
  //             x2="2.15217391"
  //             y2="1.73913043"
  //             id="Path"
  //             transform="translate(1.0761, 1.7391) scale(-1, 1) translate(-1.0761, -1.7391)"
  //           ></line>
  //         </g>
  //         <circle
  //           id="Oval"
  //           cx="8.26086957"
  //           cy="15.6521739"
  //           r="1.73913043"
  //         ></circle>
  //         <line
  //           x1="9.99652174"
  //           y1="15.6521739"
  //           x2="17.4095652"
  //           y2="15.6521739"
  //           id="Path"
  //         ></line>
  //         <line
  //           x1="2.62695652"
  //           y1="15.6521739"
  //           x2="6.52173913"
  //           y2="15.6521739"
  //           id="Path"
  //         ></line>
  //       </g>
  //     </g>
  //   </g>
  // </svg>
  // <svg
  //   width="15.7826087px"
  //   height="15.7826087px"
  //   viewBox="0 0 24 24"
  //   fill="none"
  //   stroke="currentColor"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <g stroke-width="0" />
  //   <g
  //     stroke-linecap="round"
  //     stroke-linejoin="round"
  //   />
  //   <path d="M20.058 9.723c.948-.534 1.423-.801 1.682-1.232.26-.43.26-.949.26-1.987v-.69c0-1.326 0-1.99-.44-2.402C21.122 3 20.415 3 19 3H5c-1.414 0-2.121 0-2.56.412S2 4.488 2 5.815v.69c0 1.037 0 1.556.26 1.986s.733.698 1.682 1.232l2.913 1.64c.636.358.955.537 1.183.735.474.411.766.895.898 1.49.064.284.064.618.064 1.285v2.67c0 .909 0 1.364.252 1.718.252.355.7.53 1.594.88 1.879.734 2.818 1.101 3.486.683S15 19.452 15 17.542v-2.67c0-.666 0-1 .064-1.285a2.68 2.68 0 0 1 .899-1.49" />
  // </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.7826087px"
    height="15.7826087px"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="#17ace8"
      d="M301 242h-90c-8.401 0-15 6.599-15 15v239.893c0 11.999 13.5 19.2 23.401 12.599l36.599-24.3 53.401-35.7c4.199-2.999 6.599-7.5 6.599-12.599V257c0-8.401-6.599-15-15-15"
    />
    <path
      fill="#1689fc"
      d="M316 257v179.893c0 5.099-2.401 9.6-6.599 12.599L256 485.192V242h45c8.401 0 15 6.599 15 15"
    />
    <path
      fill="#1689fc"
      d="M496 90 316 272H196L16 90l21.599-15H471.7z"
    />
    <path
      fill="#136ef1"
      d="M496 90 316 272h-60V75h215.7z"
    />
    <path
      fill="#17ace8"
      d="M496 15v75H16V15c0-8.401 6.599-15 15-15h450c8.401 0 15 6.599 15 15"
    />
    <path
      fill="#1689fc"
      d="M496 15v75H256V0h225c8.401 0 15 6.599 15 15"
    />
  </svg>
);

export default Settings;
