import { configureStore } from '@reduxjs/toolkit';
import selectorSlice from '../features/select/select.js';
import listSlice from '../features/studylist/studylist.js';
import userSlice from '../features/studylist/userlist.js';
import bucket from '../features/studylist/bucketlist.js';
import patient from '../features/studylist/patient.js';
import packagesSlice from '../features/register/packagelist.js';

const store = configureStore({
  reducer: {
    selector: selectorSlice,
    list: listSlice,
    user: userSlice,
    bucket: bucket,
    patient: patient,
    packages: packagesSlice,
  },
});

export default store;
