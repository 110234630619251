import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function ViewportGrid({ numRows, numCols, layoutType, children }) {
  const isPrint = useSelector(state => state.user.isPrint);
  return (
    <div
      data-cy="viewport-grid"
      className={`dark:bg-primary-modalgray border-primary-gray dark:border-primary-newgray rounded-tr-lg rounded-tl-2xl ${isPrint ? 'border-0' : 'border-8'} border-b-0 bg-white p-2`}
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
      }}
    >
      {children}
    </div>
  );
}

ViewportGrid.propTypes = {
  /** Number of columns */
  numRows: PropTypes.number.isRequired,
  /** Number of rows */
  numCols: PropTypes.number.isRequired,
  layoutType: PropTypes.string,
  /** Array of React Components to render within grid */
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default ViewportGrid;
