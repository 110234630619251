// import React from 'react';
// import classNames from 'classnames';

// import ProgressLoadingBar from '../ProgressLoadingBar';
// import { Icons } from '@ohif/ui-next';
// /**
//  *  A React component that renders a loading indicator.
//  * if progress is not provided, it will render an infinite loading indicator
//  * if progress is provided, it will render a progress bar
//  * Optionally a textBlock can be provided to display a message
//  */
// function LoadingIndicatorProgress({ className, textBlock, progress }) {
//   return (
//     <div
//       className={classNames(
//         'absolute top-0 left-0 z-50 flex flex-col items-center justify-center space-y-5',
//         className
//       )}
//     >
//       <Icons.LoadingOHIFMark className="h-12 w-12 text-white" />
//       <div className="w-48">
//         <ProgressLoadingBar progress={progress} />
//       </div>
//       {textBlock}
//     </div>
//   );
// }

// export default LoadingIndicatorProgress;

import React from 'react';
import classNames from 'classnames';

import './loader.css';
import { Icons } from '@ohif/ui-next';

/**
 *  A React component that renders a loading indicator.
 * if progress is not provided, it will render an infinite loading indicator
 * if progress is provided, it will render a progress bar
 * Optionally a textBlock can be provided to display a message
 */
function LoadingIndicatorProgress({ className, textBlock, progress, isUpload = false }) {
  return (
    <div
      className={classNames(
        'absolute top-0 left-0 z-50 flex flex-col items-center justify-center space-y-5',
        className
      )}
    >
      <div className={`mywraper ${isUpload ? '!w-[200%]' : ''}`}>
        <div className="mydiv">
          {/* <img
                className="imgdiv"
                src={'../../../public/assets/myinner.png'}
                alt="loooading"
              /> */}
          <Icons.ByName
            name="main-loader"
            className="h-[100px] w-[100px] text-white"
          />
        </div>
        <div className="loader"></div>
      </div>
      {/* <div className="w-48">
        <ProgressLoadingBar progress={progress} />
      </div> */}
      {textBlock}
    </div>
  );
}

export default LoadingIndicatorProgress;
