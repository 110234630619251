import React from 'react';
import type { IconProps } from '../types';

export const Main3D = (props: IconProps) => (
  <svg
    width="64"
    height="64"
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      transform="matrix(.87227 -.48902 .0174 .99985 0 0)"
      fill="#ed1c24"
      opacity=".6"
      d="M9.191 12.792h5.077v4.705H9.191z"
    />
    <path
      transform="matrix(.02232 -.99975 .8739 .4861 0 0)"
      fill="#00aeef"
      opacity=".7"
      d="M-8.403 4.204h4.777v4.868h-4.777z"
    />
    <path
      transform="matrix(.85165 .52411 -.86093 .50873 0 0)"
      fill="#0db14b"
      opacity=".7"
      d="M7.204-2.042h5.15v4.705h-5.15z"
    />
    <path
      d="M5.5 1H1v4.5h1V2h3.5zM1 10.5V15h4.5v-1H2v-3.5zm14-5V1h-4.5v1H14v3.5zM10.5 15H15v-4.5h-1V14h-3.5zm2.245-9.935-4.5-2.5a.5.5 0 0 0-.5 0l-4.5 2.5A.5.5 0 0 0 3 5.5v5a.5.5 0 0 0 .255.435l4.5 2.5a.5.5 0 0 0 .5 0l4.5-2.5A.5.5 0 0 0 13 10.5v-5a.5.5 0 0 0-.255-.435M8 3.57l3.47 1.93L8 7.43 4.53 5.5zM4 6.35l3.5 1.945v3.855L4 10.205zm4.5 5.8V8.295L12 6.35v3.855z"
      fill="currentColor"
    />
  </svg>
);

export const Mpr2D = (props: IconProps) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.039 6.385h4.308v4.308H15V1H5.04z"
      fill="#389fd6"
    />
    <path
      d="M10.01 9.109v-.643l2.572-.322V7.18l1.928 1.607-1.928 1.607V9.43zM5.43 3.21l1.608-1.929L8.645 3.21H7.68l-.322 2.572h-.643l-.32-2.572z"
      fill-opacity=".504"
    />
    <path
      d="M5.039 10.693h3.5v-3.5h-3.5z"
      fill="#389fd6"
    />
    <g fill="#59a869">
      <path d="M3.806 12.577H6.89L7.968 11.5H4.81z" />
      <path d="M1.544 15H11.77L15 11.5H9.11l-1.885 1.885H3.051z" />
    </g>
    <g fill="#db5860">
      <path d="m3.154 12.09 1.077-1.153V7.864l-1.077 1.23z" />
      <path d="m1 14.399 1.347-1.442V8.79L4.23 6.638V1L1 4.5z" />
    </g>
  </svg>
);

export default Main3D;
