const CLIENT_CONFIGS = {
  dev: {
    KeyImagesXRAD: 'https://dev-api.xrad.cloud',
    MetaPythonXRAD: 'https://dev-api.xrad.cloud',
    TokenXRAD: 'https://app.dcmcloud.com',
    BucketXRAD: `https://dev-api.xrad.cloud`,
  },
  prod: {
    KeyImagesXRAD: 'https://prod-api.xrad.cloud',
    MetaPythonXRAD: 'https://prod-api.xrad.cloud',
    TokenXRAD: 'https://app.dcmcloud.com',
    BucketXRAD: `https://prod-api.xrad.cloud`,
  },
  mrc: {
    KeyImagesXRAD: 'https://app.dcmcloud.com',
    MetaPythonXRAD: 'http://app.dcmcloud.com',
    TokenXRAD: 'https://app.dcmcloud.com',
    BucketXRAD: `https://app.dcmcloud.com`,
  },
};

const DEFAULT_CLIENT = 'prod';

const isValidClient = clientName => {
  return clientName && CLIENT_CONFIGS.hasOwnProperty(clientName);
};

const getClientName = () => {
  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search);
    const clientName = searchParams.get('clientName');
    return isValidClient(clientName) ? clientName : DEFAULT_CLIENT;
  }
  return DEFAULT_CLIENT;
};

const getClientConfig = () => {
  const clientName = getClientName();
  return CLIENT_CONFIGS[clientName];
};

// export const baseURL = 'http://192.168.100.112';
// export const API = `${baseURL}:8000`; //File Reader .NET Core, File Loader .NET Core & Node.js
// export const CloudAPI = `${baseURL}:8011`; //8011 //Metadata
// export const LocalAPI = `${baseURL}:3081`; //3081 //5003 //File Reader & File Loader Local
// export const LocalMetadataAPI = `${baseURL}:5002`; //5002 //9044 //Metadata Local
// export const LocalKeyImages = `${baseURL}:8001`; //8001 //Key Images Local
// export const KeyImagesAPI = `${baseURL}:8002:`; //8002 //Key Images Cloud
// export const PanoAPI = `${baseURL}:8011`; //8003 //Pano Dentistry API
// export const DentistrySegAPI = `${baseURL}:9007`;

export const baseURL = 'https://app.dcmcloud.com';
export const API = `${baseURL}`; //File Reader .NET Core, File Loader .NET Core & Node.js
export const CloudAPI = `${baseURL}`; //8011 //Metadata
export const LocalAPI = `${baseURL}`; //3081 //5003 //File Reader & File Loader Local
export const LocalMetadataAPI = `${baseURL}`; //5002 //9044 //Metadata Local
export const LocalAPIUpload = `${baseURL}`; //5002 //9044 //Metadata Local
export const LocalKeyImages = `${baseURL}`; //8001 //Key Images Local
export const KeyImagesAPI = `${baseURL}`; //8002 //Key Images Cloud
export const PanoAPI = `${baseURL}`; //8003 //Pano Dentistry API
export const DentistrySegAPI = `${baseURL}`;

export const blockChainAPI = '';
export const XRADAPIKEY = '4bd40931-8592-4ab6-85c5-41e4469e836e';

//XRAD ROUTES
export const TokenVerifyAPI = Object.defineProperty({}, 'current', {
  get: () => getClientConfig().TokenXRAD,
}).current;
export const LocalKeyImagesXRAD = Object.defineProperty({}, 'current', {
  get: () => getClientConfig().KeyImagesXRAD,
}).current; //8001 //Key Images Local
export const LocalAPIXRAD = Object.defineProperty({}, 'current', {
  get: () => getClientConfig().MetaPythonXRAD,
}).current;
export const BucketXRAD = Object.defineProperty({}, 'current', {
  get: () => getClientConfig().BucketXRAD,
}).current;
