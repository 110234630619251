import React from 'react';
import type { IconProps } from '../types';

export const CloudConnect = (props: IconProps) => (
  <svg
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 16h-2v-2h3a4 4 0 0 0 0-8 4 4 0 0 0-8 0 4 4 0 0 0 0 8h3v2H9v2H2v2h7v2h6v-2h7v-2h-7Zm-2 4h-2v-2h2Z"
      data-name="Layer 2"
    />
  </svg>
);

export default CloudConnect;
