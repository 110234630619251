import React, { useState, createContext } from 'react';

export const MyContext = createContext({});

export const MyProvider = ({ children }) => {
  const [selectContext, setSelect] = useState(false);

  const handleSelectChange = () => {
    setSelect(!selectContext);
  };

  return (
    <MyContext.Provider value={{ selectContext, handleSelectChange }}>
      {children}
    </MyContext.Provider>
  );
};
