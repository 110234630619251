import { API } from '../../../../../../constants/baseurl';
const api_constants = {
  SIGN_UP: `${API}/api/v1/userSignup`,
  SIGN_IN: `${API}/api/v1/userSignup/signin`,
  CHECK_CONNECTIVITY: `${API}/fileloaderv1/files/CheckConnectivity`,
  ADD_BUCKET: `${API}/api/v1/bucket/addBucket`,
  UPDATE_SERVICES: `${API}/api/v1/userSignup/updateUserServices`,
  GET_TOKEN: `${API}/api/v1/external/getShareAuth`,
};

export default api_constants;
