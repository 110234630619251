import React, { useState } from 'react';
import Tabs from './Tabs';
import PersonalInfo from './PersonalInfo';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Services from './Services';
import Package from './Package';
import Checkout from './Checkout';
import Finished from './Finished';
import Bucket from './Bucket';
import darklogo from './assets/svgs/dcmcloud-logos-dark.svg';
import { packages } from '../../../../../platform/app/src/redux/features/register/packagelist';
import { useSelector } from 'react-redux';

function SignUp() {
  const steps = ['personal', 'services', 'package', 'checkout', 'payment', 'bucket', 'finished'];
  const [active, setActive] = useState(steps[0]);
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(0);
  const [selectedCards, setSelectedCards] = useState([]);
  const [connected, setConnected] = useState(false);
  const [checked, setChecked] = useState(false);
  const isPackages = useSelector(state => state.packages.value);

  const handleCurrentStep = newStep => {
    if (
      completedSteps >= newStep ||
      //newStep == 1 ||
      newStep == 2 ||
      newStep == 3 ||
      newStep == 4 ||
      newStep == 5 ||
      newStep == 6
    ) {
      if (newStep == 2) {
        selectedCards.length > 0 && setActive(steps[newStep]);
        selectedCards.length > 0 && setCurrentStep(newStep);
      } else if (newStep == 3) {
        isPackages.id != '' && setActive(steps[newStep]);
        isPackages.id != '' && setCurrentStep(newStep);
      } else if (newStep == 4) {
        checked && setActive(steps[newStep]);
        checked && setCurrentStep(newStep);
      } else if (newStep == 6) {
        connected && setActive(steps[newStep]);
        connected && setCurrentStep(newStep);
      } else {
        setActive(steps[newStep]);
        setCurrentStep(newStep);
      }
    }
  };

  const handlePrevious = newStep => {
    setActive(steps[newStep]);
    setCurrentStep(newStep);
  };

  const handleCompletedSteps = () => {
    setActive(steps[completedSteps + 1]);
    setCurrentStep(completedSteps + 1);
    setCompletedSteps(completedSteps + 1);
  };

  const handleActive = isVar => {
    setActive(isVar);
  };

  const renderSwitch = param => {
    switch (param) {
      case 'personal':
        return <PersonalInfo handleCompletedSteps={handleCompletedSteps} />;
      case 'services':
        return (
          <Services
            handleCompletedSteps={handleCompletedSteps}
            selectedCards={selectedCards}
            setSelectedCards={setSelectedCards}
          />
        );
      case 'package':
        return <Package />;
      case 'checkout':
        return (
          <Checkout
            setChecked={setChecked}
            selectedServices={selectedCards}
            setSelectedCards={setSelectedCards}
          />
        );
      case 'bucket':
        return (
          <Bucket
            handleCompletedSteps={handleCompletedSteps}
            setConnected={setConnected}
          />
        );
      case 'finished':
        return <Finished selectedCards={selectedCards} />;
      default:
        return <div></div>;
    }
  };

  return (
    <div
      className="flex h-screen flex-col overflow-y-auto"
      style={{
        background:
          'radial-gradient(ellipse, rgba(37,58,86,1) 0%, rgba(18,23,51,1) 50%, rgba(20,20, 33,1) 75%)',
      }}
    >
      <div className="items-cen ter flex w-full justify-center sm:hidden">
         <img
          src={darklogo}
          alt="logo-dark"
          className="h-24 w-48 sm:hidden xl:mr-[10%]"
        />
      </div>
      <Tabs
        active={active}
        handleActive={handleActive}
      />
      {renderSwitch(active)}
      <div className="flex-grow"></div>
      <div className="mt-8 mb-5 flex justify-end">
        {currentStep !== 0 && (
          <div
            onClick={() => handlePrevious(currentStep - 1)}
            className={`from-primary-gradient to-primary-signlight ${currentStep !== steps.length - 1 ? 'mr-5' : 'mr-5 md:mr-20 lg:mr-32'} flex cursor-pointer items-center rounded-3xl bg-gradient-to-r p-1 px-5 text-base font-semibold text-white transition duration-300 hover:scale-105`}
          >
            <ArrowBackIcon className="mr-2 text-white" />
            Back
          </div>
        )}
        {currentStep !== steps.length - 1 && (
          <div
            onClick={() => handleCurrentStep(currentStep + 1)}
            className="from-primary-gradient to-primary-signlight mr-5 flex cursor-pointer items-center rounded-3xl bg-gradient-to-r p-1 px-5 text-base font-semibold text-white transition duration-300 hover:scale-105 md:mr-28 lg:mr-32"
          >
            Next
            <ArrowForwardIcon className="ml-2 text-white" />
          </div>
        )}
      </div>
    </div>
  );
}

export default SignUp;
