import React, { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneIcon from '@mui/icons-material/Phone';
import PropTypes from 'prop-types';
import './gradientbutton.css';
import signup from './apis/signup/signup';
import { useNavigate } from 'react-router-dom';
import signin from './apis/signin/signin';

export default function PersonalInfo({ handleCompletedSteps }) {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('+92');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedStorage, setSelectedStorage] = useState('Storage Service');
  const [storageDropdownOpen, setStorageDropdownOpen] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('Storage Region');
  const [regionDropdownOpen, setRegionDropdownOpen] = useState(false);
  const [concatPhoneNo, setConcatPhoneNo] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();

  const handlePhoneNo = e => {
    let value = e.target.value.replace(/\D/g, '');

    if (value.length > 10) {
      value = value.slice(0, 10);
    }

    if (value.length > 3) {
      value = value.slice(0, 3) + ' ' + value.slice(3);
    }

    if (phoneNo.length > value.length) {
      if (phoneNo[phoneNo.length - 1] === ' ') {
        value = value.slice(0, -1);
      } else if (phoneNo[phoneNo.length - 2] === ' ' && value.length === 3) {
        value = value.slice(0, -1);
      }
    }

    setPhoneNo(value);
    const cleanCountryCode = selectedCountry.replace(/[()]/g, '');
    const cleanPhoneNo = value.replace(/\s+/g, '');
    const concatPhoneNo = cleanCountryCode.concat(cleanPhoneNo);

    setConcatPhoneNo(concatPhoneNo);
  };

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCountrySelect = code => {
    setSelectedCountry(code);
    setDropdownOpen(false);
  };

  const toggleStorageDropdown = () => {
    setStorageDropdownOpen(!storageDropdownOpen);
  };

  const handleStorageSelect = storage => {
    setSelectedStorage(storage);
    setStorageDropdownOpen(false);
  };

  const toggleRegionDropdown = () => {
    setRegionDropdownOpen(!regionDropdownOpen);
  };

  const handleRegionSelect = region => {
    setSelectedRegion(region);
    setRegionDropdownOpen(false);
  };

  const countries = ['(+92)', '(+1)', '(+44)'];
  const storageOptions = ['AWS', 'Google', 'Azure', 'DCMC Bucket'];
  const regionOptions = [
    'us-east-1',
    'us-west-1',
    'us-north-1',
    'us-east-1',
    'us-west-1',
    'us-east-1',
    'us-west-1',
  ];

  const validateEmail = value => value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);

  const validateForm = () => {
    const newErrors = {};

    if (!fname.trim()) newErrors.fname = 'First Name is missing';
    if (!lname.trim()) newErrors.lname = 'Last Name is missing';
    if (!email.trim()) newErrors.email = 'Email is missing';
    if (!phoneNo.trim()) newErrors.phoneNo = 'Phone Number is missing';
    if (!password.trim()) newErrors.password = 'Password is missing';
    if (!passwordConfirm.trim()) newErrors.passwordConfirm = 'Confirm Password is missing';
    if (password !== passwordConfirm) newErrors.passwordMatch = 'Passwords do not match';
    if (selectedStorage == 'Storage Service') newErrors.storage = 'No Storage Selected';
    if (selectedRegion == 'Storage Region') newErrors.region = 'No Region Selected';
    if (!validateEmail(email)) newErrors.emailInvalid = 'Email is not valid';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleCreateAccount = () => {
    if (validateForm()) {
      // Form is valid, proceed with account creation
      if (validateForm()) {
        setIsLoading(true);
        signup(email, fname, lname, password, concatPhoneNo, selectedStorage, selectedRegion)
          .then(response => {
            if (!response.error) {
              signin(email, password)
                .then(response => {
                  if (!response.error) {
                    localStorage.setItem('user_data', JSON.stringify(response.data));
                  }
                })
                .catch(error => {
                  console.error(error);
                });
              handleCompletedSteps();
            }
          })
          .catch(error => {
            console.error(error);
            navigate('/sign-in');
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const spinner = () => {
    return (
      <div
        className={`border-t-primary-light mr-2 h-4 w-4 animate-spin rounded-full border-[2px] border-t-[3px] border-solid`}
      ></div>
    );
  };

  return (
    <div className="mt-5 flex flex-col items-center justify-center">
      <div className="flex items-start justify-start md:w-[560px] lg:w-[810px]">
        <span className="text-[18px] font-semibold text-white">Personal Info</span>
      </div>
      <div>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col">
            <div
              className={`mt-10 flex w-64 border-b-2 py-2 pl-2 transition duration-300 lg:w-96 ${errors.fname ? 'border-red-500' : 'border-primary-gray hover:border-primary-signactivedark'}`}
            >
              <PersonIcon className="text-white" />
              <input
                placeholder="First Name"
                value={fname}
                onChange={e => setFname(e.target.value)}
                className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
              />
            </div>
            {errors.fname && <p className="mt-2 text-sm text-red-500">{errors.fname}</p>}
          </div>
          <div className="flex flex-col">
            <div
              className={`${errors.lname ? 'border-red-500' : 'border-primary-gray hover:border-primary-signactivedark'} ml-0 mt-10 flex w-64 border-b-2 p-2 transition duration-300 md:ml-10 lg:w-96`}
            >
              <PersonIcon className="text-white" />
              <input
                placeholder="Last Name"
                value={lname}
                onChange={e => setLname(e.target.value)}
                className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
              />
            </div>
            {errors.lname && (
              <p className="mt-2 ml-0 text-sm text-red-500 md:ml-10">{errors.lname}</p>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col">
            <div
              className={`mt-10 flex w-64 border-b-2 py-2 pl-2 transition duration-300 lg:w-96 ${errors.email || errors.emailInvalid ? 'border-red-500' : 'border-primary-gray hover:border-primary-signactivedark'}`}
            >
              <EmailIcon className="text-white" />
              <input
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
              />
            </div>
            {errors.email && <p className="mt-2 text-sm text-red-500">{errors.email}</p>}
            {errors.emailInvalid && (
              <p className="mt-2 text-sm text-red-500">{errors.emailInvalid}</p>
            )}
          </div>
          <div className="relative ml-0 mt-10 w-64 md:ml-10 lg:w-96">
            <div className="flex">
              <div className="flex flex-col">
                <div
                  onClick={toggleDropdown}
                  className={`${errors.phoneNo ? 'border-red-500' : 'border-primary-gray hover:border-primary-signactivedark'} group flex cursor-pointer items-center border-b-2 px-2 pt-2 pb-[6px] text-gray-500 transition duration-300`}
                >
                  {selectedCountry}
                  <span className="ml-3 lg:ml-5">
                    <ExpandMoreIcon className="group-hover:text-white" />
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <div
                  className={`${errors.phoneNo ? 'border-red-500' : 'border-primary-gray hover:border-primary-signactivedark'} ml-3 flex w-full cursor-pointer items-center border-b-2 p-2 text-gray-500 transition duration-300`}
                >
                  <PhoneIcon className="text-white" />
                  <input
                    placeholder="Phone Number"
                    value={phoneNo}
                    onChange={handlePhoneNo}
                    className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
                  />
                </div>
                {errors.phoneNo && (
                  <p className="mt-2 ml-3 text-sm text-red-500">{errors.phoneNo}</p>
                )}
              </div>
            </div>
            {dropdownOpen && (
              <div className="bg-primary-signlight border-primary-gray text-primary-gray absolute top-full left-0 z-50 mt-1 max-h-44 w-20 overflow-y-auto rounded rounded-b-xl border-[1px] p-2 shadow-lg lg:w-24">
                {countries.map(country => (
                  <div
                    onClick={() => handleCountrySelect(country)}
                    className="hover:bg-primary-signcarddark cursor-pointer px-2 py-1 hover:rounded-lg hover:text-white"
                  >
                    {country}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col">
            <div className="group relative mt-10 w-64 lg:w-96">
              <div
                onClick={toggleStorageDropdown}
                className={`${errors.storage ? 'border-red-500' : 'border-primary-gray hover:border-primary-signactivedark'} flex cursor-pointer justify-between border-b-2 p-2 text-gray-600 transition duration-300`}
              >
                {selectedStorage}
                <span className="ml-5">
                  <ExpandMoreIcon className="text-gray-600 transition duration-300 group-hover:text-white" />
                </span>
              </div>
              {storageDropdownOpen && (
                <div className="bg-primary-signlight border-primary-gray text-primary-gray absolute top-full left-0 z-10 mt-1 max-h-44 w-full overflow-y-auto rounded rounded-b-xl border-[1px] p-2 shadow-lg">
                  {storageOptions.map((storage, index) => (
                    <div
                      key={index}
                      onClick={() => handleStorageSelect(storage)}
                      className="hover:bg-primary-signcarddark cursor-pointer px-2 py-1 hover:rounded-lg hover:text-white"
                    >
                      {storage}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {errors.storage && <p className="mt-2 text-sm text-red-500">{errors.storage}</p>}
          </div>
          <div className="flex flex-col">
            <div className="group relative ml-0 mt-10 w-64 md:ml-10 lg:w-96">
              <div
                onClick={toggleRegionDropdown}
                className={`${errors.region ? 'border-red-500' : 'border-primary-gray hover:border-primary-signactivedark'} flex cursor-pointer justify-between border-b-2 p-2 text-gray-600 transition duration-300`}
              >
                {selectedRegion}
                <span className="ml-5">
                  <ExpandMoreIcon className="text-gray-600 transition duration-300 group-hover:text-white" />
                </span>
              </div>
              {regionDropdownOpen && (
                <div className="bg-primary-signlight border-primary-gray text-primary-gray absolute top-full left-0 z-10 mt-1 max-h-44 w-full overflow-y-auto rounded rounded-b-xl border-[1px] p-2 shadow-lg">
                  {regionOptions.map((region, index) => (
                    <div
                      key={index}
                      onClick={() => handleRegionSelect(region)}
                      className="hover:bg-primary-signcarddark cursor-pointer px-2 py-1 hover:rounded-lg hover:text-white"
                    >
                      {region}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {errors.region && (
              <p className="mt-2 ml-0 text-sm text-red-500 md:ml-10">{errors.region}</p>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col">
            <div
              className={`${errors.password ? 'border-red-500' : 'border-primary-gray hover:border-primary-signactivedark'} mt-10 flex w-64 border-b-2 p-2 transition duration-300 lg:w-96`}
            >
              <LockIcon className="text-white" />
              <input
                autoComplete="off"
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
              />
              <button
                type="button"
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="focus:outline-none"
              >
                {passwordVisible ? (
                  <VisibilityOffOutlinedIcon className="text-gray-500" />
                ) : (
                  <VisibilityOutlinedIcon className="text-gray-500" />
                )}
              </button>
            </div>
            {errors.password && <p className="mt-2 text-sm text-red-500">{errors.password}</p>}
          </div>
          <div className="flex flex-col">
            <div
              className={`${errors.passwordConfirm ? 'border-red-500' : 'border-primary-gray hover:border-primary-signactivedark'} ml-0 mt-10 flex w-64 border-b-2 p-2 transition duration-300 md:ml-10 lg:w-96`}
            >
              <LockIcon className="text-white" />
              <input
                type={passwordConfirmVisible ? 'text' : 'password'}
                placeholder="Confirm Password"
                value={passwordConfirm}
                onChange={e => setPasswordConfirm(e.target.value)}
                className="ml-2 w-full bg-transparent text-white placeholder-gray-600 outline-none"
              />
              <button
                type="button"
                onClick={() => setPasswordConfirmVisible(!passwordConfirmVisible)}
                className="focus:outline-none"
              >
                {passwordConfirmVisible ? (
                  <VisibilityOffOutlinedIcon className="text-gray-500" />
                ) : (
                  <VisibilityOutlinedIcon className="text-gray-500" />
                )}
              </button>
            </div>
            {errors.passwordConfirm && (
              <p className="mt-2 ml-0 text-sm text-red-500 md:ml-10">{errors.passwordConfirm}</p>
            )}
            {errors.passwordMatch && (
              <p className="mt-2 ml-0 text-sm text-red-500 md:ml-10">{errors.passwordMatch}</p>
            )}
          </div>
        </div>
      </div>
      {/* <div className="mt-10 ml-3 flex items-start justify-start md:w-[560px] lg:w-[810px]">
        <div
          className={`mt-1 h-4 w-4 border border-white transition duration-150 ${isChecked ? 'bg-white' : 'bg-transparent'}`}
          onClick={toggleCheckbox}
          style={{ cursor: 'pointer' }}
        >
          <svg
            className={`h-full w-full transition duration-150 ${isChecked ? 'scale-100' : 'scale-0'}`}
            fill="none"
            stroke="black"
            strokeWidth="3"
            viewBox="0 0 24 24"
          >
            <path d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <label
          className="ml-2 cursor-pointer text-white"
          onClick={toggleCheckbox}
        >
          Remember Me
        </label>
      </div> */}
      <div
        onClick={handleCreateAccount}
        className="from-primary-signactivedark to-primary-signactivelight mt-10 flex cursor-pointer items-center rounded-3xl bg-gradient-to-r p-2 px-5 text-base font-semibold text-white transition duration-300 hover:scale-105"
      >
        {isLoading && spinner()}
        Create Account
      </div>
    </div>
  );
}

PersonalInfo.propTypes = {
  handleCompletedSteps: PropTypes.func.isRequired,
};
