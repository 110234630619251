import React from 'react';
import PropTypes from 'prop-types';

import profile from './assets/icons/profile.png';
import bucket from './assets/icons/bucket.png';
import checkout from './assets/icons/checkout.png';
import finished from './assets/icons/finished.png';
import payment from './assets/icons/payment.png';
import services from './assets/icons/services.png';
import packagee from './assets/icons/package.png';
import darklogo from './assets/svgs/dcmcloud-logos-dark.svg';

import './tabanimate.css';

function Tabs({ active }) {
  const icons = [
    {
      id: 'personal',
      icon: profile,
      label: 'Personal Info',
    },
    {
      id: 'services',
      icon: services,
      label: 'Services',
    },
    {
      id: 'package',
      icon: packagee,
      label: 'Package Selection',
    },
    {
      id: 'checkout',
      icon: checkout,
      label: 'Checkout',
    },
    {
      id: 'payment',
      icon: payment,
      label: 'Payment Verification',
    },
    {
      id: 'bucket',
      icon: bucket,
      label: 'Bucket Connection',
    },
    {
      id: 'finished',
      icon: finished,
      label: 'Finished',
    },
  ];

  return (
    <div className="flex items-center justify-center pt-1 text-white sm:items-start sm:justify-start">
      <img
        src={darklogo}
        alt="logo-dark"
        className="hidden h-20 w-36 sm:flex lg:h-24 lg:w-48 xl:mr-[10%]"
      />
      {icons.map(icon => (
        <div
          className="flex pt-4"
          key={icon.id}
        >
          <div className="flex-col">
            <div
              className={`${
                active === icon.id
                  ? 'border-primary-signactivedark border-gradient-purple animate-tab'
                  : 'border-primary-darkTable'
              } relative ml-1 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-[3px] p-[4px] sm:ml-7 sm:h-12 sm:w-12 lg:ml-10 lg:h-14 lg:w-14`}
            >
              <div
                className={`${
                  active === icon.id
                    ? 'from-primary-signactivedark to-primary-signactivelight bg-gradient-to-r'
                    : ''
                } flex h-6 w-8 items-center justify-center rounded-full sm:h-8 sm:w-10 lg:h-10 lg:w-12`}
              >
                <img
                  src={icon.icon}
                  alt="logo"
                  className="h-4 w-4 object-contain sm:h-6 sm:w-6"
                />
              </div>
              <div
                className={`bg-primary-signdark absolute right-[-5px] h-[calc(40%+1px)] w-2 ${
                  active === icon.id ? '' : 'hidden'
                }`}
              ></div>
            </div>
            <div className="ml-5 mt-2 hidden w-16 text-center text-[12px] sm:block lg:ml-7 lg:w-20 lg:text-sm">
              {icon.label}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

Tabs.propTypes = {
  active: PropTypes.string.isRequired,
};

export default Tabs;
