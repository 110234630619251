import { API } from '../../../../constants/baseurl';
import React, { useState } from 'react';
import validator from 'validator';
import ReCAPTCHA from 'react-google-recaptcha';
import './LeadForm.css';

export default function LeadForm() {
  const userdata = localStorage.getItem('user_data');
  const jsonobject = JSON.parse(userdata);
  const newFirstName = jsonobject.email != 'userdemo@gmail.com' ? jsonobject.firstName : '';

  const newLastName = jsonobject.email != 'userdemo@gmail.com' ? jsonobject.lastName : '';

  const [formData, setFormData] = useState({
    email: '',
    organisation: '',
    lastName: newLastName,
    firstName: newFirstName,
    role: '',
    termsAndConditions: false,
    privacyPolicy: false,
    sendInfo: false,
  });

  const [button, setButton] = useState(null);

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // console.log(formData, "formData")
  const generateMailtoLink = () => {
    const { email, organisation, lastName, firstName, role } = formData;

    const subject = 'View Findings Request';
    const body = `
    First Name: ${firstName}
    Last Name: ${lastName}
    Email: ${email}
    Role: ${role}
    Organisation: ${organisation}
    Send me info on Annalise products & events: ${formData.sendInfo ? 'Yes' : 'No'}
    `;

    return formData
      ? `mailto:info@dcmcloud.com?subject=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(body)}`
      : '';
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      if (!validator.isEmail(formData.email)) {
        // cogoToast.error('Email is not valid', {
        //   position: 'bottom-right',
        // });
      } else {
        const token = localStorage.getItem('token');

        const response = await fetch(`${API}/api/v1/emailsend/sendEmailLinkLeads`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          localStorage.setItem('formData', JSON.stringify(formData));
          localStorage.setItem('FormTrue', true); // Store 'true' as a string
          // try {
          //   const response = await fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
          //     method: 'POST',
          //     headers: {
          //       'Content-Type': 'application/json',
          //     },
          //     body: JSON.stringify(formData),
          //   })

          if (response.ok) {
            // alert('Form submitted successfully!');
            try {
              const payload = {
                fields: Object.keys(formData).map(key => ({
                  name: key,
                  value: formData[key],
                })),
              };
              const response = await fetch(
                `https://api.hsforms.com/submissions/v3/integration/submit/44843687/03faba37-39ba-43e4-8e83-b24f36053902`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(payload),
                }
              );
              if (response.ok) {
                // cogoToast.success('Mail Sent', {
                //   position: 'bottom-right',
                // });
                window.location.reload(); // Reload the page
              }
            } catch (error) {
              console.error('Error submitting the form:', error);
            }
          } else {
            alert('Failed to submit the form');
          }
          // Handle success
        } else {
          // cogoToast.error('Mail Not Sent ', { position: 'bottom-right' });
          // Handle failure
        }
      }
    } catch (error) {
      console.error(error);
      // cogoToast.error('An Error Occured', { position: 'bottom-right' });
      // Handle error
    }
    // Submit the form data to the server
  };
  function handleRecaptchaChange(value) {
    // console.log("Captcha value:", value);
  }
  // console.log(button, "button")

  return (
    <div className="mainCont pt-12 pb-20 md:pt-48 lg:pt-12">
      <div className="headerDiv">
        <h1 className="form-header">Experience comprehensive AI for medical imaging</h1>
        <div className="disclaimer">
          <svg
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="svgImage"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.86112 2.1853L2.33533 11.6072H13.3872L7.86112 2.1853ZM12.743 11.2375H12.7435L7.86155 2.91386L7.86132 2.91425L2.97981 11.2375H12.743ZM8.22954 9.11335L8.22995 9.11335V4.49452H8.22954H7.49315L7.4931 9.11335L8.22954 9.11335ZM7.87183 10.7768C7.86834 10.7769 7.86484 10.7769 7.86133 10.7769C7.55625 10.7769 7.30877 10.5287 7.30877 10.2224C7.30877 9.92025 7.55038 9.67432 7.85043 9.66849C7.85405 9.66842 7.85769 9.66839 7.86133 9.66839C8.16636 9.66839 8.41399 9.9166 8.41399 10.2224C8.41399 10.5252 8.17202 10.7712 7.87183 10.7768Z"
              fill="#CCCCCC"
            ></path>
          </svg>
          <h3>Not a medical device. Not for clinical or research use.</h3>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className="formContEmail"
      >
        <div className="contentDiv">
          <div className="contentDivGroup">
            <h2 className="form-header-inner">Ready to discover DCMCloud's innovative features?</h2>
            <div className="formPara">
              <h3>
                Register for instant access to our demo and start navigating the future of medical
                imaging today.
              </h3>
              <h3>Registration required to ensure security and privacy.</h3>
              <h3>All fields are required.</h3>
            </div>
          </div>
          <div className="links">
            <a id="link">Terms & Conditions</a>
            <a id="link">Privacy Policy</a>
          </div>
        </div>
        <div className="inputDiv">
          <div className="inputDivGroup">
            <div className="formGroupBtn">
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="Enter your first name"
                  required
                  autoComplete="off"
                  style={{ background: 'white', color: 'black' }}
                />
              </div>

              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>

                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Enter your last name"
                  required
                />
              </div>
            </div>
            <div className="formGroupBtn">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email address"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="role">Role</label>
                <input
                  type="text"
                  name="role"
                  id="role"
                  value={formData.role}
                  onChange={handleChange}
                  placeholder="Enter your role"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="organisation">Organisation</label>
              <input
                type="text"
                name="organisation"
                id="organisation"
                value={formData.organisation}
                onChange={handleChange}
                placeholder="Enter your organisation name"
                required
              />
            </div>
          </div>
          <div className="formGroupBtn">
            <div className="checkBoxGroup">
              <span>
                <input
                  type="checkbox"
                  name="sendInfo"
                  id="sendInfo"
                  // checked={formD}
                  onChange={handleChange}
                />
              </span>
              <span>
                <label htmlFor="sendInfo">Send me info (unsubscribe anytime)</label>
              </span>
            </div>
            <div
              className="botss"
              style={{ transform: 'scale(0.85)', transformOrigin: '0 0' }}
            >
              <ReCAPTCHA
                className="botss2"
                sitekey="6LfS6BkpAAAAAB1POyPkciSzyBzdRtusvkrRHPxD"
                onChange={val => setButton(val)}
              />
            </div>
            <button
              type="submit"
              className="btnSubmit btn-primary"
              onClick={() => generateMailtoLink()}
              disabled={!button}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                height={17}
                width={17}
                data-prefix="fas"
                data-icon="check-circle"
                className="svg-inline--fa fa-check-circle fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                ></path>
              </svg>
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
